import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logger from "../../../services/logger";
import CommitteeMemberTable from "./committeeMemberTable";
import ButtonLink from "../../common/buttonLink";
import PagingAndSorting from "../../common/pagingAndSorting";
import committeeMemberService from "../../../services/committeeMemberService";
import CommitteeMemberForm from "./committeeMemberForm";
import FormDialog from "../../common/formDialog";
import Add from "../../common/add";

class CommitteeMembers extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  getAll = async () => {
    try {
      let {data}={...this.state}
      data=[];
      this.setState({data})
      const { data:members } = await committeeMemberService.getAllByCommittee(this.props.committee.id);
      this.setState({ data:members });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await committeeMemberService.deleteById(id);
      await this.getAll();
      // this.props.onMemberAdd();
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
    // this.props.onMemberAdd();
    this.getAll();
  };

  componentDidMount() {
    console.log("selected committee:",this.props);
   this.getAll()
  }
  

  render() {
    const { sortColumn, data, dialogOpen,id } = this.state;
    const { t, committee ,bom,heading} = this.props;
  //  this.getAll();
    return (
      <React.Fragment>
       <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
            {/* <ButtonLink onButtonClick={() => this.handleEdit({ id: "new" })} icon={"add_circle"} title={t("Add Member")} size="medium"></ButtonLink> */}
            <Add onAdd={this.handleAdd} item={{id:"new"}} title={t("Add Member")}/>

          </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CommitteeMemberTable
              data={data}
              committee={committee}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              heading={heading}
            />
          </Grid>
          <Grid container>
          &nbsp;
          </Grid>
          {/* <Grid container item sm={12} md={12} lg={12} direction="row">
            <Add onAdd={this.handleAdd} item={{id:"new"}} title={t("Add Member")}/>

          </Grid> */}
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
        {dialogOpen && (
          <FormDialog
            id="category-dialog"
            keepMounted
            title={id!=="new"?"Update Details":"Add Details"}
            content={
              <CommitteeMemberForm
              committee={committee}
                id={id ? id : "new"}
                committeeId={committee.id}
                bom={bom}
                onSave={this.closeDialog}
               
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
         )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["committeeMember"])(
  CommitteeMembers
);
