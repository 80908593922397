import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import ApplicantBasicDetailsForm from "./applicant-basic-details/applicantBasicDetailsForm";
import ApplicationPreviewForm from "./applicant-preview/applicantPreviewForm";
import UploadDocs from "./applicant-upload-docs/uploadDocs";
import OrgContext from "../app-context";
import enquiryService from "../../services/enquiryService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    // marginRight: theme.spacing(1),
  },
  instructions: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },

  headerBorder: {
    borderBottomStyle: "solid",
    borderWidth: "1px",
    borderBottomColor: theme.palette.primary.main,
  },
}));
function getSteps() {
  return [
    "Personal Info",
    "Address Details",
    "Education Details",
    "Upload Documents",
  ];
}

export default function ApplicantDetails(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [value, setValue] = React.useState(null);
  const [data, setData] = React.useState({});
  const org = React.useContext(OrgContext);
  React.useEffect(() => {
    getEnquiry();
  }, [])

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const handleNext = (i) => {
    //console.log('handle next in applicant details');
    props.onNext(i)
    // setValue(i)
  };

  const handleNextTab = (i) => {
    //console.log('handle next in applicant details');
    // props.onNext()
    setValue(i)
  };

  const handleBack = () => {
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }

  async function getEnquiry() {
    try {
      const { data: enqueryData } = await enquiryService.getEnquiryByUserIdIfApplicant(props.user.id);
      if (enqueryData.applicationStatus === "SUBMITTED" || enqueryData.applicationStatus === "APPROVED") {
        setValue(2);
      }
      else setValue(0);
      setData(enqueryData);
    } catch (ex) {

    }
  }
  function renderTabItems() {
    if (data.applicationStatus === "SUBMITTED" || data.applicationStatus === "APPROVED")
      return (
        <Tab label="Application Details"
          {...a11yProps(2)}
        />
      );
    else
      return (
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            scrollButtons
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            allowScrollButtonsMobile>
            <Tab
              label="Applicant Details"
              {...a11yProps(0)} />
            <Tab
              label="Upload Documents"
              {...a11yProps(3)} />
            <Tab
              label="Preview & Submit"
              {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      );
  }

  function renderTab() {
    return (
      <div className={classes.root}>
        <div className={classes.tabBorder}>
          {renderTabItems()}
          <TabPanel key={1} value={value} index={0}>
            <ApplicantBasicDetailsForm
              activeStep={activeStep}
              stepsLength={steps.length}
              onBack={handleBack}
              onNext={handleNextTab}
              user={props.user}
              status={props.status}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UploadDocs user={props.user} onNext={handleNext} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ApplicationPreviewForm
              activeStep={activeStep}
              stepsLength={steps.length}
              onBack={handleBack}
              onNext={handleNext}
              user={props.user}
              status={props.status}

            />
          </TabPanel>
        </div>
      </div>
    )
  }
  //console.log("ORG type:", org, "--", org.instituteType);
  return (renderTab());
}
