import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";

const EnquiriesQueueTable = ({ data, sortColumn, onSort, onChange }) => {
  const { t } = useTranslation(["enquiries", "common"]);
  const columns = [
    {
      path: "date",
      label: t("Date"),
      sort: true,
    },
    {
      path: "enquiryNumber",
      label: t("Enquiry #"),
    },

    { path: "userName", label: t("Name") },
    { path: "userMobile", label: t("Mobile #") },
    // { path: "userEmail", label: t("Email") },
    { path: "source", label: t("Source") },
    { path: "enquiryType", label: t("Type") },
    { path: "lastFollowedUpDate", label: t("LFD") },
    { path: "nextFollowUpDate", label: t("NFD"), sort: true },
    {
      label: t("Waiting"),
      key: "waiting",
      content: (item) => {
        return (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.waiting ? true : false}
                  onChange={(event) => onChange(event, item)}
                  name="waiting"
                  color="primary"
                />
              }
              label={item.waiting ? "Waiting" : ""}
            />
          </FormGroup>
        );
      },
    },
  ];

  return (
    <MatTable
      data={data}
      heading="List of Enquiries"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EnquiriesQueueTable;
