import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/dept-or-prgms";
const apiEndpoint = "/api/students";


function create(data) {
  return http.post(apiEndpoint, {...data,instituteType:authService.getInstituteType()});
}

function update(data) {
  return http.put(`${apiEndpoint}/${data.id}`, {...data,instituteType:authService.getInstituteType()});
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

function getAll() {
  return http.get(apiEndpoint);
}


function getAllByType(type) {
  return http.get(`${apiEndpoint}/type/${type}`);
}

function getAllByInstitute(instituteId) {
  return http.get(`${apiEndpoint}/list/${instituteId}`);
}

function getAllByQuery(programId,termId,query) {

  return http.get(`${apiEndpoint}/q`, { params: { ...query } });
}

function getAllByDepartment(departmentId,query) {
  return http.get(`${apiEndpoint}/department/${departmentId}`, { params: { ...query } });
}

function getAllCounsellors(){
  return http.get(`${apiEndpoint}/counsellors`);

}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByType,
  getAllByInstitute,
  getAllByQuery,
  getAllByDepartment,
  getAllCounsellors,
  deleteById
};
