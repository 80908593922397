import {
  Button,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import Joi from "joi";
import React from "react";
import { Link } from "react-router-dom";
import authService from "../../../../services/authService";
import { getNavItems } from "../../../../services/navService";
import Form from "../../../common/form";

class SignInForm extends Form {
  state = {
    data: { username: "", password: ""},
    groups: [],
    errors: {},
  };

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
    // rememberme: Joi.boolean(),
  };

  componentDidMount() {
    // this.getGroups()
  }


  doSubmit = async () => {
    try {
      const { data } = this.state;
      await authService.login(data.username, data.password);
      authService.setCurrentRole();
      const defaultUrl=getNavItems().defaultUrl;
     // console.log("default url:",defaultUrl);
      // this.push(defaultUrl);
      window.location.replace(defaultUrl);
    } catch (ex) {

    }
  };

  render() {
    const { classes,onClick } = this.props;
    const { groups } = this.state;
    return (
      <>
         <Card variant="outlined">
              <CardContent>
                <form onSubmit={this.handleSubmit}>
                  <Grid container>
                    {this.renderMatInput(
                      {},
                      "username",
                      "Username",
                      "text",
                      "outlined",
                      "medium"
                    )}
                    {this.renderMatInput(
                      {},
                      "password",
                      "Password",
                      "password",
                      "outlined",
                      "medium")}

                    {/* {this.renderMatCheckbox({}, "rememberme", "Remember me")} */}

                    {this.renderMatButton(
                      "Log in",
                      "submit",
                      "primary",
                      true,
                      "lock",
                      "medium"
                    )}
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs className="forgot-password">
                      <Button
                        variant="text"
                        color="primary"
                      >
                        <Link to="/forgot-password">
                          {"Forgot Password ?"}
                        </Link>
                      </Button>
                    </Grid>
                    <Grid item className="forgot-password">
                      <Button color="primary" variant="text" onClick={()=>onClick(false)}>
                      {"New Registration"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
        </>
    );
  }
}

export default SignInForm;
