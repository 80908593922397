import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEPT_OR_PRGM_ROUTE } from "../../../data/routerConstants";
import logger from "../../../services/logger";
import specializationService from "../../../services/specializationService";
import PagingAndSorting from "../../common/pagingAndSorting";
import SpecializationsTable from "./specializationsTable";

class Specializations extends PagingAndSorting {
  state = {
    specializations: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
  };

  getSpecializations = async () => {
    try {
      const {
        data: specializations,
      } = await specializationService.getSpecializations();
      this.setState({ specializations });
    } catch (ex) {
      logger.error("specializations ", ex);
    }
  };

  deleteSpecialization = async (id) => {
    try {
      await specializationService.deleteSpecialization(id);

      await this.getSpecializations();
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = (id) => {
    this.deleteSpecialization(id);
  };

  componentDidMount() {
    this.getSpecializations();
  }

  render() {
    const { sortColumn, specializations } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            direction="row-reverse"
          >
            <Button variant="outlined" color="primary">
              <Link to={`${DEPT_OR_PRGM_ROUTE}/new`}>
                {t("New Specialization")}
              </Link>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <SpecializationsTable
              data={specializations}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></SpecializationsTable>
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["specialization"])(Specializations);
