import { ButtonGroup, Chip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
// import renderHTML from "react-render-html";
import htmr from 'htmr';
import { formatToDateAndTime } from "../../common/dateUtil";

const EventsCalendarTable = ({ data, sortColumn, onSort, onDelete,onEdit }) => {
  const { t } = useTranslation(["tickets"]);
  const columns = [
    {
      path: "title",
      label: t("Title")
    },
    {
      path: "start",
      label: t("Start Date"),
      content: (item) => {
      return formatToDateAndTime(item.start)
      }
    },
    {
      path: "end",
      label: t("End Date"),
      content: (item) => {
      return formatToDateAndTime(item.end)
      }
    },
    {
      path: "status",
      label: t("Status"),
      content: (item) => {
        return(

          <Chip size="small"  label={t(item.status)} className={item.status} />
          
          )
      }
    }, {
      key: "action",
      content: (item) => {
        return <><Edit link={`events-calendars/${item.id}`} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      heading={t("List of Events")}
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EventsCalendarTable;
