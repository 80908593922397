import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import MatSelect from "../../common/matSelect";
import MatTablePagination from "../../common/matTablePagination";
import classSectionService from "./classSection-service";
import ClassSectionTable from "./classSectionTable-page";

class ClassSections extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 20,
    programId:-1,
    confirmDialogOpen: false
  };


  componentDidMount() {
    this.getPrograms();
    this.getPagedData({...this.query()});
  }

  onConfirmOk = async (id) => {
    try {
      await classSectionService.deleteById(id);
      await this.getPagedData({...this.query()});
    } catch (ex) {
      logger.error(ex);
    }
  };


  handleProgramChange = async (event) => {
    const programId = event.target.value;
    this.setState({ programId })
    console.log("event.target.value:", programId);
    this.getPagedData({ ...this.query(), programId: event.target.value});
  }

  getPagedData = async (query) => {
    try {
      const { programId } = query;
      query.programId = programId ? programId : this.state.programId;
      const { data, headers } = await classSectionService.getAllByQuery(query);
      const totalElements = headers["x-total-elements"];
      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const {sortColumn,rowsPerPage,page,totalElements,data,programId,programs} = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
  
           <Grid item xs={12} md={2}>
            <MatSelect label={t("common:Filter By Program")} isOptionAllRequired={true} onChange={this.handleProgramChange} options={programs} value={programId} />
          </Grid>
         {/*   {terms && terms.length > 1 &&
            <Grid item xs={12} md={2}>
              <MatSelect label={"Filter By Year"} isOptionAllRequired={false} onChange={this.handleTermChange} options={terms} value={termId} />
            </Grid>
          } */}
            <Grid container item sm={12} md={10} direction="row-reverse">
              <Add link={"class-sections/new"} title={t("New Section")} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>

            <ClassSectionTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
              <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["classTimetableConfigs","common"])(ClassSections);
