import { HEDGE_BROWN_SERVER } from "../data/endpointConstants";
import http from "./httpService";
const masterApi = HEDGE_BROWN_SERVER + "/manage-applications";
const apiEndpoint = "applicant-followups";

function create(applicationId, data) {
  return http.post(`${masterApi}/${applicationId}/${apiEndpoint}`, data);
}

function update(applicationId, data) {
  return http.put(`${masterApi}/${applicationId}/${apiEndpoint}`, data);
}

function get(applicationId, id) {
  return http.get(`${masterApi}/${applicationId}/${apiEndpoint}/${id}`);
}
function getAll(applicationId) {
  return http.get(`${masterApi}/${applicationId}/${apiEndpoint}`);
}

function getAllByQuery(applicationId, query) {
  return http.get(`${masterApi}/${applicationId}/${apiEndpoint}`, {
    params: { ...query },
  });
}

function deleteById(enquiryId, id) {
  return http.delete(`${masterApi}/${enquiryId}/${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByQuery,
  deleteById,
};
