import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import {prepareChartData, prepareMapDataToChartData} from "../../common/chartUtil"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme) => ({
  dashboardCard:{
    height:"100%",
    [theme.breakpoints.up('md')]: {
     height:"325px"
    }
  }
}))



function getText(key, data,className="default") {
  return (
    <ListItem button disableGutters  >
      <ListItemText disableTypography
        primary={
          <div style={{ margin: 0,textAlign:"center" }}>
            <span> {key}</span>
            <div style={{ fontSize: 18 }} class={className}>
              <strong>{data}</strong>
            </div>
          </div>
        } 
      />
    </ListItem>
  );
}

const BillingStatsCard = ({ data,stats,title }) => {
  const classes=useStyles();
console.log("title:",title,"-",data);
// const total=stats.total;
// delete stats.total;
//  const dataSet= prepareChartData(chartData,['green','yellow','red']);
 const dataSet= prepareMapDataToChartData(data,data.Billing,['blue','green','red']);
 //console.log("data set ;",dataSet.data);
  return (
    <React.Fragment>
      <Card className={classes.dashboardCard}>
        <CardHeader title={title?title:"Billing vs Collections"} className="dashboard-card-heading"></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <List component="nav" disablePadding aria-label="mailbox folders">
                {getText("Billing ", data.Billing,"text-blue")}
                <Divider />
                {getText("Collection ", data.Collection,"text-green")}
                <Divider />
                {getText("Discount", data.Discount)}
                <Divider />
                {getText("Collectable ", data.Collectable,"text-red")}
                <Divider />
              </List>
            </Grid>
            <Grid container xs={12} alignItems={"baseline"} md={8}>
              <Grid item xs={12} md={12}>
                <br />
               {dataSet && <Bar
                  options={dataSet.options}
                  data={dataSet.data}
                  height={260}
                />}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Bar options={options} data={dataSet} /> */}
    </React.Fragment>
  );
};

export default BillingStatsCard;
