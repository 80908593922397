import RegistrationForm from './registration/registrationForm';
import SignInForm from './signin/SignInForm';
import React, { Component } from 'react';
import groupService from '../../../services/groupService';
import { Grid, Hidden } from '@mui/material';
import authService from '../../../services/authService';

class Auth extends Component {
    state = {
        groups: [],
        signIn: true
    }
    constructor() {
        super()
        if (authService.getCurrentUser())
            window.location = "/";

    }



    async getGroups() {
        try {
            const { data: groups } = await groupService.getGroupsInLogin();
            this.setState({ groups });
            this.handleLocalStorage(groups[0]?.name)
        } catch (error) {
            console.log("error:", error);
        }
    }
    handleLocalStorage = (name) => {
        localStorage.setItem("institute", name)
        window.dispatchEvent(new Event("storage"));
    };

    renderForm(classes) {
        return this.state.signIn ? <SignInForm onClick={this.handleClick} classes={classes} /> : <RegistrationForm {...this.props} onClick={this.handleClick} classes={classes} />
    }

    handleClick = (signIn) => {
        this.setState({ signIn });
    }


    componentDidMount() {
        // this.getGroups();
        this.getGroups()
    }


    render() {
        const { classes } = this.props;
        const { groups } = this.state;
        return (
            < >
               
            <Grid container component="main" alignContent="space-around" className={[classes.root,classes.authContent]} spacing={2}>
                    <Hidden mdDown>

                        <Grid item md={8} paddingLeft={"16px"} paddingRight={"16px"}>
                            <img
                                style={{ width: "100%", height: "80vh" }}
                                src="../../../logo.jpeg" />

                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={4} paddingLeft={"16px"} paddingRight={"16px"}>
                        {this.renderForm(classes)}
                    </Grid>
                </Grid>
                </>
        );
    }
}

export default Auth;

