import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import enquiryDocumentConfigService from "../../../services/enquiryDocumentConfigService";
import logger from "../../../services/logger";
import ButtonLink from "../../common/buttonLink";
import PagingAndSorting from "../../common/pagingAndSorting";
import EnquiryDocumentConfigTable from "./enquiryDocumentConfigTable";

class EnquiryDocumentConfigs extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  getAll = async () => {
    try {
      const { data } = await enquiryDocumentConfigService.getAll();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await enquiryDocumentConfigService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const { sortColumn, data } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
            {/* <Button variant="contained" color="secondary">
              <Link to={"enquiry-document-config/new"}>{t("New Config")}</Link>
            </Button> */}
            <ButtonLink  link={"enquiry-document-config/new"} icon="add_circle" title={t("New Document")} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EnquiryDocumentConfigTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></EnquiryDocumentConfigTable>
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiryDocumentConfig"])(
  EnquiryDocumentConfigs
);
