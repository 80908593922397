// Calendar.js
import React from 'react';

function Calendar({ events, onSelect }) {
  const currentDate = new Date();

  const getEventsForDate = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return events.filter((event) => event.date === formattedDate);
  };

  const handleDateClick = (date) => {
    onSelect(date);
  };

  const renderCalendar = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
    const numDaysInMonth = lastDayOfMonth.getDate();
    const startDayOfWeek = firstDayOfMonth.getDay();

    const numWeeks = Math.ceil((numDaysInMonth + startDayOfWeek) / 7);
    const calendar = [];

    let dayCounter = 1;

    for (let week = 0; week < numWeeks; week++) {
      const weekRow = [];

      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        if (dayCounter > numDaysInMonth) {
          break;
        }

        if (week === 0 && dayOfWeek < startDayOfWeek) {
          weekRow.push(<div key={`empty-${dayOfWeek}`} className="calendar-day empty"></div>);
        } else {
          const currentDate = new Date(currentYear, currentMonth, dayCounter);
          const eventsForDate = getEventsForDate(currentDate);

          weekRow.push(
            <div
              key={dayCounter}
              className={`calendar-day ${isToday(currentDate) ? 'today' : ''}`}
              onClick={() => handleDateClick(currentDate)}
            >
              <div className="day-number">{dayCounter}</div>
              {eventsForDate.length > 0 && <div className="event-count">{eventsForDate.length}</div>}
            </div>
          );

          dayCounter++;
        }
      }

      calendar.push(<div key={week} className="calendar-week">{weekRow}</div>);
    }

    return calendar;
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  return <div className="calendar">{renderCalendar()}</div>;
}

export default Calendar;
