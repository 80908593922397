import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../../common/delete";
import Edit from "../../../common/edit";
import MatTable from "../../../common/matTable";
import { formatToTime } from "../../../common/dateUtil";

const ClassTimetableConfigTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["admissionCalendar","common"]);
  const columns = [
    // { path: "id", label: t("ID") },

    { path: "programName", label: t("common:Program") },
    {
      path: "startTime", label: t("Start Time"),numeric:true,
      content: (item) => {
        return formatToTime(item.startTime)
      }
    },
    {
      path: "endTime", label: t("End Time"),numeric:true,
      content: (item) => {
        return formatToTime(item.endTime)
      }
    },
    { path: "periodDuration", label: t("Period Duration"), numeric: true },
    { path: "numberOfPeriods", label: t("# of Periods"), numeric: true },
    {
      path: "shortBreakTime", label: t("Short Break Time"),numeric:true,
      content: (item) => {
        return formatToTime(item.shortBreakTime)
      }
    },
    { path: "shortBreakDuration", label: t("Short break Duration"), numeric: true },
    {
      path: "longBreakTime", label: t("Long Break Time"),numeric:true,
      content: (item) => {
        return formatToTime(item.longBreakTime)
      }
    },
    { path: "longBreakDuration", label: t("Long break Duration"), numeric: true },

    {
      key: "action",
      content: (item) => {
        return <><Edit link={`class-timetable-configs/${item.id}`} iconOnly="true" /> <Delete onDelete={onDelete} item={item} iconOnly="true" /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Timetable Details")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default ClassTimetableConfigTable;
