import { Card, CardActions, CardHeader, Grid, Typography } from '@mui/material';
import React from 'react';
import queryString from "query-string";
import AdmissoinFeePaymentForm from './admissoinFeePaymentForm';
import enquiryService from '../../../services/enquiryService';
import applicantDetailsJson from "../../../data/forms/applicantDetailsPreview.json"
import { withTranslation } from 'react-i18next';
import Form from '../../common/form';
import admissionFeePaymentService from '../../../services/admissionFeePaymentService';
import downloadFileService from '../../../services/downloadFileService';
import BackButton from '../../common/backButton';
import Edit from '../../common/edit';
import MatAccordion from '../../common/matAccordion';

class AdmissionFeePayments extends Form {
    state = {}

    async populateFeeData(id) {
        // const { data: fees } = await admissionFeeService.getAll();
        const queryParams = this.props.location?.search
        const programId = queryString.parse(queryParams).programId;
        const termId = queryString.parse(queryParams).termId;

        const { data: fees } = await admissionFeePaymentService.getByStudent(id)

        // const { data: fees } = await admissionFeeDetailService.getByProgramAndTerm(programId, termId)
        //console.log("fee details:", fees);
        this.setState({ fees: [fees] });
    }

    async populateTransactions(id) {
        const { data: transactions } = await admissionFeePaymentService.getTransactionsByStudent(id);
        this.setState({ transactions })
    }

    async populateApplicantData(id) {
        const { data: previewData } = await enquiryService.getEnquiryByUserId(id);
        this.setState({ previewData })
    }


    async populateData() {
        const id = this.props.match.params.id;

        // await this.populateTransactions(id);
        // await this.populateFeeData(id);
        await this.populateApplicantData(id);
    }

    handleRefresh = async () => {
        //    await this.populateData()
        this.props.history.push("/admision-applications");

    }

    handleDownload = (item) => {
        // downloadFileService.downloadFile
        downloadFileService.downloadFile("admission-fee-payments/receipt/" + item.id, item.id + "_admission_fee.pdf")

    }
    renderSummary() {

        return <Grid container >
            <Card>
                {/* <CardHeader title={this.props.t("Applicant Details for - "+this.state.previewData?.programName)} action={<BackButton {...this.props} />} /> */}
                {this.state.previewData && this.renderView(applicantDetailsJson, this.state.previewData)}
                <CardActions>
                
                    <Grid container xs={12} md={6}>
                        {this.state.previewData &&
                            <Edit variant='outlined' link={`/fill-admission-details/${this.state.previewData.userId}/${this.state.previewData.status}`} />
                        }
                    </Grid>
                </CardActions>
            </Card><br />
        </Grid>
    }
    async componentDidMount() {
        await this.populateData()
    }

    render() {
        const id = this.props.match.params.id;
        const pay = queryString.parse(this.props.location?.search).pay
        const { previewData, fees, transactions } = this.state;
        const { t } = this.props;
        return (
            <>
                <Grid container item xs="12" >
                    <BackButton {...this.props} />
                    <br />
                </Grid>

                <Grid container item xs="12">

                    <MatAccordion
                        heading={<Typography component={"span"} variant='h5' >{t(`Application Details of - ${previewData?.userName}`)} </Typography>}
                        content={this.renderSummary()}
                    />
                </Grid>

                <br />

                {/* Old implementation */}
                {/* <Grid container spacing={3} > */}
                    {/* <Grid item sm={12} md={previewData && previewData.admissionFeePaymentStatus !== "PAYMENT_DONE" ? 5 : 12}> */}
                    {previewData && <AdmissoinFeePaymentForm doRefresh={this.handleRefresh} previewData={previewData} id={id} pay={pay} programId={previewData.programId} termId={previewData.termId} />}
                    {/* </Grid> */}
                    {/* <Grid item sm={12} md={previewData && previewData.admissionFeePaymentStatus === "PAYMENT_DONE" ? 12 : 7} spacing={3}>
                        {fees && <AdmissionFeeConfigTableView  data={fees} />}
                        <br />
                        {transactions && <AdmissionFeePaymentTransactionTable onDownload={this.handleDownload} data={transactions} />}
                    </Grid> */}
                {/* </Grid> */}
            </>
        );
    }
}

export default withTranslation(["admissionFeePayments", "common"])(
    AdmissionFeePayments
);