import React, { Component } from 'react';
import ApplicantDetails from '../../enquiry-timeline/applicantDetails';
import authService from '../../../services/authService';
import queryString from "query-string";
import { Grid } from '@mui/material';
import BackButton from '../../common/backButton';

class FillApplicantDetails extends Component {
    state = {}

    componentDidMount() {
        const userId = this.props.match.params.applicantId;
        // const parsed = queryString.parse(window.location.search);
        this.setState({ userId })
        //console.log("parsed:", userId);
    }

    handleSubmit(i) {
        //console.log("submit ");
        this.props.history.push("/admision-applications");

    }

    render() {
        const { userId } = this.state;
        return (
            <>
                <Grid container spacing={3}>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction='row-reverse'>
                        <BackButton {...this.props} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {userId ? <ApplicantDetails onNext={this.handleSubmit} status={"APPLICANT"} user={{ "id": userId }} /> : ""}                </Grid>
                </Grid>
            </>
        )
    }
}

export default FillApplicantDetails;