import React from "react";
import { useTranslation } from "react-i18next";
import { ADMISSION_ENQUIRY_TARGET_ROUTE } from "../../../data/routerConstants";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const EnquiryTargetsTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiryTargets","common"]);
  const columns = [
    // { path: "id", label: t("ID") },
    { path: "programName", label: t("common:Program"), sort: true },
    { path: "target", label: t("Enquiry Target") },
    {
      key: "action",
      content: (item) => {
        return (<>
          <Edit link={`/admission-enquiry-targets/${item.id}`}/> &nbsp;<Delete onDelete={onDelete} item={item} />
        </>
        );
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading="Enquiry Targets"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EnquiryTargetsTable;
