import { Button, Fab, Hidden, Icon, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const ButtonLinkFab = ({ link, item, title, icon,type="normal",color="secondary", variant = "contained",size="small", onButtonClick,...rest }) => {
  const { t } = useTranslation(["common"]);

  function renderFabButton(){
    return (
      <React.Fragment>
          {link ? (
            <Link to={link}>
              <Fab size={size} {...rest} variant={variant ? variant : "contained"} color={color}>
                <Icon  >{icon}</Icon>
              </Fab>
            </Link>
          ) : (
             <Fab onClick={(event) => onButtonClick(item,event)} size={size} {...rest} variant={variant ? variant : "contained"} color={color}>
              <Icon  >{icon}</Icon>
            </Fab>
          )}
       
      </React.Fragment>
    );
  }

  return renderFabButton() 

  
};

export default ButtonLinkFab;
