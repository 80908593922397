import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/users";
const apiEndpoint = "/api/users";
const personalInfoApi = "personal-infos";

function create(data, userId) {
  const personalInfo = { ...data, userId: userId };
  return http.post(`${apiEndpoint}/${userId}/${personalInfoApi}`, personalInfo);
}

function update(data, userId) {
  const personalInfo = { ...data, userId: userId };
  return http.put(`${apiEndpoint}/${userId}/${personalInfoApi}`, personalInfo);
}

function get(userId, id) {
  return http.get(`${apiEndpoint}/${userId}/${personalInfoApi}/${id}`);
}

function getByUserId(userId) {
  return http.get(`${apiEndpoint}/${userId}/${personalInfoApi}`);
}
function getAll() {
  return http.get(apiEndpoint);
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getByUserId,
  getAll,
  deleteById,
};
