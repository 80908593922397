import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
const MatCircularProgressbar = (props) => {
    return (<>
        <CircularProgressbarWithChildren value={props.value}  >
            {props.content}
        </CircularProgressbarWithChildren>
    </>);
}

export default MatCircularProgressbar;