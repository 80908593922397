import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { Key } from "@mui/icons-material";

const CommitteeMemberTable = ({ data,committee,heading, sortColumn, onSort, onDelete,onEdit }) => {
  const { t } = useTranslation(["committeeMember"]);
  const columns = [
   
    {
      path: "user.firstName",
      label: t("Name")
    },
    { Key:"designation", path: "designation",label: t("Designation"), content:(item)=> {
      if(item.designation==="-1")
      return item.otherDesignation;
    else return item.designation
    } },
    {
      path: "user.email",
      label: t("Email")
    },
    {
      path: "user.mobile",
      label: t("Mobile")
    },
  
 
    {
      key: "action",
      content: (item) => {
        return <>
        <Edit item={item} onEdit={onEdit} iconOnly/> 
        &nbsp; 
        <Delete onDelete={onDelete} item={item} iconOnly/>
        </>
      },
    },
   
  ];

  return (
    <MatTable
      data={data}
      heading={heading}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default CommitteeMemberTable;
