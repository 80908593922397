import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { getDocumentTypes } from "../../../data/enums";
import admissionCalendarService from "../../../services/admissionCalendarService";
import logger from "../../../services/logger";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import academicCalendarService from "../../../services/academicCalendarService";

class AcademicCalendarForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("academicCalendar");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getEnumValue() {
    try {
      const documentTypes = getDocumentTypes();

      const dropDowns = { ...this.state.dropDowns };

      dropDowns["documentTypes"] = documentTypes;

      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("applicationFormStatus ", ex);
    }
  }

  onSelectOf = async (item, value) => { 
    switch (item) {
      case "instituteId":
        await this.getPrograms(value);
        break;
    }
  };

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await academicCalendarService.get(id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    await this.populateForm();
    this.getEnumValue();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await academicCalendarService.create(this.state.data);
      } else {
        await academicCalendarService.update(this.state.data);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t(titleText)} />

          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["admissionCalendar", "common"])(
  AcademicCalendarForm
);
