import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { formatToDate } from "../../common/dateUtil";

const AcademicCourseTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["academicCourse","common"]);
  const columns = [
    // { path: "id", label: t("ID") },

    { path: "programName", label: t("common:Program Name") },
    { path: "termLabel", label: t("Std/Year") },
    { path: "name", label: t("Course Name") },
    { path: "maxMarks", label: t("Max Marks") },
    {
      key: "action",
      content: (item) => {
        return <><Edit link={`academic-courses/${item.id}`} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Courses")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default AcademicCourseTable;
