import { FormControl, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ADMISSION_ENQUIRY_ROUTE } from "../../../data/routerConstants";
import MatTable from "../../common/matTable";

const EnquiryAllocationsTable = ({
  data,
  counsellor,
  counsellors,
  sortColumn,
  onSort,
  onDelete,
  onCounsellorSelect,
}) => {
  const { t } = useTranslation(["enquiries", "common"]);
  const columns = [
    {
      path: "enquiryNumber",
      label: t("Enquiry #"),
      content: (item) => {
        return (
          <Link to={`${ADMISSION_ENQUIRY_ROUTE}/${item.id}`}>
            {item.enquiryNumber}
          </Link>
        );
      },
    },

    { path: "userName", label: t("Name") },
    { path: "userMobile", label: t("Mobile #") },

    { path: "source", label: t("Source") },
    // { path: "enquiryType", label: t("Type") },
    // { path: "lastFollowedUpDate", label: t("LFD") },
    // { path: "nextFollowUpDate", label: t("NFD") },
    {
      key: "followup",
      label: "Allocate",
      content: (item) => {
        return (
          <FormControl>
            {/* <InputLabel htmlFor="age-native-simple">Age</InputLabel> */}
            <Select
              native
              value={item.counsellorId}
              onChange={(event) => onCounsellorSelect(event, item.id)}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
            >
              <option value="NA" aria-readonly>
                Counsellor
              </option>
              {counsellors &&
                counsellors.map((c) => (
                  <option value={c.id}>{c.firstName}</option>
                ))}
              {/* <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={88}>Thirty</option>
              <option value={21}>Forty</option>
              <option value={89}>Fifty</option> */}
            </Select>
          </FormControl>
        );
      },
    },
  ];

  return (
    <MatTable
      data={data}
      heading="Enquiry Allocations"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EnquiryAllocationsTable;
