import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ADMISSION_ENQUIRY_ROUTE } from "../../../data/routerConstants";
import authService from "../../../services/authService";
import ButtonLink from "../../common/buttonLink";
import MatTable from "../../common/matTable";

const ApplicationTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiries", "common"]);
  const columns = [
    // {
    //   path: "applicationNumber",
    //   label: t("Application #")
    // },
    { path: "userName", label: t("Name") },
    { path: "userMobile", label: t("Mobile #") },
    // { path: "userEmail", label: t("Email") },


    {
      path: "programName",
      label: t("common:Program"),

      sort: true,
    }, {
      path: "submittedDate",
      label: t("Submitted Date"),
      sort: true,
    },
    {
      path: "applicationStatus",
      label: t("Status"),
      sort: true,
      content:(item)=>{
        return <label htmlFor="" style={{color:"white", backgroundColor:item.applicationStatus=== "APPROVED"?'green':item.applicationStatus=== "REJECTED"?"red":"orange"}}>{item.applicationStatus} </label>
      }
    },

    {
      key: "verifyApprove",
      label: "Action",
      content: (item) => {
        return item.applicationStatus === "SUBMITTED" ?

          <ButtonLink
            title={t("Verify and Approve")}
            link={"/verify-and-approve-applications/" + item.id}
            item
            icon={"visibility"}
          />
          :
          <ButtonLink
            title={t("View")}
            link={"/verify-and-approve-applications/" + item.id}
            item
            icon={"visibility"}
          />


      },
    },
  ];

  return (
    <MatTable
      data={data}
      heading="List of Applications"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default ApplicationTable;
