import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ADMISSION_APPLICATION_FORM_FEE_CONFIG_ROUTE } from "../../../data/routerConstants";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const ApplicationFeeConfigsTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["applicationFeeConfigs","common"]);
  const columns = [
    // { path: "id", label: t("ID") },
    {
      path: "programName",
      label: t("common:Program"),
      sort: true,
    },
    // { path: "instituteName", label: t("Institute") },
    { path: "feeAmount", label: t("Fee") },
    { path: "academicYear", label: t("Academic Year") },
    { path: "status", label: t("Status") },
    {
      key: "action",
      content: (item) => {
        return (
          <>
          <Edit
            link={`${ADMISSION_APPLICATION_FORM_FEE_CONFIG_ROUTE}/${item.id}`}/> &nbsp;&nbsp;
            <Delete onDelete={onDelete} item={item} />
            </>
        );
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Application Fees")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default ApplicationFeeConfigsTable;
