import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import academicCourseService from "./academicCourseService";
import AcademicCourseTable from "./academicCourseTable";
import ProgramFilter from "../../program/programFilter";
import termDataService from "../../../services/termDataService";
import MatSelect from "../../common/matSelect";
import MatTablePagination from "../../common/matTablePagination";
import programService from "../../../services/programService";

class AcademicCourses extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  onConfirmOk = async (id) => {
    try {
      await academicCourseService.deleteById(id);
      await this.getPagedData({...this.query()});
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getPagedData({...this.query()})
    this.getPrograms()
  }

  // getPrograms = async () => {
  //   try {
  //     const { data: programs } = await programService.getAll();
  //     this.setState({ programs });
  //   } catch (ex) {
  //     logger.error(ex);
  //   }
  // };
  handleProgramChange = async (event) => {
    const programId = event.target.value;
    this.setState({ programId })
    console.log("event.target.value:", programId);
    const { from, to } = this.state;

    if (programId != -1) {
      const { data: terms } = await termDataService.getAllByProgram(event.target.value);
      this.setState({ terms });
      if (terms.length === 1) {
        this.getPagedData({ ...this.query(), programId: event.target.value, termId: terms[0].id, from, to });
      }
    } else {
      this.getPagedData({ ...this.query(), programId: -1, termId: -1, from, to });
    }
  }

  handleTermChange = async (event) => {
    this.setState({ termId: event.target.value });
    const { programId, from, to } = this.state;

    this.getPagedData({ ...this.query(), programId, termId: event.target.value, from, to });

  }

  query(search = "") {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { search, page, size, sort };
  }

  getPagedData = async (query) => {
    try {
      const { programId, termId } = query;
      query.programId = programId ? programId : this.state.programId;
      query.termId = termId ? termId : this.state.termId;
      const { data, headers } = await academicCourseService.getAllByQuery(query);
      const totalElements = headers["x-total-elements"];
      
      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { 
      sortColumn,  
      rowsPerPage,
      page,
      totalElements ,
      data,
      programId,
      programs,
      terms,
      termId,
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
  
            <Grid item xs={12} md={2}>
            <MatSelect label={t("common:Filter By Program")} isOptionAllRequired={false} onChange={this.handleProgramChange} options={programs} value={programId} />
          </Grid>
          {terms && terms.length > 1 &&
            <Grid item xs={12} md={2}>
              <MatSelect label={t("common:Filter By Term")} isOptionAllRequired={false} onChange={this.handleTermChange} options={terms} value={termId} />
            </Grid>
          }
            <Grid container item sm={12} md={terms && terms.length > 1 ? 8 : 10} direction="row-reverse">
              <Add link={"academic-courses/new"} title={t("New Course")} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>

            <AcademicCourseTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
              <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["academicCourses","common"])(AcademicCourses);
