import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { getResultTypes } from "../../../data/enums";
import educationService from "../../../services/educationService";
import icamelService from "../../../services/icamelService";
import logger from "../../../services/logger";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class EducationForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("education");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getBoardOrUniversities = async () => {
    try {
      const { data } = await icamelService.getBoardOrUniversities();

      const dropDowns = this.state.dropDowns;
      dropDowns.universities = data;
      this.setState({ dropDowns });
    } catch (ex) {
      console.log("courses ", ex);
    }
  };

  async getCourses() {
    try {
      const { data: courses } = await icamelService.getCourses();
      const { dropDowns } = this.state;
      dropDowns.courses = courses;
      this.setState({ dropDowns });
    } catch (error) {}
  }

  async getSpecializationsByCourse(value) {
    try {
      const { data: specializations } =
        await icamelService.getSpecializationsByCourse(value);
      const { dropDowns } = this.state;
      dropDowns.specializations = specializations;
      this.setState({ dropDowns });
    } catch (error) {}
  }

  onSelectOf = (item, value) => {
    switch (item) {
      case "course":
        this.getSpecializationsByCourse(value);
        break;
    }
  };

  getResultTypes = () => {
    try {
      const dropDowns = this.state.dropDowns;
      dropDowns.resultTypes = getResultTypes();
      this.setState({ dropDowns });
    } catch (ex) {
      console.log("courses ", ex);
    }
  };

  async populateForm() {
    const { id, userId } = this.props;
    if (id === "new") return;
    try {
      const { data } = await educationService.get(userId, id);
      delete data.userId;
      delete data.universityName;
      delete data.courseName;
      delete data.specializationName;
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    this.getBoardOrUniversities();
    this.getCourses();
    this.getResultTypes();
    await this.populateForm();
    const { data } = this.state;
    //console.log("data course:", data.course);
    if (data.course) this.getSpecializationsByCourse(data.course);
  }

  doSubmit = async (id) => {
    const { userId } = this.props;
    const { dropDowns, data } = this.state;

    const course = dropDowns.courses.find((c) => c.id === data.course);
    const specialization = dropDowns.specializations.find(
      (s) => s.id === data.specialization
    );
    //console.log("spezs:", specialization);
    const university = dropDowns.universities.find(
      (u) => u.id === data.university
    );

    try {
      if (id === "new") {
        await educationService.create(userId, {
          ...data,
          graduationType: course.graduationType,
          courseName: course.name,
          specializationName: specialization.name,
          universityName: university.name,
        });
      } else {
        //update method
        await educationService.update(userId, {
          ...data,
          graduationType: course.graduationType,
          courseName: course.name,
          specializationName: specialization.name,
          universityName: university.name,
        });
      }
      this.props.onSave();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          {/* <CardHeader title={t(titleText)} /> */}

          {this.formJson.groups.map((group) => (
            <div key={group.name + "-div"}>
              <CardContent>
                <Typography
                  color="primary"
                  variant="h6"
                  component="h2"
                  gutterBottom
                >
                  {group.name}
                </Typography>
                <Grid container spacing={1}>
                  {group.fields.map(
                    (field) =>
                      !field.notShow && (
                        <Grid
                          key={field.name + "-grid"}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                        >
                          {this.renderField(field, dropDowns)}
                        </Grid>
                      )
                  )}
                </Grid>
              </CardContent>
              <Divider />
            </div>
          ))}

          <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["educations", "common"])(EducationForm);
