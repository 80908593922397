function prepareOptions(data, max) {
  return {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        // min: 1,
        max: max,
        width: 3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };


}




export function prepareMapDataToChartData(data, max, colors) {
  //console.log("chart data:", data);
  if (data) {

    const labels = Object.keys(data).sort((e1, e2) => e1 - e2);
    const dataSet = labels.map((l) => data[l]);
    const options = prepareOptions(data, max);
    return {
      options: options,
      data: {
        labels,
        datasets: [
          {
            data: dataSet,
            backgroundColor: ['#3498db'],
          },
        ],
      },
    };
  }
}

export function prepareChartData(data, colors) {
  if (data) {

    const labels = data.map((s) => s.label);
    const dataSet = labels.map(
      (l) => data.find((s) => s.label === l).count
    );
    const options = prepareOptions(data, 10);
    return {
      options: options,
      data: {
        labels,
        datasets: [
          {
            data: dataSet,
            backgroundColor: colors,
          },
        ],
      },
    };
  }
}