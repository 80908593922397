import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";
import { formatToDate } from "../../common/dateUtil";

const ApplicantFollowupsTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiryFollowup", "common"]);
  const columns = [
    { path: "followUpDate", label: t("FD"), sort: true,helperText:t("Followup Date"),
    
    content: (item) => {
     return formatToDate(item.followUpDate)
   } },
    { path: "note", label: t("Comments") },
    { path: "nextFollowUpDate", label: t("NFD"), helperText:t("Next Followup Date"),
    
    content: (item) => {
     return formatToDate(item.nextFollowUpDate)
   }},
    { path: "enquiryType", label: t("Enquiry Type") },
    { path: "followUpType", label: t("Followup Type") },
  ];

  return (
    <MatTable
      data={data}
      heading={"Followup History"}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default ApplicantFollowupsTable;
