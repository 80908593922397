import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const ApplicantEducationDetailsTable = ({ data, sortColumn, onSort, onDelete, onEdit }) => {
  const { t } = useTranslation(["educations"]);
  const columns = [
    { path: "id", label: t("ID") },
    {
      path: "courseName",
      label: t("Course"),
      sort: true,
    },
    { path: "specializationName", label: t("Specialization") },
    { path: "universityName", label: t("Board or University") },
    { path: "yearOfPassing", label: t("Year of Passing") },
    { path: "obtainedPercentageOrCgpa", label: t("Percentage / CGPA") },
    {
      key: "action",
      content: (item) => <Edit item={item} onEdit={onEdit} />,
    },
    {
      key: "delete",
      content: (item) => <Delete item={item} onDelete={onDelete} />,
    },
  ];

  return (
    <MatTable
      data={data}
      heading="Education Details"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default ApplicantEducationDetailsTable;
