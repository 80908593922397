import React, { Component } from 'react';
import committeeMemberService from '../../services/committeeMemberService';
import committeeService from '../../services/committeeService';
import CommitteeMembers from '../committee/committee-member/committeeMembers';

class IQACComposition extends Component {
    state = {}

    async getCommitte() {
        const { data: committee } = await committeeService.getByName("IQAC");
        this.setState({ committee });
    }

    async getAllByCommittee(committeeId) {
        const { data: members } = await committeeMemberService.getAllByCommittee(this.state.committee.id)
        this.setState({ members })
    }

    handleMemberAdd = () => {
        this.getAllByCommittee(this.state.committee.id);
    }

    async componentDidMount() {
        await this.getCommitte();
        await this.getAllByCommittee(this.state.committee.id)
    }

    render() {
        const { committee, members } = this.state;
        return (
            <>
            {members &&  <CommitteeMembers bom={true} onMemberAdd={this.handleMemberAdd} committee={committee} members={members}/>} 
            
            </>
        );
    }
}

export default IQACComposition;