import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { prepareChartData, prepareMapDataToChartData } from "../../common/chartUtil"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme) => ({
  dashboardCard: {
    height: "100%",
    [theme.breakpoints.up('md')]: {
      height: "325px"
    }
  }
}))

function getText(key, label, data, onClick) {
  return (
    <ListItem button disableGutters>
      <ListItemText disableTypography
        // onClick={key==="ALL"?
        //     () => onClick("/manage-applications/applicants?type=" + key):
        //     () => onClick("/admision-applications?status=" + key)}
        primary={
          <div style={{ margin: 0, textAlign: "center" }}>
            <span> {label}</span>
            <div style={{ fontSize: 20 }}>
              <strong>{data}</strong>
            </div>
          </div>
        }
      />
    </ListItem>
  );
}

const AdmissionStatsCard = ({ data, stats, onClick }) => {
  const classes = useStyles();
  const chartData = {
    "Applications": data.totalApplicationCount,
    "Approved": data.totalApprovedApplicationCount,
    "Admissions": data.totalAdmissionCount,
    "Cancellations": data.totalAdmissionCanceledCount
  }
  // const total=stats.total;
  // delete stats.total;
  // const dataSet= prepareChartData(chartData,['green','yellow','red']);
  const dataSet = prepareMapDataToChartData(chartData, data.totalApplicationCount, ['blue', 'green', 'red']);
  // console.log("data set ;",dataSet.data);
  return (
    <React.Fragment>
      <Card className={classes.dashboardCard}>
        <CardHeader title="Admissions" className="dashboard-card-heading"></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <List component="nav" disablePadding aria-label="mailbox folders">
                {getText("ALL", "Applications ", data.totalApplicationCount, onClick)}
                <Divider/>
                {getText("APPROVED", "Approved", data.totalApprovedApplicationCount, onClick)}
                <Divider />
                {getText("ENROLLED", "Admissions ", data.totalAdmissionCount, onClick)}
                <Divider />
                {getText("CANCELLED", "Cancellations ", (data.totalAdmissionCanceledCount === 0 ? "00" : data.totalAdmissionCanceledCount), onClick)}
                <Divider />
              </List>
            </Grid>
            <Grid container xs={12} alignItems={"baseline"} md={8}>
              <Grid item xs={12} md={12}>
                <br />
                {dataSet && <Bar
                  options={dataSet.options}
                  data={dataSet.data}
                  height={260}
                />}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Bar options={options} data={dataSet} /> */}
    </React.Fragment>
  );
};

export default AdmissionStatsCard;
