import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { formatToDate } from "../../common/dateUtil";

const AcademicCalendarTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["admissionCalendar"]);
  const columns = [
    // { path: "id", label: t("ID") },

    { path: "academicYear", label: t("Academic Year") },
    { path: "startDate", label: t("Start Date"),
    content:(item)=>{
     return formatToDate(item.startDate)
    } },
    { path: "endDate",
     label: t("End Date"),
     content:(item)=>{
      return formatToDate(item.endDate)
     }
     },
    {
      key: "active",
      label: "Enabled",
      content: (obj) => (obj.active ? "Yes" : "No"),
    },
    {
      key: "action",
      content: (item) => {
        return <><Edit link={`admission-calendars/${item.id}`} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading="List of Academic Calendars"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default AcademicCalendarTable;
