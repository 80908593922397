import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import groupService from "../../services/groupService";
import logger from "../../services/logger";
import PagingAndSorting from "../common/pagingAndSorting";
import GroupsTable from "./groupsTable";

class Groups extends PagingAndSorting {
  state = {
    groups: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  componentDidMount() {
    this.getGroups();
  }

  onConfirmOk = (id) => {
    this.deleteGroup(id);
  };

  getGroups = async () => {
    try {
      const { data: groups } = await groupService.getGroups();
      this.setState({ groups });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteGroup = async (id) => {
    try {
      const { data } = await groupService.deleteGroup(id);
      await this.getGroups();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, groups } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          {/* <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            direction="row-reverse"
          >
            <Button variant="contained" color="secondary">
              <Link to={"/groups/new"}>{t("New Group")}</Link>
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <GroupsTable
              data={groups}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              
            ></GroupsTable>
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure, You want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["group"])(Groups);
