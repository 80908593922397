import { Card,CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  getBloodGroups,
  getGenders,
  getMotherTongue,
  getNationality,
  getReligion,
} from "../../../data/enums";
import programService from "../../../services/degreeService";
import enquiryService from "../../../services/enquiryService";
import icamelService from "../../../services/icamelService";
import logger from "../../../services/logger";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class ApplicantBasicDetailsForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("applicantBasicDetailsForm");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getPrograms = async () => {
    try {
      const { data } = await programService.getPrograms();
      const dropDowns = this.state.dropDowns;
      dropDowns.programs = data;
      this.setState({ dropDowns });
    } catch (ex) {
      console.log("programs ", ex);
    }
  };

  getGenders = () => {
    const { dropDowns } = { ...this.state };
    const updated = { ...dropDowns, genders: getGenders() };
    this.setState({ dropDowns: updated });
  };

  // getBloodGroups = () => {
  //   const { dropDowns } = { ...this.state };

  //   const updated = { ...dropDowns, bloodGroups: getBloodGroups() };
  //   this.setState({ dropDowns: updated });
  // };

  getBloodGroups = () => {
    const { dropDowns } = { ...this.state };
    const updated = {
      ...dropDowns,
      bloodGroups: getBloodGroups(),
      nationalities: getNationality(),
      religions: getReligion(),
      motherTongues: getMotherTongue(),
    };
    this.setState({ dropDowns: updated });
  };

  async populateForm() {
    const user = this.props.user;
    const status=this.props.status;
    try {
      //console.log("user:", user);
      // const { data } = await enquiryService.getEnquiryByUserIdIfApplicant(user.id);
            const { data } = await enquiryService.getEnquiryByUserIdAndStatus(user.id,status);

      // const { data } = this.props;
      //console.log("data:",data);
      if (data) this.mapToViewModel(data);
      else return;
    } catch (ex) {
      // logger.error(ex);
      if (user.id) this.mapToViewModel({ ...user });
    }
  }

  mapToViewModel(obj) {
    this.setState({ data: getStateObject(this.formJson.groups, obj) });
  }

  async componentDidMount() {
    await this.populateForm();
    this.getBloodGroups();
    this.getGenders();
  }

  raiseBack = () => {
    this.props.onBack();
  };

  doSubmit = async (id) => {
    //console.log("user id:", id);
    try {
      if (this.state.data.id) {
        //update method
      const {data}=  await enquiryService.update({...this.state.data,applicant:true}, id);
      this.setState({data});
      } else {
        await enquiryService.create(this.state.data, id);
      }
      this.props.onNext(1);
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t, user } = this.props;
    const { dropDowns } = this.state;
    const buttonText = getFormButtonText(user.id);
    const titleText = getFormTitleText(user.id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, user.id)}>
          {this.renderForm(this.formJson, dropDowns,false)}
          <CardContent>
            <Grid container item md={12} justifyContent="center">
              {this.renderMatButton(t(buttonText))}
              {/* {this.renderMatButton(t("common:Reset"), "reset", "secondary")} */}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["applicantBasicDetailsForm", "common"])(
  ApplicantBasicDetailsForm
);

