import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import addressService from "../../../services/addressService";
import icamelService from "../../../services/icamelService";
import logger from "../../../services/logger";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class AddressForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("address");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getCountries = async () => {
    try {
      const { data } = await icamelService.getCountries();

      const dropDowns = this.state.dropDowns;
      dropDowns.countries = data;
      this.setState({ dropDowns });
    } catch (ex) {
      console.log("coutries ", ex);
    }
  };

  async getStatesByCountry(country) {
    const { data: states } = await icamelService.getStatesByCountry(country);
    // this.setState({ states });
    const dropDowns = this.state.dropDowns;
    dropDowns.states = states;
    this.setState({ dropDowns });
  }

  async getCitiesByState(state) {
    const { data: cities } = await icamelService.getCitiesByState(state);
    // this.setState({ cities });
    const dropDowns1 = this.state.dropDowns;
    dropDowns1.cities = cities;
    this.setState({ dropDowns: dropDowns1 });
  }

  onSelectOf = async (item, value) => {
    switch (item) {
      case "country" || "paCountry":
        await this.getStatesByCountry(value);
        break;

      case "state" || "paState":
        await this.getCitiesByState(value);
        break;

      case "sameAsCommunication":
        const { data } = { ...this.state };
        if (value) {
          const address = {
            ...data,
            ...{
              sameAsCommunication: value,
              paCountry: data.country,
              paState: data.state,
              paCity: data.city,
              paAddressLine1: data.addressLine1,
              paAddressLine2: data.addressLine2,
              paPostalCode: data.postalCode,
            },
          };

          this.setState({ data: address });
        } else {
          const address = {
            ...data,
            ...{
              sameAsCommunication: value,
              paCountry: "",
              paState: "",
              paCity: "",
              paAddressLine1: "",
              paAddressLine2: "",
              paPostalCode: "",
            },
          };
          this.setState({ data: address });
        }
        break;
    }
  };

  async populateForm() {
    const user = this.props.user;
    console.log("user:", user);
    try {
      const { data } = await addressService.getByUserId(user.id);
      if (data.id) this.mapToViewModel(data);
      // this.setState({ data });
    } catch (ex) {
      // logger.error(ex);
    }
  }
  mapToViewModel(obj) {
    this.setState({ data: getStateObject(this.formJson.groups, obj) });
  }

  async componentDidMount() {
    await this.getCountries();
    await this.populateForm();
    const { data } = this.state;
    if (data.country) this.getStatesByCountry(data.country);
    if (data.state) this.getCitiesByState(data.state);
  }
  raiseBack = () => {
    this.props.onBack();
  };

  doSubmit = async (id) => {
    try {
      if (this.state.data.id) {
        //update method
        await addressService.update(this.state.data, id);
      } else {
        await addressService.create(this.state.data, id);
      }
      this.props.onNext();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t, user, activeStep, stepsLength } = this.props;
    const { dropDowns } = this.state;
    // const user.id = this.props.user.id;

    const buttonText = getFormButtonText(user.id);

    const titleText = getFormTitleText(user.id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, user.id)}>
          {/* <CardHeader title={t(titleText)} /> */}
          {this.renderForm(this.formJson, dropDowns)}
          {/* {this.formJson.groups.map((group) => (
            <div key={group.name + "-div"}>
              <CardContent>
                <Typography
                  color="primary"
                  variant="h6"
                  component="h2"
                  gutterBottom
                >
                  {group.name}
                </Typography>
                <Grid container spacing={1}>
                  {group.fields.map(
                    (field) =>
                      !field.notShow && (
                        <Grid
                          key={field.name + "grid"}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                        >
                          {this.renderField(field, dropDowns)}
                        </Grid>
                      )
                  )}
                </Grid>
              </CardContent>
              <Divider />
            </div>
          ))} */}

          <CardContent>
            <Grid container item md={12} justifyContent="center">
              {this.renderMatButton(t(buttonText))}
              {/* {this.renderMatButton(t("common:Reset"), "reset", "secondary")} */}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["address", "common"])(AddressForm);
