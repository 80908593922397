import { Card } from "@mui/material";
import React from "react";
import logger from "../../../../services/logger";
import Form from "../../../common/form";
import {
  getJoiSchema,
  getStateObject,
} from "../../../common/formUtils";
import  objectivesJson  from "./objectives.json";
import groupService from "../../../../services/groupService";
class ObjectivesForm extends Form {
  constructor() {
    super();
    this.formJson = objectivesJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

 

  async populateForm() {
    const id = this.props.id;
    if (id === "new") return;
    try {
      const { data } = await groupService.getGroupObjectives(id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    await this.populateForm();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await groupService.create(this.state.data);
      } else {
        await groupService.partialUpdateGroup(this.state.data);
      }
      this.props.onSave()
      // this.props.history.push("/configurations/terms-and-conditions");
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.id;

    return (
      <Card>
          {/* <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} /> */}

        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default ObjectivesForm;
