import { Hidden } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ButtonLink from "./buttonLink";
import ButtonLinkFab from "./buttonLinkFab";
const Add = ({ link, item, onAdd, title, type = "normal | fab | iconOnly" }) => {
  const { t } = useTranslation(["common"]);
  return (<>
    <Hidden only={["md", "lg", "xl"]}>
      <ButtonLinkFab
        title={title}
        link={link}
        item={item}
        icon={"add"}
        onButtonClick={onAdd}
      />
    </Hidden>
    <Hidden only={["xs", "sm"]}>
      <ButtonLink
        title={title}
        link={link}
        item={item}
        icon={"add_circle"}
        onButtonClick={onAdd}
      />
    </Hidden>
  </>)
};

export default Add;
