import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";

const MyEnquiryTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiries", "common"]);
  //console.log("data in table : ", data);
  const columns = [
    {
      path: "enquiryNumber",
      label: t("Enquiry #"),
    },
    {
      path: "degreeAlias",
      label: t("Course"),
      sort: true,
    },
    {
      path: "programAlias",
      label: t("Specialization"),
      sort: true,
    },
    // { path: "userName", label: t("Name") },
    { path: "userMobile", label: t("Mobile #") },
    // { path: "userEmail", label: t("Email") },
    // { path: "source", label: t("Source") },
    { path: "enquiryType", label: t("Type") },
    { path: "lastFollowedUpDate", label: t("LFD") },
    { path: "nextFollowUpDate", label: t("NFD") },
  ];

  return (
    <MatTable
      data={data}
      heading="My Enquiry Details"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default MyEnquiryTable;
