import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class PaymentAndReceiptForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("paymentAndReceipt");
    // this.pay=this.props.pay;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }



  onSelectOf = (item, value) => {

  };



  async populateForm() {
    const user = this.props.user;

    try {
      const { data } = this.props;
      //console.log("data ===: ", data);
      if (data.id) this.mapToViewModel(data);
      else return;
    } catch (ex) {
      logger.error(ex);
      if (user.id) this.mapToViewModel({ ...user });
    }
  }

  mapToViewModel(obj) {
    this.setState({ data: getStateObject(this.formJson.groups, obj) });
  }

  async componentDidMount() {
    await this.populateForm();
  }


  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async createOrder(data) {
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const result = await enquiryService.createPaymentOrder(data,this.props.pay);

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    //console.log("result=================:", result);
    const { userName, userEmail, userMobile, description, amount, id: order_id, currency, key, receiverName, notes } = result.data;

    const options = {
      key: key, // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: currency,
      name: receiverName,
      description: description,
      image: "",
      order_id: order_id,
      pay:this.props.pay,
      handler: this.props.pay==="ADMISSION_FEE"? async function (response) {
        const data = {
          id: order_id,
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id,
          signature: response.razorpay_signature,
        };
        //console.log("pay ::",this.pay);

        await enquiryService.verifyAdmissionFeePayment(data);

        //console.log(result.data);
        window.location="enquiry-timeline-copy"

      }:async function (response) {
        //console.log("response====:",response);
        const data = {
          id: order_id,
          paymentId: response.razorpay_payment_id,
          orderId: response.razorpay_order_id,
          signature: response.razorpay_signature,
        };
        //console.log("pay ::",this.pay);

         await enquiryService.verifyPayment(data);
        //console.log(result.data);
        window.location="enquiry-timeline-copy"
      },
      prefill: {
        name: userName,
        email: userEmail,
        contact: userMobile,
      },
      notes: { ...notes },
      theme: {
        color: "#61dafb",
      },
    };
    
    const paymentObject = new window.Razorpay(options);
  const obj=await paymentObject.open();
    return obj;
  }

  raiseBack = () => {
    this.props.onBack();
  };

  doSubmit = async (id) => {
    //console.log("user id:", id);
    try {
      if (this.state.data.id) {
        //update method
        // const { data } = await enquiryService.paymentOrder(this.state.data);
       await this.createOrder(this.state.data)
       //console.log("order data:",order);
        // this.props.onNext(data.stage);
      } else {
        // await enquiryService.create(this.state.data);
      }
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t, user } = this.props;
    const { dropDowns } = this.state;

    // const buttonText = getFormButtonText(user.id);

    // const titleText = getFormTitleText(user.id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, user.id)}>
          {this.renderForm(this.formJson, dropDowns,false)}
          <CardContent>
            <Grid container item md={12} justifyContent="center">
              {this.renderMatButton(t("common:Pay Now"))}
              {/* {this.renderMatButton(t("common:Reset"), "reset", "secondary")} */}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["paymentAndReceipt", "common"])(
  PaymentAndReceiptForm
);
