import { Icon, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { INSTITUTE_ROUTE } from "../../data/routerConstants";
import Delete from "../common/delete";
import Edit from "../common/edit";
import MatTable from "../common/matTable";

const InstituteBasicTable = ({ data, sortColumn, onSort, onDelete,onEdit }) => {
  const { t } = useTranslation(["institute"]);
  function renderListItem(icon, text) {
    return (
      <ListItem style={{paddingLeft:"0"}} >
        <ListItemIcon style={{minWidth:"38px"}}>
          <Icon>{icon} </Icon>
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    )

  }
  const columns = [
    // { path: "id", label: t("ID") },
    {
      path: "name",
      label: t("Name"),
      cardHeader:true,
      // content: (institute) => {
      //   return (
      //     <Link to={`institute-basics/${institute.id}`}> {institute.name} </Link>
      //   );
      // },
    },{
      path:"principalName",
      label:t("Principal/VC Name")
    },
    {
      path:"principalEmail",
      label:t("Email ID")
    },
    {
      path:"principalMobile",
      label:t("Mobile #")
    },
    {
      path:"hrName",
      label:t("Hr Name")
    },
    {
      path:"hrEmail",
      label:t("Email ID")
    },
    {
      path:"hrMobile",
      label:t("Mobile #")
    },
    // {
    //   key: "principalDetails",
    //   label: t("Principal Details"),
    //   content: (institute) => {
    //     return (         
    //       <List component="nav" aria-label="principalDetails">
    //         {renderListItem("person", institute.principalName)}
    //         {renderListItem("mail", institute.principalEmail)}
    //         {renderListItem("call", institute.principalMobile)}
    //       </List>
    //     )
    //   }
    // }, {
    //   key: "hrDetails",
    //   label: t("HR Details"),
    //   content: (institute) => {
    //     return (          
    //       <List component="nav" aria-label="principalDetails">
    //         {renderListItem("person", institute.hrName)}
    //         {renderListItem("mail", institute.hrEmail)}
    //         {renderListItem("call", institute.hrMobile)}
    //       </List>
    //     )
    //   }
    // },
   
    {
      key: "action",
      content: (item) => {
        return <>
        <Edit item={item} onEdit={onEdit} iconOnly /> &nbsp;&nbsp;
        <Delete onDelete={onDelete} item={item} iconOnly />
        </>;
      },
    }
  ];

  return (
    <MatTable
      heading={t("List of Institutions")}
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default InstituteBasicTable;
