import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  getBloodGroups,
  getGenders,
  getMotherTongue,
  getNationality,
  getReligion,
} from "../../data/enums";
import programService from "../../services/degreeService";
import enquiryService from "../../services/enquiryService";
import icamelService from "../../services/icamelService";
import logger from "../../services/logger";
import personalInfoService from "../../services/personalInfoService";
import { getDynamicFormJson } from "../common/dynamicFormService";
import Form from "../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";

class EnquiryBasicInfoForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("enquiryBasicInfo");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getPrograms = async () => {
    try {
      const { data } = await programService.getPrograms();

      const dropDowns = this.state.dropDowns;
      dropDowns.programs = data;
      this.setState({ dropDowns });
    } catch (ex) {
      console.log("programs ", ex);
    }
  };

  onSelectOf = (item, value) => {
    switch (item) {
      case "country" || "paCountry":
        const { data: countries } = icamelService.getStatesByCountry(value);
        this.setState({ countries });
        break;

      case "state" || "paState":
        const { data: states } = icamelService.getCitiesByState(value);
        this.setState({ states });
        break;

      case "sameAsCommunication":
        const { data } = this.state;
        if (value) {
          const address = {
            ...data,
            ...{
              sameAsCommunication: value,
              paCountry: data.country,
              paState: data.state,
              paCity: data.city,
              paAddressLine1: data.addressLine1,
              paAddressLine2: data.addressLine2,
              paPostalCode: data.postalCode,
            },
          };

          this.setState({ data: address });
        } else {
          const address = {
            ...data,
            ...{
              sameAsCommunication: value,
              paCountry: "",
              paState: "",
              paCity: "",
              paAddressLine1: "",
              paAddressLine2: "",
              paPostalCode: "",
            },
          };
          this.setState({ data: address });
        }
        break;
    }
  };

  getGenders = () => {
    const { dropDowns } = { ...this.state };

    const updated = { ...dropDowns, genders: getGenders() };
    this.setState({ dropDowns: updated });
  };



  getBloodGroups = () => {
    const { dropDowns } = { ...this.state };

    const updated = {
      ...dropDowns,
      bloodGroups: getBloodGroups(),
      nationalities: getNationality(),
      religions: getReligion(),
      motherTongues: getMotherTongue(),
    };
    this.setState({ dropDowns: updated });
  };

  async populateForm() {


    try {

      const { data } = this.props;
      //console.log("data ===: ", data);
      if (data.id) this.mapToViewModel(data);
      else return;
    } catch (ex) {
      logger.error(ex);
    }
  }

  mapToViewModel(obj) {
    this.setState({ data: getStateObject(this.formJson.groups, obj) });
  }

  async componentDidMount() {
    await this.populateForm();
    this.getGenders();
  }

  raiseBack = () => {
    this.props.onBack();
  };

  doSubmit = async (id) => {
    //console.log("user id:", id);
    try {
      if (this.state.data.id) {
        //update method
        const { data } = await enquiryService.apply(this.state.data);
        this.props.onNext(data.stage);
      } else {
        await enquiryService.create(this.state.data);
      }
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t, user, activeStep, stepIndex, currentStep } = this.props;
    const { dropDowns } = this.state;

    const buttonText = getFormButtonText(user.id);

    const titleText = getFormTitleText(user.id, this.formJson.alias);
    //console.log("current step :", currentStep, "-stepindex:", stepIndex);
    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, user.id)}>
          {this.renderForm(this.formJson, dropDowns,false)}
          {currentStep === stepIndex && <CardContent>
            <Grid container item md={12} justifyContent="center">
              {this.renderMatButton(t("common:Apply"))}
            </Grid>
          </CardContent>}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["pesonalInfo", "common"])(
  EnquiryBasicInfoForm
);
