import React, { Component } from 'react';
import ApplicantDetails from '../../enquiry-timeline/applicantDetails';
import authService from '../../../services/authService';
import queryString from "query-string";
import EditableApplicantDetails from '../../enquiry-timeline/editableApplicantDetails';
import BackButton from '../../common/backButton';
import { Grid } from '@mui/material';

class FillAdmissionDetails extends Component {
    state = {}

    componentDidMount() {
        const userId = this.props.match.params.applicantId;
        const status = this.props.match.params.status;

        // const parsed = queryString.parse(window.location.search);
        this.setState({ userId, status })
        //console.log("parsed:", userId, ":", status);
    }

    handleSubmit(i) {
        //console.log("submit ");
        this.props.history.push("/admision-applications");

    }

    render() {
        const { userId, status } = this.state;
        return (
            <>
            <Grid container spacing={3}>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction='row-reverse'>
                <BackButton {...this.props} />
                </Grid>
                {/* <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    &nbsp;
                </Grid> */}
                <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                {userId ? <EditableApplicantDetails onNext={this.handleSubmit} status={status} user={{ "id": userId }} />:""}
                </Grid>
            </Grid>
            </>
        )

    }
}

export default FillAdmissionDetails;