import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/dept-or-prgms";
const apiEndpoint = "/api/employees";


function create(data) {
  // const instituteId=authService.getInstituteTenant()
  // const data={...data,instituteId}
  return http.post(apiEndpoint, {...data,instituteType:authService.getInstituteType()});
}

function update(data) {
  // const instituteId=authService.getInstituteTenant()
  // const data={...data,instituteId}
  return http.put(`${apiEndpoint}/${data.id}`, {...data,instituteType:authService.getInstituteType()});
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

function getAll() {
  return http.get(apiEndpoint);
}

function getAllByRole(role) {
  return http.get(`${apiEndpoint}/role/${role}`);
}

function getAllByType(type) {
  return http.get(`${apiEndpoint}/type/${type}`);
}

function getAllByInstitute(instituteId) {
  return http.get(`${apiEndpoint}/list/${instituteId}`);
}

function getAllByQuery(query) {
  return http.get(`${apiEndpoint}/q`, { params: { ...query } });
}

function getAllByDepartment(departmentId,query) {
  return http.get(`${apiEndpoint}/department/${departmentId}`, { params: { ...query } });
}

function getAllCounsellors(){
  return http.get(`${apiEndpoint}/counsellors`);

}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByRole,
  getAllByType,
  getAllByInstitute,
  getAllByQuery,
  getAllByDepartment,
  getAllCounsellors,
  deleteById
};
