import React, { Component } from 'react';
import * as XMPP from 'stanza';
import authService from '../../services/authService';

class StanzaChat extends Component {
    constructor(props) {
        super(props);
        this.xmppUserId="poornima@localhost";
        this.xmppPwd="123";
        this.xmppResource="poornima"
        this.xmppBoshURL="http://localhost:7444/http-bind"
        this.state = {
            messages: [],
            inputMessage: '',
        };

        this.client = XMPP.createClient({
            jid: this.xmppUserId,
            password: this.xmppPwd,
            resource:this.xmppResource,
            chatMarkers:true,
            sendReceipts:true,
            transports: {
                // websocket: 'ws://localhost:5222/xmpp-websocket',
                bosh: this.xmppBoshURL,
            },
        });

        this.client.getUploadSlot("",{type:""})

        this.client.on('session:started', () => {
            this.client.getRoster();
            this.client.sendPresence();
            this.client.enableCarbons();
            this.client.sendMarker()
            this.client.discoverICEServers();
            // this.fetchChatHistory();
            this.client.on('message', (msg) => {
                // console.log("on chat:",msg);
                this.handleIncomingMessage(msg);
            });        

        });

        

        // this.fetchChatHistory();
    }

    componentDidMount() {
        this.client.connect();
        this.client.jingle.addICEServer()
        this.client.jingle.createFileTransferSession();

    }

    getSenderNameFromJID = (jid) => {
        const name = jid.split('@')[0];
        return name;
    };

    fetchChatHistory = async () => {
        try {
            const {results:history} = await  this.client.searchHistory("poornima@localhost",{with:"shree@localhost"});
    
            console.log("history:",history);

            const messages = history.map((item) => {
                const {message}=item.item;
                // console.log("Item:",message.body);
             return {  
                type: message.to===this.xmppUserId ? 'sent' : 'received',
                message: message.body}
            }
            );
            console.log("prep messages:",messages);

            this.setState({ messages:messages });
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };


    

    async  searchMessageHistory() {
        console.log('Reached first message');

       let before = '';
    let complete = false;
      if (complete) {
        console.log('Reached first message');
        return;
      }

      this.client.searchHistory("shree@chat.emagna.in", {
          with: "vaishu@chat.emagna.in",
          pagination: {before, max: 25},
        }).then(res => {
          console.log(`Found ${res.results.length} messages`, res);
          if (res.complete) {
            complete = true;
          } else {
            before = res.results[0].queryId;
          }
        //   searchHistory();
        })
        .catch(error => {
          console.error('Error searching for chat history:', error);
        });
    }




    handleIncomingMessage = (msg) => {
        const { messages } = this.state;
        const senderName = this.getSenderNameFromJID(msg.from);
        // messages.push({ type: 'received', text: `${senderName}: ${msg.body}` });
        const messageWithLinks = msg.body.replace(
            /((http|https):\/\/[^\s]+)/g,
            '<a href="$1" target="_blank">$1</a>'
        );
        messages.push({ type: 'received', text: messageWithLinks });

        this.setState({ messages });
    };

    handleInputChange = (event) => {
        this.setState({ inputMessage: event.target.value });
    };

    handleSendMessage = () => {
        const { inputMessage, messages } = this.state;
        const formattedMessage =  inputMessage;
        messages.push({ type: 'sent', text: formattedMessage });
        this.setState({ messages, inputMessage: '' });
        // Send message using XMPP
        if(authService.getCurrentRole()==="ADMISSION_MANAGER")
        this.client.sendMessage({
            to: 'vaishu@localhost/vaishu', // Replace with the appropriate recipient JID
            body: inputMessage,
            marker:{
                type:'markable',
                id:''
            }
        });
    };

    render() {
        const { messages, inputMessage } = this.state;
        return (
            <div>
                <div className="chat-window">
                    {messages && messages.map((msg, index) => (
                        <div
                            key={index}
                            className={`message ${msg.type === 'sent' ? 'sent' : 'received'}`}
                        >
                            {/* {  renderHTML(msg.text)} */}
                        </div>
                    ))}
                </div>
                <div className="input-area">
                    <input
                        type="text"
                        value={inputMessage}
                        onChange={this.handleInputChange}
                        placeholder="Type a message..."
                    />
                    <button onClick={this.handleSendMessage}>Send</button>
                </div>
            </div>
        );
    }
}

export default StanzaChat;
