import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import CommitteMeetingsTable from "./committeeMeetingsTable.page";
import FormDialog from "../../common/formDialog";
import committeeMeetingService from "./committeeMeeting.service";
import CommitteeMeetingFormPage from "./committeeMeetingForm.page";
import committeeService from "../../../services/committeeService";
import MatChips from "../../common/matChips";

class CommitteeMeetings extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "modifiedDate", order: "asc" },
    confirmDialogOpen: false,
    numOfStopsDilog:false,
    status:"All",
    pagination: { page: 0, rowsPerPage: 25, count: 0, onPageChange: this.handlePageChange, onRowsPerPageChange: this.handleRowsPerPageChange }

  };

  async componentDidMount() {
    await this.getCommitte();
    await this.getPagedData({...this.query()});
  }


  onConfirmOk = async (id) => {
    try {
      await committeeMeetingService.deleteById(id);
      await this.getPagedData({...this.query()});
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog=()=>{
    this.setState({dialogOpen:false,id:null});
    this.getPagedData(this.query());  
  }

  async getCommitte() {
    try{
      const { data: committee } = await committeeService.getByName(this.props.committee);
      this.setState({ committee });
    }catch(error){
      console.log("error:",error);
    }
  }



  getPagedData = async (query) => {
    try {
      query= {...query,search:this.state.status}
      const { data, headers } = await committeeMeetingService.getAllByQuery({...query, calendarId:-1, committeeId:this.state.committee.id});
      const totalElements = headers["x-total-elements"];
      const {pagination}={...this.state}
      pagination.count=totalElements
      this.setState({ data, pagination });
    } catch (ex) {
      logger.error(ex);
    }
  };


  handleAttendance=(item)=>{
    console.log("att:",item);
  }
  handleResolution=(item)=>{
    console.log("res:",item);
  }
  handleAgend=(item)=>{
    console.log("ag:",item);
  }
  handleUploadMoM=(item)=>{
    console.log("mom:",item);
  }

  filterByStatus= (search)=>{
     this.setState({status:search},()=>this.getPagedData(this.query()));
    // this.getPagedData({...this.query(),search})
  }
  getChipItem(label) {
    return <><span>{label}</span></>
  }
 
  render() {
    const { sortColumn, data, confirmDialogOpen,dialogOpen,id,pagination,status,totalElements  } = this.state;
    const { t, committee } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Grid container item sm={12} md={6} >
              <MatChips
                color="primary"
                items={[
                  { label: this.getChipItem("All"), value: "All" },
                  { label: this.getChipItem("Scheduled"), value: "Scheduled" },
                  { label: this.getChipItem("Completed"), value: "Completed" },
                ]}
                selected={status}
                onChipClick={this.filterByStatus}
              />
            </Grid>
            
            <Grid container item sm={12} md={6} direction="row-reverse">
              <Add item={{id:null}} onAdd={()=>{this.setState({dialogOpen:true})}} title={t("Schedule Meeting")} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CommitteMeetingsTable
              paginate={true}
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              onAttendanceClick={this.handleAttendance}
              onAgendaClick={this.handleAgend}
              onResolutionClick={this.handleResolution}
              onUploadMoMClick={this.handleUploadMoM}
              t={t}
              pagination={pagination}
              
            />
          </Grid>
        </Grid>
        {
          dialogOpen &&
          <FormDialog
            title={t("Meeting Details")}
            content={<CommitteeMeetingFormPage committee={committee} id={id?id:"new"} onSave={this.closeDialog}/>}
            open={dialogOpen}
            onClose={this.closeDialog}
          ></FormDialog>
        }
        {confirmDialogOpen && this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["committee", "common"])(CommitteeMeetings);
