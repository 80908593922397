import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import feeCategoryService from "../../../services/feeCategoryService";
import ButtonLink from "../../common/buttonLink";
import FormDialog from "../../common/formDialog";
import PagingAndSorting from "../../common/pagingAndSorting";
import FeeCategoryForm from "./feeCategoryForm";
import Delete from "../../common/delete";
import FeeItems from "./feeItems";
import logger from "../../../services/logger";
import Edit from "../../common/edit";
import Add from "../../common/add";
import BackButton from "../../common/backButton";

class FeeCategories extends PagingAndSorting {
  state = {
    institutes: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    dialogOpen: false,
    expanded: "",
  };

  getAll = async () => {
   await this.getAllByFeeStructureId();
    // try {
    //   const { data } = await feeCategoryService.getAll();
    //   if (data && data.length > 0)
    //     this.setState({ expanded: data[0]?.id })
    //   this.setState({ data });
    // } catch (ex) {
    //   logger.error(ex);
    // }
  };

  getAllByFeeStructureId = async () => {
    const id=this.props.match.params.id;
    try {
      const { data } = await feeCategoryService.getAllByFeeStructureId(id);
      if (data && data.length > 0)
        this.setState({ expanded: data[0]?.id })
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteById = async (id) => {
    try {
      await feeCategoryService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getAll();
  }

  closeDialog = () => {
    this.setState({ dialogOpen: false });
    this.getAll();
  };

  handleChange = (id) => {
    //console.log("panel id:", id);
    this.setState({ expanded: id })
  }

  onConfirmOk = async (id) => {
    try {
      //console.log("fee cat id:", id);
      await feeCategoryService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  accordionList(data) {
    const { t } = this.props;
    if (data && data.length > 0) {
      return (
        <div>
          <h3 style={{ textAlign: "center" }}>Admission Fee Structure</h3>
          {data && data.map((item, index) => (
            <Accordion key={item.id} 
            // expanded={this.state.expanded === item.id} 
            onChange={() => this.handleChange(item.id)}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id={"fee-category" + item.id}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography style={{flexBasis: '20%',flexShrink: 0,}} color="secondary">{index + 1}. {item.name}</Typography>
                <Typography> {item.remarks} </Typography>
                <div>
                  <Delete onDelete={this.handleDelete} item={item} iconOnly={true} />
                  <Edit onEdit={this.handleEdit} item={item} iconOnly={true} />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <FeeItems categoryId={item.id} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      );
    } else {
      return <>
        <Grid>
          <Card>
            <CardContent style={{ textAlign: "center" }}>
              <h2 > Fee Structure is not created yet. Please add category and start creating the structure. </h2>
              <Add item={{ id: "new" }} title={t("Add New Fee Category")} onAdd={this.handleAdd} type="fab" />
            </CardContent>
          </Card>
        </Grid>
      </>
    }
  }

  render() {
    const { sortColumn, data, dialogOpen, id } = this.state;
    const { t } = this.props;
    const feeStructureId=this.props.match.params.id;

    return (
      <React.Fragment>
        <Grid container>
          <Grid container direction="row-reverse">
          <BackButton {...this.props} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
            <Add item={{ id: "new" }} title={t("New Fee Category")} onAdd={this.handleAdd} type="fab" />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            {this.accordionList(data)}
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}

        {dialogOpen && (
          <FormDialog
            id="category-dialog"
            keepMounted
            title={"Category Details"}
            content={
              <FeeCategoryForm
                id={id ? id : "new"}
                feeStructureId={feeStructureId}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["feeCategory"])(FeeCategories);
