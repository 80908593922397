import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/dept-or-prgms";
const apiEndpoint = "/api/tickets";


function create(data) {
  const user=authService.getCurrentUser();
  const userId=user.id;
  const userName=user.email;
  const req={...data,userId,userName}
  return http.post(apiEndpoint, req);
}

function update(data) {
  const req={...data}
  return http.put(`${apiEndpoint}/emagna/${data.id}`, req);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

function getAll() {
  return http.get(apiEndpoint);
}

function getAllByInstitute(instituteId) {
  return http.get(`${apiEndpoint}/list/${instituteId}`);
}

function getAllByUser(userId){
  return http.get(`${apiEndpoint}/user/${userId}`);

}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByInstitute,
  getAllByUser,
  deleteById
};
