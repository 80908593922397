import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import enquiryDocsUploadService from "../../../services/enquiryDocsUploadService";
import logger from "../../../services/logger";
import FormDialog from "../../common/formDialog";
import PagingAndSorting from "../../common/pagingAndSorting";
import UploadDocForm from "./uploadDocForm";
import UploadDocsTable from "./uploadDocsTable";

class UploadDocs extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  getAll = async () => {
    try {
      this.setState({ data: [] });
      const { data } = await enquiryDocsUploadService.getAllByUser();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await enquiryDocsUploadService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog = () => {
    // dialogOpen=false;
    this.setState({ dialogOpen: false });
    this.getAll();
  };

  hadnleUploadDocs = (item) => {
    this.setState({ docs: item, dialogOpen: true });
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const { sortColumn, data, id, dialogOpen } = this.state;
    const { t, user } = this.props;
    return (
      <React.Fragment>
        <Grid container>
         

          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <UploadDocsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              // onEdit={() => this.handleEdit({ id: "new" })}
              onUpload={(item) => this.hadnleUploadDocs(item)}
            />
            {/* </UploadDocsTable> */}
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}

        {dialogOpen && (
          <FormDialog
            id="upload-docs-dialog"
            keepMounted
            title={"Upload new document"}
            content={
              <UploadDocForm
                userId={user.id}
                id={"new"}
                doc={this.state.docs}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["uploadDocs"])(UploadDocs);
