import { Card } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import feeStructureService from "../../../services/feeStructureService";
import OrgContext from "../../app-context";

class FeeStructureForm extends Form {
  constructor() {
    super();
   
    this.formJson = getDynamicFormJson("feeStructure");

    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }





  async populateForm() {
    const id = this.props.id;
    if (id === "new") return;
    try {
      
      const { data } = await feeStructureService.get(id);

      this.mapToViewModel(data);
    } catch (ex) { }
  }

  async componentDidMount() {

    await this.populateForm();
  }

  doSubmit = async (id) => {
    try {
    
      if (id === "new") await feeStructureService.create(this.state.data);
      else await feeStructureService.update(this.state.data);
      this.props.onSave();
    } catch (ex) {
      // logger.log(ex.response.data.message);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      <Card>
          {/* <CardHeader title={t("Fee Structure")} action={<BackButton {...this.props} />} /> */}
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

        </form>
      </Card>
    );
  }
}
FeeStructureForm.contextType = OrgContext;
export default withTranslation(["feeStructure", "common"])(FeeStructureForm);
