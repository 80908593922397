import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class MatCKEditor extends Component {
    render() {
        const {value,onEditorChange,name}=this.props;
        return (
            <div className="App">
                {/* <h2>Using CKEditor 5 build in React</h2> */}
                <CKEditor
                    editor={ ClassicEditor }
                    data={value?value:{}}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        //console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={( event, editor ) => {
                        //console.log( 'Blur.', editor );
                        onEditorChange(name,editor.getData())
                    }}
                   
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />
            </div>
        );
    }
}

export default MatCKEditor;
