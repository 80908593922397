import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ButtonLink from "../../common/buttonLink";
import Delete from "../../common/delete";
import MatTable from "../../common/matTable";

const UploadDocsTable = ({
  data,
  sortColumn,
  onSort,
  onEdit,
  onUpload,
  onDelete,
}) => {
  const { t } = useTranslation(["uploadDocs"]);
  const columns = [
    { path: "id", label: t("Id") },
    {
      path: "documentName",
      label: t("Document Name"),
      content: (doc) => {
        return <Link to={`upload-docs/${doc.id}`}> {doc.documentName} </Link>;
      },
    },
    {
      path: "document",
      label: t("Document"),
      content: (doc) => {
        if (doc.documentContentType === "application/pdf")
          return <span>PDF</span>;
        else
          return (
            <img
              width="50"
              height="50"
              src={
                "data:" + doc.documentContentType + ";base64," + doc.document
              }
            />
          );
      },
    },

    // {
    //   key: "action",
    //   content: (item) => {
    //     return <Edit link={`upload-docs/${item.id}`}></Edit>;
    //   },
    // },
    {
      key: "delete",
      content: (doc) => {
        return doc.id == null ? (
          // <Button variant="contained" color="secondary" onClick={onEdit}>
          //   {t("Upload")}
          // </Button>
          <ButtonLink
            title="Upload"
            item={doc}
            onButtonClick={() => onUpload(doc)}
          />
        ) : (
          <Delete onDelete={onDelete} item={doc} />
        );
      },
    },
  ];

  return (
    <MatTable
      data={data}
      heading="List of Documents"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default UploadDocsTable;
