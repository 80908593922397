import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/dept-or-prgms";
const apiEndpoint = "/api/departments";


function create(data) {
  // const instituteId=authService.getInstituteTenant()
  // const data={...data,instituteId}
  return http.post(apiEndpoint, data);
}

function update(data) {
  // const instituteId=authService.getInstituteTenant()
  // const data={...data,instituteId}
  return http.put(`${apiEndpoint}/${data.id}`, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

function getAll() {
  return http.get(apiEndpoint);
}

function getAllByType(type) {
  return http.get(`${apiEndpoint}/type/${type}`);
}

function getAllByInstitute(instituteId) {
  return http.get(`${apiEndpoint}/list/${instituteId}`);
}


function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByType,
  getAllByInstitute,
  deleteById
};
