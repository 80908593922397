import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";
import Edit from "../../common/edit";

const AdmissionFeePaymentTable = ({
  data,
  sortColumn,
  onSort,
  onMoreClick,
}) => {
  const { t } = useTranslation(["admissionFeeConfigs","common"]);
  //console.log("feedata:",data);
  const columns = [

    {
      path: "programName",
      label: t("common:Program"),
    },
   
    { path: "totalFeeAmount", label: t("Fee Amount") },
    { path: "totalPaidAmount", label: t("Paid Amount") },
    {path:"remainingAmount",label:t("Balance")},

    {
      key: "action",
      content: (item) => {
        return <Edit icon="visibility" onEdit={onMoreClick} variant="outlined" iconOnly="true" title="View Fee Items" />;
      },
    },
    
  ];

  return (
    <MatTable
      data={data}
      heading="Admission Fee Details"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default AdmissionFeePaymentTable;
