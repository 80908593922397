import { HEDGE_BROWN_SERVER } from "../data/endpointConstants";
import http from "./httpService";

const masterApi =HEDGE_BROWN_SERVER+"/manage-enquiries";
const apiEndpoint = "enquiry-followups";

function create(enquiryId, data) {
  return http.post(`${masterApi}/${enquiryId}/${apiEndpoint}`, data);
}

function update(enquiryId, data) {
  return http.put(`${masterApi}/${enquiryId}/${apiEndpoint}`, data);
}

function get(enquiryId, id) {
  return http.get(`${masterApi}/${enquiryId}/${apiEndpoint}/${id}`);
}
function getAll(enquiryId) {
  return http.get(`${masterApi}/${enquiryId}/${apiEndpoint}`);
}

function getStats() {
  return http.get(`${masterApi}/${apiEndpoint}/stats`);
}

function getAllByQuery(enquiryId, query) {
  return http.get(`${masterApi}/${enquiryId}/${apiEndpoint}`, {
    params: { ...query },
  });
}

function deleteById(enquiryId, id) {
  return http.delete(`${masterApi}/${enquiryId}/${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getStats,
  getAllByQuery,
  deleteById,
};
