import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/dept-or-prgms";
const apiEndpoint = "/api/notifications";


function create(data) {
   return http.post(apiEndpoint, data);
}

function update(data) {
  
  return http.put(apiEndpoint, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

function getByName(name) {
  return http.get(`${apiEndpoint}/name/${name}`);
}

function getAll() {
  const userId=authService.getCurrentUser().id;
  return http.get(`${apiEndpoint}/${userId}`);
}

function getAllByInstitute(instituteId) {
  return http.get(`${apiEndpoint}/list/${instituteId}`);
}


function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getByName,
  getAll,
  getAllByInstitute,
  deleteById
};
