import React, { Component } from "react";
import DeleteConfirmDialog from "./deleteConfirmDialog";

class DialogBox extends Component {
  state = {};

  handleCancel = () => {
    this.setState({ confirmDialogOpen: false });
  };

  handleOk = () => {
    this.onConfirmOk(this.state.id);
    this.setState({ confirmDialogOpen: false });
  };

  renderDeleteConfirmDialog(title, body) {
    return (
      <DeleteConfirmDialog
        id="confirm-dialog"
        keepMounted
        dialogTitle={title}
        dialogContent={body}
        open={this.state.confirmDialogOpen}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      />
    );
  }
}
export default DialogBox;
