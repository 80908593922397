import httpService from "./httpService";

const { HEDGE_BROWN_SERVER } = require("../data/endpointConstants");

const masterApi = HEDGE_BROWN_SERVER;

function upload(file, url, onUploadProgress) {
  let formData = new FormData();

  formData.append("file", file);

  return httpService.post(HEDGE_BROWN_SERVER + url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

function uploadWithFormData(formData, url, onUploadProgress) {
  // let formData = new FormData();

  // formData.append("file", file);

  return httpService.post(HEDGE_BROWN_SERVER + url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

export default {
  upload,
  uploadWithFormData,
};
