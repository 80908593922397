import addmissionActivity from "../../data/forms/addmissionActivity.json";
import addmissionApplictionFee from "../../data/forms/addmissionApplictionFeeConfig.json";
import addmissionApplictionForm from "../../data/forms/addmissionApplictionForm";
import addmissionFeeConfig from "../../data/forms/addmissionFeeConfig.json";
import addmissionPartner from "../../data/forms/addmissionPartner.json";
import address from "../../data/forms/address.json";
import admissionCalendar from "../../data/forms/admissionCalendar.json";
import applicantFollowup from "../../data/forms/applicantFollowup.json";
import boardOrUniversity from "../../data/forms/boardOrUniversity.json";
import degreeOrEquivalent from "../../data/forms/degreeOrEquivalent.json";
import education from "../../data/forms/education.json";
import enquiryDocumentConfig from "../../data/forms/enquiryDocumentConfig.json";
import enquiryFollowup from "../../data/forms/enquiryFollowup.json";
import enquiryTarget from "../../data/forms/enquiryTarget.json";
import group from "../../data/forms/group.json";
import institute from "../../data/forms/institute.json";
import instituteBasic from "../../data/forms/instituteBasic.json";
import preAdmissionCalendar from "../../data/forms/preAdmissionCalendar.json";
import role from "../../data/forms/role.json";
import specialization from "../../data/forms/specialization.json";
import studentPersonalInfo from "../../data/forms/studentPersonalInfo.json";
import termsAndConditions from "../../data/forms/termsAndConditions.json";
import uploadDocs from "../../data/forms/uploadDocs.json";
import uploadEnquiries from "../../data/forms/uploadEnquiriesForm.json";
import userManagement from "../../data/forms/userManagement.json";
import enquiryBasicInfo from "../../data/forms/enquiryBasicInfo.json"
import applicationFormFee from "../../data/forms/applicationFormFee.json";
import enquiryForm from "../../data/forms/enquiryForm.json";
import applicationPreview from "../../data/forms/applicationPreview.json"
import program from "../../data/forms/program.json"
import paymentAndReceipt from "../../data/forms/paymentAndReceipt.json"
import applicantBasicDetailsForm from "../../data/forms/applicantBasicDetailsForm.json"
import applicantEducationDetailsForm from "../../data/forms/applicantEducationDetailsForm.json"
import feeCategory from "../../data/forms/feeCategory.json";
import feeItem from "../../data/forms/feeItem.json"
import admissionFeeTemplateService from "../../services/admissionFeeTemplateService";
import committee from "../../data/forms/committee.json"
import department from "../../data/forms/department.json"
import employee from "../../data/forms/employee.json"
import student from "../../data/forms/student.json"
import employeeNonTeaching from "../../data/forms/employeeNonTeaching.json"
import uploadEmployees from "../../data/forms/uploadEmployees.json"
import committeeMember from "../../data/forms/committeeMember.json"
import externalCommitteeMember from "../../data/forms/externalCommitteeMember.json"
import userTicket from "../../data/forms/userTicket.json"
import resolveTicket from "../../data/forms/resolveTicket.json"
import admissionFeePaymentForm from "../../data/forms/admissionFeePaymentForm.json"
import feeStructure from "../../data/forms/feeStructure.json"
import academicCalendar from "../../data/forms/academics/academicCalendar.json"
import eventsCalendar from "../../data/forms/eventsCalendar.json"
import holidayCalendar from "../../data/forms/holidayCalendar.json"
import academicCourse from "../../data/forms/academics/academicCourse.json"



export function getDynamicFormJson(formName) {
  switch (formName) {
    case "boardOrUniversity":
      return boardOrUniversity;
    case "group":
      return group;
    case "institute":
      return institute;
    case "instituteBasic":
      return instituteBasic;
    case "degreeOrEquivalent":
      return degreeOrEquivalent;
    case "specialization":
      return specialization;
    case "program":
      return program;
    case "preAdmissionCalendar":
      return preAdmissionCalendar;
    case "admissionCalendar":
      return admissionCalendar;
    case "addmissionFeeConfig":
      return addmissionFeeConfig;
    case "feeCategory":
      return feeCategory;
    case "feeItem":
      return feeItem;
    case "studentPersonalInfo":
      return studentPersonalInfo;
    case "enquiryBasicInfo":
      return enquiryBasicInfo;
    case "paymentAndReceipt":
      return paymentAndReceipt;
    case "applicantBasicDetailsForm":
      return applicantBasicDetailsForm;
    case "applicantEducationDetailsForm":
      return applicantEducationDetailsForm;
    case "applicationFormFee":
      return applicationFormFee;
    case "addmissionApplictionFee":
      return addmissionApplictionFee;
    case "addmissionPartner":
      return addmissionPartner;
    case "addmissionActivity":
      return addmissionActivity;
    case "address":
      return address;
    case "education":
      return education;
    case "applicationPreview":
      return applicationPreview;
    case "addmissionApplictionForm":
      return addmissionApplictionForm;
    case "userManagement":
      return userManagement;
    case "role":
      return role;
    case "enquiryForm":
      return enquiryForm;
    case "enquiryTarget":
      return enquiryTarget;
    case "enquiryFollowup":
      return enquiryFollowup;
    case "applicantFollowup":
      return applicantFollowup;
    case "termsAndConditions":
      return termsAndConditions;
    case "uploadEnquiries":
      return uploadEnquiries;
    case "uploadDocs":
      return uploadDocs;
    case "enquiryDocumentConfig":
      return enquiryDocumentConfig;
    case "committee":
      return committee;
    case "committeeMember":
      return committeeMember;
    case "externalCommitteeMember":
      return externalCommitteeMember;
    case "department":
      return department;
    case "employee":
      return employee;
    case "employeeNonTeaching":
      return employeeNonTeaching;
    case "uploadEmployees":
      return uploadEmployees;
    case "student":
      return student;
    case "userTicket":
      return userTicket;
    case "resolveTicket":
      return resolveTicket;
    case "admissionFeePaymentForm":
      return admissionFeePaymentForm;
    case "feeStructure":
      return feeStructure;
    case "feeDetail":
      return admissionFeeTemplateService.getTemplate();
    case "academicCalendar":
      return academicCalendar;
    case "eventsCalendar":
      return eventsCalendar;
    case "holidayCalendar":
      return holidayCalendar;
    case "academicCourse":
      return academicCourse;

  }
}


