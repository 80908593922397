import {
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,

} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import classNames from "classnames";
import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { getNavItems } from "../../../services/navService";
import { Menu } from "@mui/icons-material";
// import { ExpandLess, ExpandMore } from "@mui/icons-material";

class NavBar extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.nav = getNavItems();
  }

  push = (subapp) => {
    window.history.pushState(null, subapp, subapp);
  };

  handleClick = (e) => {
    this.setState({ [e]: !this.state[e] });
    const {selected}=this.state;
    if(selected===e)
    this.setState({selected:null})
    else 
    this.setState({selected:e})

  };

  componentDidMount() {
    const { org } = this.props;
    org.then(orgData => {
      this.setState({ org: orgData })
    })
  }

  drawer(onDrawerClick) {
    const { classes, open,t } = this.props;
    const { org } = this.state;

    return <>
      <div className={classes.toolbarIcon}>
       {open && 
      <a href="https://www.syniza.in" target="_blank">

        <img src="../../../logo.jpeg" style={{ height: "100px",textAlign:"left" }}  />
      </a> 
       }
        {/* <h2 style={{
          textAlign: "left",
          width: "100%",
          marginLeft: "12px"
        }}>
          AccrEdu
        </h2> */}
       {open? <IconButton onClick={onDrawerClick} size="large">
            <ChevronLeftIcon  />
          </IconButton>:
          <IconButton onClick={onDrawerClick} size="large" >
          <Menu />
        </IconButton>
        }
      </div>
      <Divider style={{color:"black",border:"0.5px solid black"}} />

      {this.nav.groups.map((navGroup) => {
        return (
          <div>
            <div id="org-logo" style={{ paddingTop: "5%",height:"90px", borderBottom: "1px solid #7e7c7c" }}>
             {org  && <img alt={org?.nickName} src={"data:" + org?.logoContentType + ";base64," + org?.logo} style={{
                margin: "auto",
                height: "90px",
                width: "100%",
              }}
              />}
            </div>
            <div >
              <List
                key={navGroup.id}
              >
                {navGroup.items.map((item) => {
                  return (
                    <div key={item.id}>
                      {item.subitems != null ? (
                        <div key={item.id}>
                          <ListItemButton
                            button
                            key={item.id}
                            // onClick={() => this.handleClick(item.name)}
                            onClick={() => this.handleClick(item.id)}
                            className={item.id===this.state.selected && classes.navMainActive}
                          >
                            <Tooltip title={t(item.name)} placement="top">
                              <ListItemIcon className={classes.listItemIcon}>
                                <Icon>{item.icon}</Icon>
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={t(item.name)} />
                            {/* {this.state[item.name]  ? ( */}

                            {item.id===this.state.selected  ? (
                              <Icon>{'expand_less'}</Icon>
                              // <ExpandLess />
                            ) : (
                              <Icon>{'expand_more'}</Icon>

                              // <ExpandMore />
                            )}
                          </ListItemButton>

                          <Collapse
                            key={navGroup.items.id}
                            component="li"
                            // in={this.state[item.name]}
                            in={item.id===this.state.selected}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List disablePadding>
                              {item.subitems.map((sitem) => {
                                return (
                                  <ListItemButton
                                    button
                                    key={item.id + "-" + sitem.id}
                                    className={classNames(classes.nested)}
                                    component={NavLink}
                                    to={sitem.url}
                                    activeClassName={classes.navActive}

                                  >
                                    <ListItemIcon
                                      className={classes.listItemIcon}
                                    >
                                      <Icon fontSize="10px">{"circle"}</Icon>
                                    </ListItemIcon>
                                    <Hidden smDown implementation="css">

                                      <ListItemText
                                        key={item.id + "-" + sitem.id}
                                        primary={t(sitem.name)}

                                      />
                                    </Hidden>
                                    <Hidden smUp implementation="css">

                                      <ListItemText
                                        key={item.id + "-" + sitem.id}
                                        primary={t(sitem.name)}
                                        onClick={onDrawerClick}
                                      />
                                    </Hidden>
                                  </ListItemButton>
                                );
                              })}
                            </List>
                          </Collapse>
                        </div>
                      ) : (
                        <>
                          <Divider key={"divide" + item.id} absolute />
                          <ListItemButton
                            button
                            key={item.id}
                            component={NavLink}
                            to={item.url}
                            activeClassName={classes.navActive}
                          >
                            <ListItemIcon
                              className={classes.listItemIcon}
                              title={t(item.name)}
                            >
                              <Icon>{item.icon}</Icon>
                            </ListItemIcon>
                            {/* <Hidden smDown implementation="css"> */}
                            {/* <Hidden only={["xs", "sm"]}> */}

                             {/* {open ? <ListItemText primary={t(item.name)} sx={{ display: {  xs: 'none',md: 'block' } }} />:<ListItemText primary={" "} /> } */}
                             <ListItemText style={{textWrap:"nowrap"}} primary={t(item.name)} sx={{ display: {  xs: 'none',md: 'block' } }} />
                            {/* </Hidden> */}
                            {/* <Hidden smUp implementation="css"> */}
                            {/* <Hidden only={["md", "lg", "xl"]}> */}

                              <ListItemText primary={t(item.name)} onClick={onDrawerClick} sx={{ display: {  md: 'none',xs: 'block' } }}  />
                            {/* </Hidden> */}
                          </ListItemButton>
                        </>
                      )}
                    </div>
                  );
                })}
                <Divider key={navGroup.id} absolute />
              </List>
            </div> </div>
        );
      })}
    </>;

  }

  render() {
    const { classes, mobileOpen, open, onDrawerClick, onMobileDrawerClick } = this.props;
    //console.log("open:",open);
    const { org } = this.state;
    return <><Hidden mdDown implementation="css">
      <Drawer
        id="drawer-left-temporary"
        variant="permanent"
        classes={{
          paper: classNames(
            open ? classes.drawerPaper : classes.drawerPaperClose
          ),
        }}
        open={open}
      >
        {this.drawer(onDrawerClick)}
      </Drawer>
    </Hidden>
      <Hidden mdUp implementation="css">
        <Drawer
          id="drawer-left"
          variant="temporary"
          classes={{
            paper:
              classes.drawerPaper

          }}
          open={mobileOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            onBackdropClick: onMobileDrawerClick
          }}
        >
          {this.drawer(onMobileDrawerClick)}
        </Drawer>
      </Hidden></>;
  }
}

export default withTranslation(["navBar"])(NavBar);
