import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { getEnquiryTypes, getFollowupTypes } from "../../../data/enums";
import applicantFollowupService from "../../../services/applicantFollowupService";
import enquiryFollowupService from "../../../services/enquiryFollowupService";
import logger from "../../../services/logger";
import BackButton from "../../common/backButton";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class ApplicantFollowupForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("applicantFollowup");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getEnquiryTypes = async () => {
    //console.log("types...");
    try {
      const dropDowns = this.state.dropDowns;
      dropDowns.enquiryTypes = getEnquiryTypes();
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getFollowupTypes = async () => {
    //console.log("follow-up types...");
    try {
      const dropDowns = this.state.dropDowns;
      dropDowns.followupTypes = getFollowupTypes();
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };

  doSubmit = async (id) => {
    // const applicantId =parseInt(this.props.applicantId);
    const applicantId = parseInt(this.props.applicantId?this.props.applicantId:this.props.match.params.applicantId);
    try {
      if (id === "new") {
        await enquiryFollowupService.create(applicantId, {
          ...this.state.data,
          enquiryId: applicantId,
        });
      } else {
        await applicantFollowupService.update(applicantId, {
          ...this.state.data,
          applicantId: applicantId,
        });
      }
      // this.props.history.goBack();
      this.props.onSave();
    } catch (ex) {
      // logger.log(ex);
    }
  };

  async populateForm() {
    const id = this.props.id?this.props.id:this.props.match.params.id;
    const applicantId = this.props.id?this.props.id:this.props.match.params.applicantId;
    if (id === "new") return;
    try {
      const { data } = await enquiryFollowupService.get(applicantId, id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    await this.populateForm();
    await this.getEnquiryTypes();
    await this.getFollowupTypes();
  }

  render() {
    const { t} = this.props;
    const { dropDowns } = this.state;
    const id = this.props.id?this.props.id:this.props.id?this.props.id:this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = this.formJson.alias;

    return (
      <Card>
          {/* <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} /> */}
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["followup", "common"])(ApplicantFollowupForm);
