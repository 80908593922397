import React, { useState } from 'react';
import ButtonLink from '../../common/buttonLink';
import InfoDialog from '../../common/infoDialog';

import { Comment } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import TermsAndConditionsView from '../../configurations/terms-and-conditions/termsAndConditionView';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import { id } from 'date-fns/locale';
const NewsEventsButtons = () => {
const {t}=useTranslation("newsEvents")
const [event,setEvent]=useState(false);
const [news,setNews]=useState(false);


function renderNews(){
  setNews(!news)
 
  
}

function renderEvents(){
  setEvent(!event)
 
  
}

function  newsList(){
  const newses=[{
    id:1,
    heading:"Training on AI/ML at Syniza",
    details:"Training on AI/ML at Syniza by Prof. Shreekanth from IISc Bangalore"
  },
  {
    id:1,
    heading:"Training on AI/ML at Syniza",
    details:"Training on AI/ML at Syniza by Prof. Shreekanth from IISc Bangalore"
  },
  {
    id:1,
    heading:"Training on AI/ML at Syniza",
    details:"Training on AI/ML at Syniza by Prof. Shreekanth from IISc Bangalore"
  }
  ,{
    id:1,
    heading:"Training on AI/ML at Syniza",
    details:"Training on AI/ML at Syniza by Prof. Shreekanth from IISc Bangalore"
  }
]
  console.log("newsList:");
 return <>
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
     {newses.map(item=> <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.heading} secondary={item.details} />
      </ListItem>)}
      
      
    </List>
  </>
}

function  eventList(){
  const newses=[{
    id:1,
    heading:"Training on AI/ML at Syniza",
    details:"Training on AI/ML at Syniza by Prof. Shreekanth from IISc Bangalore"
  },
  {
    id:1,
    heading:"Training on AI/ML at Syniza",
    details:"Training on AI/ML at Syniza by Prof. Shreekanth from IISc Bangalore"
  },
  {
    id:1,
    heading:"Training on AI/ML at Syniza",
    details:"Training on AI/ML at Syniza by Prof. Shreekanth from IISc Bangalore"
  }
  ,{
    id:1,
    heading:"Training on AI/ML at Syniza",
    details:"Training on AI/ML at Syniza by Prof. Shreekanth from IISc Bangalore"
  }
]
  console.log("newsList:");
 return <>
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
     {newses.map(item=> <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.heading} secondary={item.details} />
      </ListItem>)}
      
      
    </List>
  </>
}


  return (<>
    <div style={{ position: "fixed", right: 0, background: "gray", height: "100%" }}>
      <ButtonLink onButtonClick={renderNews} icon={"expand_more"} color='error' size="large" title={"News"} style={{width:"10%", position: "fixed", top: "25%", transformOrigin: "bottom", transform: "rotate(90deg)", right: "-54px" }} />
      <ButtonLink onButtonClick={renderEvents} icon={"expand_more"} color='success' size="large" title={"Events"} style={{width:"10%", position: "fixed", bottom: "25%", transformOrigin: "bottom", transform: "rotate(90deg)", right: "-54px" }} />
      <IconButton size='large' color='primary' style={{ position: "fixed", bottom: "5%", right: "-6px" }} >
        <Comment style={{ fontSize: "2.6rem" }} />
      </IconButton>
    </div>
    {
      event==true && <InfoDialog
      id="info-dialog"
      keepMounted
      title={t("Events")}
      content={eventList()}
      open={event}
      onClose={() => setEvent(!event)}
      onOk={() => setEvent(!event)}
    />
    }

    {
      news==true && <InfoDialog
      id="info-dialog"
      keepMounted
      title={t("News")}
      content={newsList()}
      open={news}
      onClose={() => setNews(!news)}
      onOk={() => setNews(!news)}
    />
    }
  </>);
}

export default NewsEventsButtons;