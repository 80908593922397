import { Icon, Typography } from '@mui/material';
import React, { Component } from 'react';

class DateAndTemperature extends Component {
    state = {  } 
    componentDidMount(){
        this.renderWeatherForeCast();
        this.renderDateAndTime();
        var intervalId=setInterval(()=>this.renderDateAndTime(),1000);
        this.setState({intervalId});
    }
    renderDateAndTime(){
        const dateTime=new Date().toLocaleString("en-IN","dd-MMM-yyyy hh:mm");
        this.setState({dateTime});
      }
    
     async renderWeatherForeCast(){
      navigator.geolocation.getCurrentPosition((position)=>this.getPosition(position))
     }
    
      getPosition(position){
        // console.log("position:",position);
        const lat=position.coords.latitude;
        const long=position.coords.longitude;
        const api="https://api.openweathermap.org/data/2.5/weather?units=metric&lat="+lat+"&lon="+long+"&appid=8c33cbabe5562cd9c17c31ea6989f533"
    
        fetch(api,{method:"GET"}).then((response) => response.json())
        .then((data) => {
            // console.log("temperature:",data);
            // const celsius=parseInt((data.main.temp-32)*5/9);
          this.setState({temperature:data.main.temp})

        })
      }
    render() { 
        const {temperature,dateTime}=this.state;
        return (<>
            {temperature &&  <>
              <Typography>
                <Icon>cloud</Icon>&nbsp;
              </Typography>
              <Typography>
                {temperature}&deg;C &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              </> 
              }
              <Typography>
                {dateTime}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
        </>);
    }
}
 
export default DateAndTemperature;