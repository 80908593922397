import { Chip } from "@mui/material";
import React from "react";
import MatChip from "./matChip";

const MatChips = ({ items, selected, onChipClick,color }) => {
  //console.log("selected:",selected);
  return items.map((item) => (
    <div>
      <MatChip
        icon={item.icon}
        label={item.label}
        value={item.value}
        color={selected === item.value ? "primary" : "primary"}
        variant={selected === item.value ? "standard" : "outlined"}

        onClick={onChipClick}
        // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
        // className={classes.chip}
      />
      &nbsp;
    </div>
  ));
};

export default MatChips;
