import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import roleService from "../../../services/roleService";
import userService from "../../../services/userService";
import BackButton from "../../common/backButton";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class UserManagementForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("userManagement");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getRoles = async () => {
    try {
      const { data } = await roleService.getAll();
      const dropDowns = this.state.dropDowns;
      dropDowns.roles = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("roles ", ex);
    }
  };

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await userService.get(id);
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    await this.getRoles();
    await this.populateForm();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await userService.create({...this.state.data,login:this.state.data.email});
      } else {
        await userService.update(this.state.data);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = this.formJson.alias;

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} />

          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["userManagement", "common"])(
  UserManagementForm
);
