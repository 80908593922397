import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ButtonLink from "../../common/buttonLink";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const FeeDetailTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["feeDetail","common"]);
  const columns = [
   
    // {
    //   path: "label",
    //   label: t("Fee Detail Name")
    // },
    {
      path: "programName",
      label: t("common:Program")
    },
    {
      path: "totalFeeAmount",
      label: t("Total Fee Amount"),
      numeric:true
    },
    {
      key: "action",
      content: (item) => {
        return <> 
          <ButtonLink icon={"visibility"} title={"View Details"} link={`admission-fee-details-view/${item.id}/${item.programName}/${item.feeStructureId}`} /> &nbsp;
          <Edit link={`admission-fee-details/${item.id}/${item.feeStructureId}`}/> &nbsp;
          <Delete onDelete={onDelete} item={item} />
          
          </>
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading="List of Fee Details"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default FeeDetailTable;
