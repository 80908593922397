import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import admissionFeeDetailService from "../../../services/admissionFeeDetailService";
import admissionFeeTemplateService from "../../../services/admissionFeeTemplateService";
import logger from "../../../services/logger";
import programService from "../../../services/programService";
import BackButton from "../../common/backButton";
import Form from "../../common/form";
import {
  getStateObject,
} from "../../common/formUtils";

class FeeDetailView extends Form {
  state={
    formJson:{}
  }
  constructor() {
    super();
    // this.getTemplate();
    // this.getTemplateByStructureId()
  }

  async getTemplate() {
    const { data} = await admissionFeeTemplateService.getTemplate();
    //console.log("json:",data);
    this.setState({
      formJson:data,
      data:getStateObject(data.groups),
      errors: {},
      dropDowns: {}
    })
   
    // this.schema = getJoiSchema(data.groups);
  }

  async getTemplateByStructureId() {
    const feeStructureId=this.props.match.params.feeStructureId;
    const { data} = await admissionFeeTemplateService.getTemplateByStructureId(feeStructureId);
    //console.log("json:",data);
    this.setState({
      formJson:data,
      data:getStateObject(data.groups),
      errors: {},
      dropDowns: {}
    })
   
    // this.schema = getJoiSchema(data.groups);
  }



  onSelectOf = async (item, value) => {
    
    switch (item) {
      case "instituteId":
        await this.getPrograms(value);
        break;
        default:
        break;
    }
  };

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data:feeData } = await admissionFeeDetailService.get(id);
      
      //console.log("data:",feeData);
      this.mapToViewModel({...feeData.data,label:feeData.label,id:feeData.id,programId:feeData.programId},this.state.formJson);
    } catch (ex) {
      logger.error(ex);
    }
  }

  getPrograms = async () => {
    try {
      const { data: programs } = await programService.getAll();
      const dropDowns  =this.state;
      dropDowns.programs=programs
      this.setState({ dropDowns});
    } catch (ex) {
      logger.error(ex);
    }
  };

  async componentDidMount() {
    await this.getPrograms();
    await this.getTemplateByStructureId();
    await this.populateForm();

  }

  doSubmit = async (id) => {
    try {
      const feeData ={...this.state.data};
      let req={label:feeData.label,programId:feeData.programId};
      delete feeData.label;
      delete feeData.programId;
      req={...req,data:feeData};
      // console.log("request :",req);
      if (id === "new") {
        await admissionFeeDetailService.create(req);
      } else {
        await admissionFeeDetailService.update({...req,id:id});
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    // const { t } = this.props;
    const { formJson } = this.state;
    // const id = this.props.match.params.id;
    const program=this.props.match.params.program;

    // const buttonText = getFormButtonText(id);
    // const titleText = getFormTitleText(id, formJson.alias);
    return (
      <Card>
        <CardHeader title={"Fee Details for "+program} action={<BackButton {...this.props} />} />
        {this.renderView(formJson)}
      </Card>
    );
  }
}

export default withTranslation(["feeDetails", "common"])(
  FeeDetailView
);
