import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/enquiry-document-configs";
const apiEndpoint = "/api/admission-fee-structures";


function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data) {
  return http.put(apiEndpoint, data);
}
 function getTemplate() {
 return http.get(apiEndpoint);
 
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

function getTemplateByStructureId(id) {
  return http.get(`${apiEndpoint}/structure/${id}`);
}

function getAll() {
  return http.get(apiEndpoint);
}

function getAllList() {
  return http.get(apiEndpoint + "/list");
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  getTemplate,
  get,
  getAll,
  getAllList,
  getTemplateByStructureId,
  deleteById,
};
