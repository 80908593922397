import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import boardOrUniversityService from "../../services/boardOrUniversityService";
import logger from "../../services/logger";
import { getDynamicFormJson } from "../common/dynamicFormService";
import Form from "../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";

class BoardOrUniversityForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("boardOrUniversity");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }
  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await boardOrUniversityService.getBoardOrUniversity(id);
      this.setState({ data });
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    await this.populateForm();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new")
        await boardOrUniversityService.createBoardOrUniversity(this.state.data);
      else
        await boardOrUniversityService.updateBoardOrUniversity(this.state.data);
      this.props.history.goBack();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t(titleText)} />

          {this.renderForm(this.formJson, dropDowns)}

          <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["boardOrUniversity", "common"])(
  BoardOrUniversityForm
);
