import { Grid } from "@mui/material";
import React from "react";
import enquiryFollowupService from "../../services/enquiryFollowupService";
import enquiryService from "../../services/enquiryService";
import EnquiryFollowUpStats from "../manage-enquiries/dashboard-cards/enquiryFollowUpStatsCard";
import EnquirySourceStatsCard from "../manage-enquiries/enquiry/enquirySourceStatsCard";
import EnquiryStatsCard from "../manage-enquiries/dashboard-cards/enquiryStatsCard";
import ApplicationStatsCard from "../manage-applications/dashboard-cards/applicationStatsCard";
import AdmissionStatsCard from "../manage-admissions/dashboard-cards/admissionStatsCard";
import BillingStatsCard from "../manage-admissions/dashboard-cards/billingStatsCard";

class AdmissionManagerDashboard extends React.Component {
  state = {
    enquiryStats: {},
    enquiryFollowupStats: {},
  };

  constructor() {
    super();
    this.getEnquiryStats();
    this.getEnquiryStatsByProgram();
  }

  getEnquiryStats = async () => {
    try {
      const { data: enquiryStats } = await enquiryService.getStats();
      sessionStorage.setItem("stats",JSON.stringify(enquiryStats))
      this.setState({ enquiryStats });
    } catch (error) { }
  };

  getEnquiryStatsByProgram = async () => {
    try {
      const { data: enquiryFollowupStats } =
        await enquiryService.getEnquiryStatsByProgram("enquiry");
      this.setState({ enquiryFollowupStats });
    } catch (error) { }
  };

  handleClick=(link)=>{
    console.log("link:",link);
    this.props.history.push(link);
  }

  render() {
    const { enquiryStats, enquiryFollowupStats } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item container spacing={2} >
          <Grid item xs={12} sm={12} md={4} onClick={()=>this.handleClick("/enquiry-analysis-by-program")} >
            {(enquiryStats) && <EnquiryStatsCard 
            
            // onClick={this.handleClick} 
            data={enquiryStats} />}
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            {(enquiryStats) && <EnquirySourceStatsCard data={enquiryStats} />}
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            {(enquiryStats && enquiryStats.followupType) && <EnquiryFollowUpStats onClick={this.handleClick} data={enquiryStats} stats={enquiryStats.followupType} />}
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={12} md={4}  onClick={()=>this.handleClick("/application-analysis-by-program")}>
            {(enquiryStats) && <ApplicationStatsCard 
            // onClick={this.handleClick}  
            data={enquiryStats} />}
          </Grid>
          <Grid item xs={12} sm={12} md={4} onClick={()=>this.handleClick("/admission-analysis-by-program")}>
            {(enquiryStats) && <AdmissionStatsCard 
            // onClick={this.handleClick} 
            data={enquiryStats} />}
          </Grid>
          <Grid item xs={12} sm={12} md={4} onClick={()=>this.handleClick("/billing-analysis-by-program")}>
            {(enquiryStats && enquiryStats.bcc) && <BillingStatsCard data={enquiryStats.bcc} />}
          </Grid>
          {/* <Grid item xs={12} sm={12} md={4}>
            <ApplicantStatsCard />
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={4}>
            <EnquirySourceStatsCard data={enquiryStats} />
          </Grid> */}
        </Grid>
      </Grid>
    );
  }
}

export default AdmissionManagerDashboard;
