import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import preAdmissionCalendarService from "../../../services/preAdmissionCalendarService";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import PreAdmissionCalendarsTable from "./preAdmissionCalendarsTable";

class PreAdmissionCalendars extends PagingAndSorting {
  state = {
    preAdmissionCalendars: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  async componentDidMount() {
    await this.getPreAdmissionCalendars();
  }

  getPreAdmissionCalendars = async () => {
    try {
      const {
        data,
      } = await preAdmissionCalendarService.getPreAdmissionCalendars();
      console.log("data:", data);
      this.setState({ preAdmissionCalendars: data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deletePreAdmissionCalendar = async (id) => {
    try {
      await preAdmissionCalendarService.deletePreAdmissionCalendar(id);
      await this.getPreAdmissionCalendars();
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    await this.deletePreAdmissionCalendar(id);
    this.getPreAdmissionCalendars();
  };

  render() {
    const { sortColumn, preAdmissionCalendars } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} direction="row-reverse">
            <Add link={"pre-admission-calendars/new"} title={t("New Calendar")}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PreAdmissionCalendarsTable
              data={preAdmissionCalendars}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["preAdmissionCalendar"])(PreAdmissionCalendars);
