import { Card, CardActions, CardContent, CardHeader, Divider, Grid, Hidden, List, ListItem, ListItemText, Paper, TablePagination, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import MatTableBody from "./matTableBody";
import MatTableHeader from "./matTableHeader";
import _ from "lodash";
import MatTablePagination from "./matTablePagination";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    fontWeight: "bold",
    // paddingTop: "7px",
    // paddingBottom: "7px",
    padding: theme.spacing(1, 2)

  },
  tableRow: {
    // paddingTop: "7px",
    // paddingBottom: "7px",
    padding: theme.spacing(1, 2)

  },
  cardAction: {
    // border:"1px solid "+theme.palette.secondary.main
  }
}))

const renderCell = (item, column, classes) => {

  if (!column.cardHeader && column.key !== "action" && column.content)
    return (<>
      <ListItem button>
        <ListItemText
          primary={<div style={{ margin: 0 }}>
            <span> {column.label}</span>
            <div style={{ fontSize: 14, float: "right" }}>
              <strong>{column.content(item)}</strong>
            </div>
          </div>} />
      </ListItem>
      <Divider />
    </>)
  if (!column.cardHeader && column.key !== "action")
    return (
      <>
        <ListItem button>
          <ListItemText
            primary={<div style={{ margin: 0 }}>
              <span> {column.label}</span>
              <div style={{ fontSize: 14, float: "right" }}>
                <strong>{_.get(item, column.path)}</strong>
              </div>
            </div>} />
        </ListItem>
        <Divider />
      </>
    )

};

const renderHeader = (item, columns, classes) => {
  const header = columns.find(column => column.cardHeader === true)
  if (header)
    return _.get(item, header.path)
}

const renderAction = (item, columns, classes) => {
  const action = columns.find(column => column.key === "action" && column.content)
  if (action)
    return action.content(item)

}

const MatCard = ({ data, columns, classes }) => {
  //console.log("matcard:");
  return <>
    {data && data.map((item, index) => (
      <>
        <Card>
          <CardHeader title={renderHeader(item, columns, classes)} />
          <CardContent>
            <List component="nav" aria-label="table in card view">
              {columns.map((column) => (
                <Typography variant="body2" component="p">
                  {renderCell(item, column, classes)}
                </Typography>
              ))}
            </List>
          </CardContent>
          <CardActions>
            {renderAction(item, columns, classes)}
          </CardActions>
        </Card>
        <br />
      </>
    ))}
  </>

}

const MatTable = (props) => {
  const { heading, columns, sortColumn, onSort, data,paginate, pagination } = props;
  const classes = useStyles();
  // console.log("mat table page:",pagination,"\nprops:",props)
  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        style={{ fontSize: "18px" }}
      >
        {heading}
      </Grid>
      <Grid item xs={12}>
        <Hidden only={["md", "lg", "xl"]}>
          <MatCard data={data} columns={columns} classes={classes} ></MatCard>
        </Hidden>
        <Hidden only={["xs", "sm"]}>
          <TableContainer component={Paper}>
            <Table>
              <MatTableHeader
                columns={columns}
                onSort={onSort}
                sortColumn={sortColumn}
                classes={classes}
              />
              <MatTableBody data={data} columns={columns} classes={classes} />
            </Table>
          </TableContainer>
        </Hidden>
      </Grid>
      {paginate && <Grid item xs={12}>
        <TablePagination
          rowsPerPageOptions={[10,25,50,100]}
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={pagination.onPageChange}
          onRowsPerPageChange={pagination.onRowsPerPageChange}
        />
      </Grid>
      }
      {/* <MatTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> */}

    </React.Fragment>
  );
};

export default MatTable;
