import { Icon, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const FeeItemTable = ({ data, sortColumn, onSort, onDelete,onEdit }) => {
  const { t } = useTranslation(["feeItem"]);
 
  const columns = [
    {
      path: "label",
      label: t("Name")
    },
    {
      path: "remarks",
      label: t("Remarks")
    },     
    {
      key: "action",
      content: (item) => {
        return <>
        <Edit item={item} onEdit={onEdit}/> &nbsp;&nbsp;
        <Delete onDelete={onDelete} item={item} />
        </>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default FeeItemTable;
