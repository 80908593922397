import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import routeJson from "./route.json"
import routeService from "./route.service";
import busService from "../manage-buses/bus.service";
import employeeService from "../../../services/employeeService";

class RouteForm extends Form {
  constructor() {
    super();
    this.formJson = routeJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  async componentDidMount() {
    this.getBuses();
    this.getDrivers();
    await this.populateForm();
  }


  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await routeService.get(id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async getBuses(){
    const {data:buses}=await busService.getAll();
    const dropDowns={...this.state.dropDowns}
    dropDowns.buses=buses;
    this.setState({dropDowns});
    }

    async getDrivers(){
      const {data:drivers}=await employeeService.getAllByRole("BUS_DRIVER");
      const {data:attenders}=await employeeService.getAllByRole("BUS_ATTENDER");

      const dropDowns={...this.state.dropDowns}
      dropDowns.drivers=drivers;
      dropDowns.attenders=attenders;
      this.setState({dropDowns});
    }
  

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await routeService.create(this.state.data);
      } else {
        await routeService.update(this.state.data,id);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t(titleText)} />
          {this.renderForm(this.formJson, dropDowns)}
          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["route", "common"])(
  RouteForm
);
