import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  getBloodGroups,
  getGenders,
  getMotherTongue,
  getNationality,
  getReligion,
} from "../../../data/enums";
import programService from "../../../services/degreeService";
import enquiryService from "../../../services/enquiryService";
import icamelService from "../../../services/icamelService";
import logger from "../../../services/logger";
import personalInfoService from "../../../services/personalInfoService";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class ApplicationPreviewForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("applicationPreview");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getPrograms = async () => {
    try {
      const { data } = await programService.getPrograms();

      const dropDowns = this.state.dropDowns;
      dropDowns.programs = data;
      this.setState({ dropDowns });
    } catch (ex) {
      console.log("programs ", ex);
    }
  };

  onSelectOf = (item, value) => {
    switch (item) {
      case "country" || "paCountry":
        const { data: countries } = icamelService.getStatesByCountry(value);
        this.setState({ countries });
        break;

      case "state" || "paState":
        const { data: states } = icamelService.getCitiesByState(value);
        this.setState({ states });
        break;

      case "sameAsCommunication":
        const { data } = this.state;
        if (value) {
          const address = {
            ...data,
            ...{
              sameAsCommunication: value,
              paCountry: data.country,
              paState: data.state,
              paCity: data.city,
              paAddressLine1: data.addressLine1,
              paAddressLine2: data.addressLine2,
              paPostalCode: data.postalCode,
            },
          };

          this.setState({ data: address });
        } else {
          const address = {
            ...data,
            ...{
              sameAsCommunication: value,
              paCountry: "",
              paState: "",
              paCity: "",
              paAddressLine1: "",
              paAddressLine2: "",
              paPostalCode: "",
            },
          };
          this.setState({ data: address });
        }
        break;
    }
  };

  getGenders = () => {
    const { dropDowns } = { ...this.state };

    const updated = { ...dropDowns, genders: getGenders() };
    this.setState({ dropDowns: updated });
  };

  // getBloodGroups = () => {
  //   const { dropDowns } = { ...this.state };

  //   const updated = { ...dropDowns, bloodGroups: getBloodGroups() };
  //   this.setState({ dropDowns: updated });
  // };

  getBloodGroups = () => {
    const { dropDowns } = { ...this.state };

    const updated = {
      ...dropDowns,
      bloodGroups: getBloodGroups(),
      nationalities: getNationality(),
      religions: getReligion(),
      motherTongues: getMotherTongue(),
    };
    this.setState({ dropDowns: updated });
  };

  async populateForm() {
    const user = this.props.user;

    try {
      //console.log("user:", user);
      const { data } = await enquiryService.getEnquiryByUserIdIfApplicant(user.id);
      if (data) this.mapToViewModel(data);
      else return;
    } catch (ex) {
      // logger.error(ex);
      if (user.id) this.mapToViewModel({ ...user });
    }
  }

  mapToViewModel(obj) {
    
    this.setState({ data: getStateObject(this.formJson.groups, obj) });
  }

  async componentDidMount() {
    await this.populateForm();
    this.getBloodGroups();
    this.getGenders();
  }

  raiseBack = () => {
    this.props.onBack();
  };

  doSubmit = async (id) => {
    //console.log("user id:", id);
    try {
      if (this.state.data.id) {
        //update method
        await enquiryService.update({...this.state.data,applicationStatus:"SUBMITTED"}, id);
      } else {
        await enquiryService.create(this.state.data, id);
      }
      // this.props.onNext();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t, user } = this.props;
    const { dropDowns,data } = this.state;

    const buttonText = getFormButtonText(user.id);

    const titleText = getFormTitleText(user.id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, user.id)}>
          {this.renderForm(this.formJson, dropDowns)}
          
        {data.applicationStatus !=="SUBMITTED" && 
         <CardContent>
            <Grid container item md={12} justifyContent="center">
              { this.renderMatButton(t("common:Submit"))}
              {/* {this.renderMatButton(t("common:Reset"), "reset", "secondary")} */}
            </Grid>
          </CardContent>}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["personalInfo", "common"])(
  ApplicationPreviewForm
);
