import { ButtonGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEGREE_OR_EQUILNT_ROUTE } from "../../data/routerConstants";
import Delete from "../common/delete";
import Edit from "../common/edit";
import MatTable from "../common/matTable";

const ProgramsTable = ({ data, sortColumn, onSort, onDelete,onEdit,onViewClick }) => {
  const { t } = useTranslation(["program"]);
  const columns = [
    // { path: "id", label: t("ID") },
    { path: "programType", label: t("Type") },
    {
      path: "name",
      label: t("Program")
    },
    { path: "duration", label: t("Duration in Years") },
    {
      path: "coordinatorName",
      label: t("Program Co-ordinator Name")
    },
    {
      path: "coordinatorEmail",
      label: t("Email")
    },
    {
      path: "coordinatorMobile",
      label: t("Mobile")
    },
    {
      key: "action",
      content: (item) => (
        <>
        <Edit  onEdit={()=>onViewClick(item)} icon="visibility" iconOnly="true" title="View Terms" />&nbsp;
        <Edit item={item} onEdit={onEdit}/>&nbsp;
        <Delete onDelete={onDelete} item={item} />
        </>
      ),
    }
  ];

  return (
    <MatTable
      heading={t("List of Programs")}
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default ProgramsTable;
