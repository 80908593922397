import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const AdmissionPartnerTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["admissionPartner"]);
  const columns = [
    { path: "id", label: t("ID") },
    { path: "name", label: t("Name") },
    {
      path: "mobile",
      label: t("Contact #"),
    },
    {
      path: "email",
      label: t("Email"),
    },
    // { path: "instituteName", label: t("Institute") },
    // { path: "amount", label: t("Fee") },
    // { path: "status", label: t("Status") },
    {
      key: "action",
      content: (item) => {
        return <Edit link={`/admission-partners/${item.id}`} />;
      },
    },
    {
      key: "delete",
      content: (item) => <Delete onDelete={onDelete} item={item} />,
    },
  ];

  return (
    <MatTable
      data={data}
      heading="Admission Partners"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default AdmissionPartnerTable;
