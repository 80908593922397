import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import FormDialog from "../../common/formDialog";
import PagingAndSorting from "../../common/pagingAndSorting";

import FeeStructureTable from "./feeStructureTable";
import logger from "../../../services/logger";
import Add from "../../common/add";
import feeStructureService from "../../../services/feeStructureService";
import FeeStructureForm from "./feeStructureForm";

class FeeStructures extends PagingAndSorting {
  state = {
    institutes: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    dialogOpen: false
  };

  getAll = async () => {
    try {
      const { data } = await feeStructureService.getAll();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteById = async (id) => {
    try {
      await feeStructureService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getAll();
  }

  
  closeDialog = () => {
   
    this.setState({ dialogOpen: false });
    this.getAll();
  };
  onConfirmOk = async (id) => {
    try {
      await feeStructureService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, data,dialogOpen,id } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">

            <Add onAdd={this.handleAdd} item={{id:"new"}} icon="add_circle" title={t("New Fee Structure")}/>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FeeStructureTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}

        {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={"Add or Update"}
            content={
              <FeeStructureForm                 
                id={id ? id : "new"}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["feeItem"])(FeeStructures);
