import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

function InfoDialog({ open, onOk, onCancel, title, content, ...rest }) {
  return (
    <Dialog
      disableEscapeKeyDown
      minWidth="xs"
      aria-labelledby="info-dialog-title"
      open={open}
      {...rest}>
      <DialogTitle id="info-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => onOk({ type: "ok", value: "none" })}
          color="primary"
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;
