import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ADMISSION_ENQUIRY_ROUTE } from "../../../data/routerConstants";
import authService from "../../../services/authService";
import ButtonLink from "../../common/buttonLink";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { formatToDate } from "../../common/dateUtil";

const ApplicantsCopyTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiries", "common"]);
  const columns = [
    {
      path: "date",
      label: t("Date"),
      sort: true,
      content: (item) => {
        return formatToDate(item.date)
      }
    },
    // {
    //   path: "applicationNumber",
    //   label: t("Application #")
    // },
    
    { path: "userName", label: t("Name") },
    { path: "userMobile", label: t("Mobile #") },
    { path: "programName", label: t("common:Program") },
    { path: "gender", label: t("Gender") },
    { path: "source", label: t("Source") },
    { path: "enquiryType", label: t("Type") },
    { path: "lastFollowedUpDate", 
    label: t("LFD"),
    helperText:t("Last Followup Date"),
    content: (item) => {
      return formatToDate(item.lastFollowedUpDate)
    } },
    { path: "nextFollowUpDate",
     label: t("NFD"),
     helperText:t("Next Followup Date"),
     sort: true,

     content: (item) => {
      return formatToDate(item.nextFollowUpDate)
    }
    },
    {
      key: "action",
      content: (item) => {
        // if (authService.getCurrentUser().id == item.counsellorId || authService.getCurrentRole() === "ADMISSION_MANAGER")
          return (
            <><Edit
              title={t("Followup")}
              link={"/manage-applications/" +
                item.id +
                "/applicant-followups?item=" +
                item}
              icon={"sync_alt"} />
              &nbsp;&nbsp;
              <Edit
                title={(item.applicationStatus === "SUBMITTED" || item.applicationStatus === "APPROVED") ? t("View") : t("Fill")}
                link={"/manage-applications/" +
                  item.userId +
                  "/fill-application?item=" +
                  item}
                icon={(item.applicationStatus === "SUBMITTED" || item.applicationStatus === "APPROVED")? "visibility" :"pending_actions"} /></>
          );

      },
    },
    // {
    //   key: "action",
    //   content: (item) => {
    //     // if (authService.getCurrentUser().id == item.counsellorId || authService.getCurrentRole() === "ADMISSION_MANAGER")
    //       return (
    //         <Edit
    //           title={(item.applicationStatus==="SUBMITTED" || item.applicationStatus==="APPROVED")?t("View"):t("Fill")}
    //           link={
    //             "/manage-applications/" +
    //             item.userId +
    //             "/fill-application?item=" +
    //             item
    //           }
    //           icon={"pending_actions"}
    //         />
    //       );
    //   },
    // },
  ];

  return (
    <MatTable
      data={data}
      heading="List of Applicants"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default ApplicantsCopyTable;
