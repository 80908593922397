import {
  IconButton,
  Typography,
 
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1,2),
    background:theme.palette.info.main,
    color:theme.palette.info.contrastText,
    borderColor:theme.palette.info.main
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.info.contrastText,
  },
  dialogHeader:{
    background:theme.palette.info.main,
    color:theme.palette.info.contrastText
  }
}));

const DialogTitle = (props) => {
  const { children,onClose, ...other } = props;
  const classes=useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

function FormDialog({ open, onClose, title, content, ...rest }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog
      disableEscapeKeyDown
      minWidth="lg"
      maxWidth="lg"
      aria-labelledby={title}
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      {...rest}
      style={{minWidth:"400px"}}>
      <DialogTitle id={title+"dialog-title"} onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
    </Dialog>
  );
}

export default FormDialog;
