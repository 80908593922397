import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
// import renderHTML from 'react-render-html';
import htmr from 'htmr';
const TermsAndConditionsTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["applicationFeeConfigs"]);
  const heading = "Terms & Conditions";
  const columns = [
    // { path: "id", label: t("ID") },
    {
      path: "value",
      label: t("Terms & Conditions"),
      content:(item)=>{
        return <div >
          {htmr(item.value)}
        </div>
      }
    },
    { path: "link", label: t("Link") },
    { path: "type", label: t("T & C For") },
    {
      key: "action",
      content: (item) => {
        return (
          <>
          <Edit
            title={t("common:Edit")}
            link={"/configurations/terms-and-conditions/" + item.id}
            /> &nbsp;
            <Delete onDelete={onDelete} item={item} />
            </>
        );
      },
    }
  ];

  return (
    <MatTable
      data={data}
      columns={columns}
      heading={heading}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default TermsAndConditionsTable;
