import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import authService from "../../../services/authService";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import PagingAndSorting from "../../common/pagingAndSorting";
import MyEnquiryTable from "./myEnquiryTable";

class MyEnquiry extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    totalElements: 0,
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const userId = await authService.getCurrentUser().id;
    alert(userId);
    try {
      const { data } = await enquiryService.getByUserId(userId);
      // console.log("data :", data);
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, data, rowsPerPage, page, totalElements } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
            
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MyEnquiryTable
              data={data}
              heading="My Enquiry Details"
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiries"])(MyEnquiry);
