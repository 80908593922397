import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const AdmissionFeeConfigsTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["admissionFeeConfigs"]);
  const columns = [
    { path: "id", label: t("ID") },
    { path: "calendarAcademicYear", label: t("Academic Year") },
    {
      path: "degreeAlias",
      label: t("Degree or Equivelent"),
      content: (item) => {
        return (
          <Link to={`/admission-fee-configs/${item.id}`}>
            {item.degreeAlias}
          </Link>
        );
      },
      sort: true,
    },
    {
      path: "deptAlias",
      label: t("Dept or Program"),
      sort: true,
    },
    // { path: "instituteName", label: t("Institute") },
    { path: "amount", label: t("Fee") },
    // { path: "status", label: t("Status") },
    {
      key: "action",
      content: (item) => {
        return <Edit link={`/admission-fee-configs/${item.id}`} />;
      },
    },
    {
      key: "delete",
      content: (item) => <Delete onDelete={onDelete} item={item} />,
    },
  ];

  return (
    <MatTable
      data={data}
      heading="Admission Fee Configurations"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default AdmissionFeeConfigsTable;
