export const BOARD_OR_UNIVERSITY_ROUTE = "/board-or-universities";
export const BOARD_OR_UNIVERSITY_FORM_ROUTE = "/board-or-universities/:id";

export const GROUP_ROUTE = "/groups";
export const GROUP_FORM_ROUTE = "/groups/:id";

export const INSTITUTE_ROUTE = "/institutes";
export const INSTITUTE_FORM_ROUTE = "/institutes/:id";

export const DEGREE_OR_EQUILNT_ROUTE = "/degree-or-equilnts";
export const DEGREE_OR_EQUILNT_FORM_ROUTE = "/degree-or-equilnts/:id";

export const DEPT_OR_PRGM_ROUTE = "/dept-or-prgms";
export const DEPT_OR_PRGM_FORM_ROUTE = "/dept-or-prgms/:id";

export const PRE_ADMISSION_CALENDAR_ROUTE = "/pre-admission-calendars";
export const PRE_ADMISSION_CALENDAR_FORM_ROUTE = "/pre-admission-calendars/:id";

export const ADMISSION_APPLICATION_FORM_ROUTE = "/admission-application-forms";
export const ADMISSION_APPLICATION_FORM_FORM_ROUTE =
  "/admission-application-forms/:id";

export const MY_ADMISSION_APPLICATION_ROUTE = "/my-admission-applications";
export const MY_ADMISSION_APPLICATION_FORM_ROUTE =
  "/my-admission-applications/:id";

export const ADMISSION_APPLICATION_FORM_FEE_CONFIG_ROUTE =
  "/admission-application-form-fee-configs";
export const ADMISSION_APPLICATION_FORM_FEE_CONFIG_FORM_ROUTE =
  "/admission-application-form-fee-configs/:id";

export const ADMISSION_ENQUIRY_TARGET_ROUTE =
  "/manage-enquiries/enquiry-targets";
export const ADMISSION_ENQUIRY_TARGET_FORM_ROUTE =
  "/manage-enquiries/enquiry-targets/:id";

export const ADMISSION_ENQUIRY_ROUTE = "/admission-enquiries";
export const ADMISSION_ENQUIRY_FORM_ROUTE = "/admission-enquiries/:id";

export const ENQUIRY_FOLLOWUP_ROUTE =
  "/manage-enquiries/:enquiryId/enquiry-followups";
export const ENQUIRY_FOLLOWUP_FORM_ROUTE =
  "/manage-enquiries/:enquiryId/enquiry-followups/:id";

export const STUDENT_PERSONAL_INFO_ROUTE = "/personal-infos";
export const STUDENT_PERSONAL_INFO_FORM_ROUTE = "/personal-infos/:id";

export const ADDRESS_ROUTE = "/addresses";
export const ADDRESS_FORM_ROUTE = "/addresses/:id";

export const EDUCATION_ROUTE = "/educations";
export const EDUCATION_FORM_ROUTE = "/educations/:id";
