import { HEDGE_BROWN_SERVER } from "../data/endpointConstants";
import http from "./httpService";

const masterApi = HEDGE_BROWN_SERVER;
const apiEndpoint = "terms-and-conditions";

function create(data) {
  return http.post(`${masterApi}/${apiEndpoint}`, data);
}

function update(data) {
  return http.put(`${masterApi}/${apiEndpoint}`, data);
}

function get(id) {
  return http.get(`${masterApi}/${apiEndpoint}/${id}`);
}
function getByType(type) {
  return http.get(`${masterApi}/${apiEndpoint}/type/${type}`);
}
function getAll() {
  return http.get(`${masterApi}/${apiEndpoint}`);
}

function getAllByQuery(query) {
  return http.get(`${masterApi}/${apiEndpoint}`, {
    params: { ...query },
  });
}

function deleteById(id) {
  return http.delete(`${masterApi}/${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getByType,
  getAllByQuery,
  deleteById,
};
