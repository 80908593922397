import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import instituteService from "../../services/instituteService";
import logger from "../../services/logger";
import Add from "../common/add";
import ButtonLink from "../common/buttonLink";
import FormDialog from "../common/formDialog";
import PagingAndSorting from "../common/pagingAndSorting";
import InstituteBasicForm from "./instituteBasicForm";
import InstituteBasicTable from "./instituteBasicTable";

class InstituteBasics extends PagingAndSorting {
  state = {
    institutes: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    dialogOpen: false
  };

  getAll = async () => {
    try {
      const { data: institutes } = await instituteService.getInstitutes();
      this.setState({ institutes });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteInstitute = async (id) => {
    try {
      await instituteService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getAll();
  }

  
  closeDialog = () => {
   
    this.setState({ dialogOpen: false });
    this.getAll();
  };
  onConfirmOk = async (id) => {
    try {
      await instituteService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, institutes,dialogOpen,id } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">

            <Add onAdd={this.handleAdd} item={{id:"new"}} title={t("Add Institute")} />

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InstituteBasicTable
              data={institutes}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}

        {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={"Institution Details"}
            content={
              <InstituteBasicForm
               
                id={id ? id : "new"}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["institute"])(InstituteBasics);
