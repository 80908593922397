import { ButtonGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEGREE_OR_EQUILNT_ROUTE } from "../../data/routerConstants";
import Delete from "../common/delete";
import Edit from "../common/edit";
import MatTable from "../common/matTable";

const EmployeeTable = ({ data, sortColumn, onSort, onDelete,onEdit }) => {
  const { t } = useTranslation(["program"]);
  const columns = [
    {
      path: "firstName",
      label: t("Employee Name")
    },
    {
      path: "email",
      label: t("Email")
    },
    {
      path: "mobile",
      label: t("Mobile")
    },
    {
      path:"departmentName",
      label:t("Department")
    },
    {
      path:"roleName",
      label:"Role"
    },
    {
      path:"employeeType",
      label:"Type"
    },
    {
      key: "action",
      content: (item) => (
        <>
        <Edit title="Edit" icon="pen" item={item} onEdit={onEdit}/>&nbsp;
        <Delete onDelete={onDelete} item={item} />
        </>
      ),
    }
  ];

  return (
    <MatTable
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EmployeeTable;
