import http from "./httpService";

const apiEndpoint = "/hedge-brown-server/api/board-or-universities";

function createBoardOrUniversity(board) {
  return http.post(apiEndpoint, board);
}

function updateBoardOrUniversity(board) {
  return http.put(apiEndpoint, board);
}

function getBoardOrUniversity(boardId) {
  return http.get(`${apiEndpoint}/${boardId}`);
}

function getBoardOrUniversities() {
  return http.get(apiEndpoint);
}

function deleteBoardOrUniversity(boardId) {
  return http.delete(`${apiEndpoint}/${boardId}`);
}

export default {
  createBoardOrUniversity,
  updateBoardOrUniversity,
  getBoardOrUniversity,
  getBoardOrUniversities,
  deleteBoardOrUniversity,
};
