import { Card, CardActions, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { getDocumentTypes } from "../../../data/enums";
import enquiryService from "../../../services/enquiryService";
import icamelService from "../../../services/icamelService";
import logger from "../../../services/logger";
import specializationService from "../../../services/specializationService";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import queryString from "query-string";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import programService from "../../../services/programService";
import applicationFeeConfigService from "../../../services/applicationFeeConfigService";
import authService from "../../../services/authService";
import termDataService from "../../../services/termDataService";
import routeService from "../../transportation/manage-routes/route.service";
import stopService from "../../transportation/manage-stops/stop.service";


class EnquiryForm extends Form {

  constructor() {
    super();
    this.formJson = {};
    this.formJson = getDynamicFormJson("enquiryForm");
    this.isCheckDDAdded = false;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getEnumValue() {
    try {
      const documentTypes = getDocumentTypes();
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["documentTypes"] = documentTypes;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("applicationFormStatus ", ex);
    }
  }

  getCountries = async () => {
    try {
      //console.log("getcountries");
      const { data } = await icamelService.getCountries();
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["countries"] = data;
      this.setState({ dropDowns });
      if (this.state.data.country)
        await this.getStatesByCountry(this.state.data.country)
    } catch (ex) {
      logger.error(ex);
    }
  };

  getStatesByCountry = async (countryCode) => {
    try {
      const { data } = await icamelService.getStatesByCountry(
        countryCode
      );
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["states"] = data;
      this.setState({ dropDowns });
      if (this.state.data.state)
        await this.getCitiesByState(this.state.data.state);
    } catch (ex) {
      logger.error(ex);
    }
  };

  getCitiesByState = async (stateCode) => {
    try {
      const { data } = await icamelService.getCitiesByState(stateCode);
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["cities"] = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getInstitutes = async () => {
    //console.log("institutes...");
    try {
      const { data } = await icamelService.getInstitutes();
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["institutes"] = data;
      this.setState({ dropDowns });
      if (this.state.data.instituteId)
        await this.getProgramsByInstitute(this.state.data.instituteId);
    } catch (ex) {
      logger.error(ex);
    }
  };

  getProgramsByInstitute = async (instituteId) => {
    //console.log("instituteId :", instituteId);
    try {
      const { data } = await programService.getAllByInstitute(instituteId);
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["programs"] = data;
      this.setState({ dropDowns });
      if(this.state.data.programId)
        await this.getTermsByProgram(this.state.data.programId)
    } catch (ex) {
      logger.error(ex);
    }
  };

  checkDDJson(type) {

    if (type === "CHEQUE" || type === "DD") {
      const fields = [
        {
          dataType: "Integer",
          label: "Cheque / DD #",
          name: "checkDDNumber"
        },
        {
          dataType: "String",
          label: "Bank Name",
          name: "bankName"
        },
        {
          name: "checkDDDate",
          label: "Cheque / DD Date",
          dataType: "date",
          inputType: "date"
        }
      ]
      const group = {
        id: "checkDD",
        name: "Cheque / DD Details",
        fields: fields
      }
      if (!this.isCheckDDAdded) {
        this.formJson.groups.push(group);
        this.isCheckDDAdded = true;
        this.schema = getJoiSchema(this.formJson.groups);
       // console.log("fields :", this.formJson);
      }
    } else {
      const groups = this.formJson.groups.filter(j => j.id !== "checkDD");
      this.formJson.groups = [];
      this.formJson.groups.push(...groups);
      this.schema = getJoiSchema(groups);
      this.isCheckDDAdded = false;
    }
  }



  async getFeeDetailsByProgram(programId, applicant) {
    try {


      if (applicant) {
        const { data: fees } = await applicationFeeConfigService.getByProgramId(programId)
        //console.log("fees:", fees);
        //console.log("formjson:", this.formJson);
        const data = { ...this.state.data, "feeAmount": fees.feeAmount }
        const group = {
          id: "feeDetails",
          name: "Application Fee Details",
          fields: [
            {
              dataType: "Integer",
              label: "Application Fee Amount",
              name: "feeAmount",
              readOnly: true
            }, {
              dataType: "String",
              inputType: "radio",
              label: "Payment Mode",
              name: "applicationFeePaymentMode",
              defaultValue:"CASH",
              options: [
                {
                  id: "CASH",
                  name: "Cash"
                },
                {
                  id: "CHEQUE",
                  name: "Cheque"
                },
                {
                  id: "DD",
                  name: "DD"
                },
                {
                  id: "ONLINE",
                  name: "Online"
                }
              ]
            }
          ]
        }
        let json = [];
        json = [...this.formJson.groups];
        const obj = json.find(j => j.id === "feeDetails");
        if (obj) {

        } else {
          json.push(group);
          this.formJson.groups = [];
          //console.log("groups json:", json);
          this.formJson.groups.push(...json);
        }
        this.schema = getJoiSchema(json);
        this.mapToViewModel(data);
      }
      else {
        const json = this.formJson.groups.filter(j => j.id !== "feeDetails");
        //console.log("json:", json);
        this.formJson.groups = [];
        this.formJson.groups.push(...json);
        const freshData = { ...this.state.data }
        delete freshData.feeAmount;
        delete freshData.applicationFeePaymentMode;
        freshData.applicant = false;
        this.setState({ data: freshData })
        this.schema = getJoiSchema(json);
      }
    } catch (error) {

    }
  }

  getSpecializationsByCourse = async (courseId) => {
    try {
      const { data } =
        await specializationService.getSpecializationsByDegree(courseId);
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["programs"] = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getTermsByProgram = async (programId) => {
    try {
      const { data } =await termDataService.getAllByProgram(programId);

      const dropDowns = { ...this.state.dropDowns };
      dropDowns["terms"] = data;
      this.setState({ dropDowns,termId:data[0].id });
    } catch (ex) {
      logger.error(ex);
    }
  };

  async getRoutes(){
      const {data}=await routeService.getAll();
      const dropDowns={...this.state.dropDowns};
      dropDowns.routes=data;
      this.setState({dropDowns});
  }

  async getStops(routeId){
    const {data}=await stopService.getAllByRoute(routeId);
    const dropDowns={...this.state.dropDowns};
    dropDowns.stops=data;
    this.setState({dropDowns});
  }

  onSelectOf = (item, value) => {
    switch (item) {
      case "instituteId":
        this.getProgramsByInstitute(value);
        break;
      case "programId":
        this.getTermsByProgram(value)
      break;
      case "country":
        this.getStatesByCountry(value);
        break;
      case "state":
        this.getCitiesByState(value);
        break;
      case "applicant":
        console.log("applicant value:", value);
        this.getFeeDetailsByProgram(this.state.data.programId, value);
      case "applicationFeePaymentMode":
        this.checkDDJson(value);
        break;
      case "transportationAvailed":
        this.getRoutes();
        break;
      case "routeId":
          this.getStops(value);
          break;
    }
  };

  async componentDidMount() {
    await this.populateForm();
    this.getCountries();
    this.getInstitutes();
    const { location } = this.props;
    const source = queryString.parse(location.search).source;
    source
      ? this.setState({ source })
      : this.setState({ source: "COLLEGE_PORTAL" });
  }

  async populateForm() {
    const id = this.props.match.params.id;
    // const parsed = queryString.parse(window.location.search,);
    // console.log("parsed:",parsed.q);
    if (id === "new") return;
    try {
      const { data } = await enquiryService.get(id);
      const isApplicant = data.applicant;
      this.setState({ applicant: isApplicant })
      // if(parsed.q.toString()==="b" || parsed.q==="B"){
      //   let enqData=this.state.data;
      //   console.log("value enqdata:",enqData);
      //   enqData.applicant=true
      //   this.setState({data:enqData})
      //   this.getFeeDetailsByProgram(this.state.data.programId,true);
      //   this.mapToViewModel(data);

      // }
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  doSubmit = async (id) => {
    try {
      const { data, source,termId } = this.state;
      if (id === "new") {
        //console.log("enquiry data:", data);
        const { data: enqData } = await enquiryService.createByCounsellor({
          ...data,
          termId,
          userLogin: data.email,
          source: source,
          tcAccepted: true,
          counsellorId: authService.getCurrentUser().id
        });
        if (enqData.status === "APPLICANT")
          this.props.history.push("/manage-applications/applicants");
        else
          this.props.history.push("/admission-enquiries");
      } else {
        const { data: appData } = await enquiryService.updateByCounsellor(this.state.data);
        if (appData.status === "APPLICANT")
          this.props.history.push("/manage-applications/applicants");
        else
          this.props.history.push("/admission-enquiries");
      }
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;
    //console.log("user");
    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t("Enquiry Details")} />
          {this.renderForm(this.formJson, dropDowns, false)}
          <CardActions>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {!this.state.applicant && this.renderMatButton(t("Submit"))}
              {this.renderMatButton(t("Cancel"), "reset")}
            </Grid>
          </CardActions>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["enquiryForm", "common"])(
  EnquiryForm
);
