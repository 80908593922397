import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import PagingAndSorting from "../../common/pagingAndSorting";

import MatTablePagination from "../../common/matTablePagination";

import AdmissionFeePaymentTransactionTable from "./admissionFeePaymentTransactionTable";
import admissionFeePaymentService from "../../../services/admissionFeePaymentService";
import BackButton from "../../common/backButton";
import downloadFileService from "../../../services/downloadFileService";

class AdmissionFeePaymentTransactions extends PagingAndSorting {
  state = {
    programs: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    formDialogOpen: false
  };



  componentDidMount() {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    this.getPagedData({ page, size, sort });
  }

  getPagedData = async (query) => {
    const id = this.props.match.params.applicantId;

    try {
      const { data,headers } = await admissionFeePaymentService.getTransactionsByStudent(id,{
        ...query
      });
      // this.setState({ transactions })
      // const { data, headers } = await employeeService.getAllByDepartment(this.props.departmentId, {
      //   ...query
      // });
      //console.log("employees data:", data);
      const totalElements = headers["x-total-elements"];
      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleDownload = (item) => {
    // downloadFileService.downloadFile
    downloadFileService.downloadFile("admission-fee-payments/receipt/" + item.id, item.id + "_admission_fee.pdf")

}

  render() {
    const { sortColumn, data,  totalElements, rowsPerPage, page } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
         
          <Grid container item xs={12} sm={12} md={12} lg={12} direction="row-reverse" >
           <BackButton {...this.props} ></BackButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AdmissionFeePaymentTransactionTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              onDownload={this.handleDownload}
            />
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}

       

      </React.Fragment>
    );
  }
}

export default withTranslation(["program"])(AdmissionFeePaymentTransactions);
