import React from 'react';
import { Button, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";

const BackButton = (props) => {
    const { t } = useTranslation("common");
    const handleBack = () => {
        props.history.goBack();
    }
    return (
        <Button style={{marginLeft:"auto"}} startIcon={<Icon>navigate_before</Icon>} color="primary" onClick={handleBack} variant="text" >
            {t("Back")}
        </Button>);
}

export default BackButton;