import { ButtonGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEGREE_OR_EQUILNT_ROUTE } from "../../data/routerConstants";
import Delete from "../common/delete";
import Edit from "../common/edit";
import MatTable from "../common/matTable";

const StudentTable = ({ data, sortColumn, onSort, onDelete,onEdit }) => {
  const { t } = useTranslation(["program"]);
  const columns = [
    {
      path: "userName",
      label: t("Student Name")
    },
    {
      path: "userEmail",
      label: t("Email")
    },
    {
      path: "userMobile",
      label: t("Mobile")
    },
    {
      path:"programName",
      label:t("Program")
    },
    {
      path:"termLabel",
      label:t("common:Term")
    },
    {
      key: "action",
      content: (item) => (
        <>
        <Edit title="Edit" link={`/manage-students/${item.id}`} />&nbsp;
        <Delete onDelete={onDelete} item={item} />
        </>
      ),
    }
  ];

  return (
    <MatTable
    heading={t("List of Students")}
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default StudentTable;
