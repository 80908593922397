import { Card } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import departmentService from "../../services/departmentService";
import employeeService from "../../services/employeeService";
import logger from "../../services/logger";
import roleService from "../../services/roleService";
import OrgContext from "../app-context";
import { getDynamicFormJson } from "../common/dynamicFormService";
import Form from "../common/form";
import {
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";

class EmployeeForm extends Form {
  constructor(props) {
    super(props);
    //console.log("props in employee:",this.props);
    const type=this.props.department.type;
    if(type==="TEACHING"){
      this.formJson = getDynamicFormJson("employee");
    }else{
      this.formJson = getDynamicFormJson("employeeNonTeaching");

    }

    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getRoles = async () => {
    try {
      const { data } = await roleService.getRoles();
      const dropDowns = this.state.dropDowns;
      dropDowns.roles = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("roles ", ex);
    }
  };

  getDepartments = async () => {
    try {
      const { data } = await departmentService.getAll();
      const dropDowns = this.state.dropDowns;
      dropDowns.departments = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("roles ", ex);
    }
  };



  async populateForm() {
    const id = this.props.id;
    //console.log("employee id:",id);
    if (id === "new") return;
    try {
      const { data } = await employeeService.get(id);
      //console.log("employee data by id:",data);
      this.mapToViewModel(data);
    } catch (ex) { }
  }

  async componentDidMount() {
    await this.populateForm();
    // await this.getDepartments();
    await this.getRoles();
  }

  doSubmit = async (id) => {
    try {
      const { data } = this.state;
      
      let req;
      if(this.props.department.type==="NON_TEACHING"){

        req={...data,employeeType:"NON_TEACHING",departmentId:this.props.department.id}
      }else{
        req={...data,departmentId:this.props.department.id}

      }

      if (id === "new") await employeeService.create(req);
      else {
        await employeeService.update(req);
      }
      this.props.onSave();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    // const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.id;

    // const buttonText = getFormButtonText(id);

    // const titleText = getFormTitleText(id, t(this.formJson.alias));
    return (
      <Card>
        {/* <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} /> */}
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

        </form>
      </Card>
    );
  }
}
EmployeeForm.contextType = OrgContext;
export default withTranslation(["employee", "common"])(EmployeeForm);
