import { HEDGE_BROWN_SERVER } from "../data/endpointConstants";
import authService from "./authService";
import http from "./httpService";

const apiEndpoint = "/admission-application-forms";

function create(data) {
  const userId = authService.getCurrentUser().id;
  return http.post(`${HEDGE_BROWN_SERVER}${apiEndpoint}`, { ...data, userId });
}

function update(data) {
  const userId = authService.getCurrentUser().id;
  return http.put(`${HEDGE_BROWN_SERVER}${apiEndpoint}`, { ...data, userId });
}

function get(id) {
  return http.get(`${HEDGE_BROWN_SERVER}${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(`${HEDGE_BROWN_SERVER}${apiEndpoint}`);
}

function getAllByUser() {
  const userId = authService.getCurrentUser().id;
  return http.get(`${HEDGE_BROWN_SERVER}${apiEndpoint}/user/${userId}`);
}

function getAllByQuery(query) {
  return http.get(`${HEDGE_BROWN_SERVER}${apiEndpoint}/q`, {
    params: { ...query },
  });
}

function getApplicantStats() {
  return http.get(`${HEDGE_BROWN_SERVER}${apiEndpoint}/stats`);
}

function deleteById(id) {
  return http.delete(`${HEDGE_BROWN_SERVER}${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByUser,
  getAllByQuery,
  getApplicantStats,
  deleteById,
};
