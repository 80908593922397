import { ButtonGroup, Chip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEGREE_OR_EQUILNT_ROUTE } from "../../data/routerConstants";
import Delete from "../common/delete";
import Edit from "../common/edit";
import MatTable from "../common/matTable";
// import renderHTML from "react-render-html";
import htmr from 'htmr';
import { formatToDateAndTime } from "../common/dateUtil";

const UserTicketTable = ({ data, sortColumn, onSort, onDelete,onEdit }) => {
  const { t } = useTranslation(["tickets"]);
  const columns = [
    {
      path: "id",
      label: t("Ticket #")
    },
    {
      path: "description",
      label: t("Description"),
      content: (item) => {
        return htmr(item.description)
      }
    },
    {
      path: "ticketRaisedDate",
      label: t("Ticket Raised Date"),
      content: (item) => {
      return formatToDateAndTime(item.ticketRaisedDate)
      }
    },
    {
      path: "ticketResolvedDate",
      label: t("Ticket Resolved Date"),
      content: (item) => {
      return formatToDateAndTime(item.ticketResolvedDate)
      }
    },
    {
      path: "timeTakenToResolve",
      label: t("Time taken to resolve")
    },
    {
      path: "ticketType",
      label: t("Type"),
      content: (item) => {
        return(
          <Chip size="small"  label={t(item.ticketType)} />
          )
      }
    },
    {
      path: "status",
      label: t("Status"),
      content: (item) => {
        return(

          <Chip size="small"  label={t(item.status)} className={item.status} />
          
          )
      }
    },
  ];

  return (
    <MatTable
      heading={t("List of Tickets")}
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default UserTicketTable;
