import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getStateObject,
} from "../../common/formUtils";

import applicantBasicDetailsPreview from "../../../data/forms/applicantBasicDetailsPreview.json"

class ApplicationPreviewForm extends Form {
  constructor() {
    super();
    this.formJson = applicantBasicDetailsPreview;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    // this.schema = getJoiSchema(this.formJson.groups);
  }
  
  async populateForm() {
    const user = this.props.user;
    const status=this.props.status;
    try {
      //console.log("user:", user);
      const { data } = await enquiryService.getEnquiryByUserIdAndStatus(user.id,status);
      if (data) this.mapToViewModel(data);
      else return;
    } catch (ex) {
      // logger.error(ex);
      if (user.id) this.mapToViewModel({ ...user });
    }
  }

  mapToViewModel(obj) {    
    this.setState({ data: getStateObject(this.formJson.groups, obj) });
  }

  async componentDidMount() {
    await this.populateForm();
   
  }

  raiseBack = () => {
    this.props.onBack();
  };

  doSubmit = async (id) => {
    //console.log("user id:", id);
    try {
      const { data } = await enquiryService.submit(id);
      this.props.onNext(data.stage);
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t, user } = this.props;
    const { dropDowns,data } = this.state;

    const buttonText = getFormButtonText(user.id);

    const titleText = getFormTitleText(user.id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, user.id)}>
          {this.renderView(this.formJson)}
          
        {data.applicationStatus !=="SUBMITTED"  && data.applicationStatus !=="APPROVED" &&
         <CardContent>
            <Grid container item md={12} justifyContent="center">
              { this.renderMatButton(t("common:Submit"))}
              {/* {this.renderMatButton(t("common:Reset"), "reset", "secondary")} */}
            </Grid>
          </CardContent>}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["personalInfo", "common"])(
  ApplicationPreviewForm
);
