import React from "react";
import { useTranslation } from "react-i18next";
import Edit from "../common/edit";
import MatTable from "../common/matTable";
// import renderHTML from "react-render-html";
import htmr from 'htmr';
import { formatToDateAndTime } from "../common/dateUtil";
import { Chip } from "@mui/material";

const ResolveTicketTable = ({ data, sortColumn, onSort, onDelete, onEdit }) => {
  const { t } = useTranslation(["tickets"]);
  const columns = [
    {
      path: "description",
      label: t("Ticket Detail"),
      content: (item) => {
        return htmr(item.description)
      }
    },
    {
      path: "ticketRaisedDate",
      label: t("Ticket Raised Date"),
      content: (item) => {
      return formatToDateAndTime(item.ticketRaisedDate)
      }
    },
    {
      path: "ticketResolvedDate",
      label: t("Ticket Resolved Date"),
      content: (item) => {
      return formatToDateAndTime(item.ticketResolvedDate)
      }
    },
    {
      path: "timeTakenToResolve",
      label: t("Time taken to resolve")
    },
    {
      path: "ticketType",
      label: t("Type"),
      content: (item) => {
        return(

          <Chip size="small"  label={t(item.ticketType)} />
          
          )
      }
    },
    {
      path: "status",
      label: t("Status"),
      content: (item) => {
        return(

          <Chip size="small"  label={t(item.status)} className={item.status} />
          
          )
      }
    },

    {
      key: "action",
      content: (item) => (
        item.status !== "CLOSED" && 
        <>
          <Edit link={"/resolve-tickets/" + item.id} />&nbsp;
        </>
      ),
    }
  ];

  return (
    <MatTable
      heading={t("List of Tickets")}
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default ResolveTicketTable;
