import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const RolesTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["role"]);
  const columns = [
    { path: "id", label: t("ID") },
    {
      path: "name",
      label: t("Name"),
      content: (item) => {
        return <Link to={`/role-management/${item.id}`}>{item.name}</Link>;
      },
      sort: true,
    },
    { path: "alias", label: t("Display Name") },

    {
      key: "action",
      content: (item) => <Edit link={`/role-management/${item.id}`} />,
    },
    {
      key: "delete",
      content: (item) => <Delete onDelete={onDelete} item={item} />,
    },
  ];

  return (
    <MatTable
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default RolesTable;
