
import { Button, Card, CardContent, CardHeader, Divider, Grid, ImageList } from '@mui/material';
import React, { useState } from 'react';
import authService from '../../services/authService';
import GroupForm from '../goup/groupForm';
import InstituteBasicForm from '../institute/instituteBasicForm';
import InstituteBasics from '../institute/instituteBasics';
import InstituteForm from '../institute/instituteForm';
import Programs from '../program/programs';
import BasicInfoTrustForm from './basicInfoTrustForm';
import BomComposition from './bomComposition';
import GcComposition from './gcComposition';


const SynizaInstitute = () => {
    const [option, setOption] = useState(null)
    const [selected, setSelected] = useState("composition")
    // let option;
    function onClick(name) {
        //console.log("name trust info:",name);
        setSelected(name);
        setOption(getItem(name));
    }

    function getItem(name) {
        switch (name) {
            case "basicinfo":
                return <InstituteForm id={authService.getInstituteTenant()} />
            case "composition":
                return <GcComposition />
            case "program":
                return <Programs />
            default:
                break;
        }
    }

    function getTabs() {
        return [
            {
                name: "composition", label: "Governing Council"
            },
            {
                name: "basicinfo", label: "College Info"
            },

            {
                name: "program", label: "Program Info"
            }
        ]
    }


    return (
        <>
            <Grid container>
                {/* { getTabs().map(tab=> <Grid item alignItems="center" sm={4}>
                    <Button variant={tab.name===selected?'outlined':'contained'} style={{textAlign:"center"}}  color={tab.name===selected?'default':'white'} onClick={() => onClick(tab.name)}> {tab.label} </Button>
                </Grid>)} */}
                <Card>
                    <CardHeader></CardHeader>
                    <CardContent>
                        <Grid container spacing={15}>
                            {getTabs().map(tab => <Grid item sm={4}>
                                {tab.name === selected ? <Button color='default' fullWidth variant='contained' onClick={() => onClick(tab.name)}> {tab.label} </Button> : <Button color='outlinedDefault' fullWidth variant='outlined' onClick={() => onClick(tab.name)}> {tab.label} </Button>}
                            </Grid>)}
                        </Grid>
                    </CardContent>
                </Card>

            </Grid>
            <br />
            <Divider />
            <br />
            <Grid container item sm={12}>
                {getItem(selected)}
            </Grid>
        </>
    );
}

export default SynizaInstitute;