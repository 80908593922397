import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import committeeMeetingJson from "./committeeMeeting.json"
import committeeMeetingService from "./committeeMeeting.service";
import committeeService from "../../../services/committeeService";

class CommitteeMeetingForm extends Form {
  constructor() {
    super();
    this.formJson = committeeMeetingJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  async componentDidMount() {
   await this.getCommitte();
    await this.populateForm();
  }


  async populateForm() {
    const id = this.props.id;
    console.log("id of the meeting:",id);
    if (id === "new") return;
    try {
      const { data } = await committeeMeetingService.get(id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async getCommitte() {
    try{
      const { data: committee } = await committeeService.getByName(this.props.committee);
      this.setState({ committee });
    }catch(error){
      console.log("error:",error);
    }
  }





  

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await committeeMeetingService.create({...this.state.data,committeeId:this.state.committee.id});
      } else {
        await committeeMeetingService.partialUpdate(this.state.data,id);
      }
      this.props.onSave();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.id;

    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          {/* <CardHeader title={t(titleText)} /> */}
          {this.renderForm(this.formJson, dropDowns)}
          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["committe", "common"])(
  CommitteeMeetingForm
);
