import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { getDegreeOrDeptTypes, getDeptSubTypes } from "../../../data/enums";
import programService from "../../../services/degreeService";
import instituteService from "../../../services/instituteService";
import logger from "../../../services/logger";
import specializationService from "../../../services/programService";
// import formJson from "./spezs.json";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class SpecializationDynamicForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("specialization");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  onSelectOf = (item, value) => {
    switch (item) {
      case "instituteId":
        //console.log("institute id:", item, ":", value);
        this.getCourses(value);
        break;
      default:
        break;
    }
  };

  getInstitutes = async () => {
    try {
      const { data } = await instituteService.getInstitutes();
      const dropDowns = this.state.dropDowns;
      dropDowns.institutes = data;
      this.setState({ dropDowns });
    } catch (ex) {}
  };

  getCourses = async () => {
    try {
      const { data } = await programService.getPrograms();

      const dropDowns = this.state.dropDowns;
      dropDowns.courses = data;
      this.setState({ dropDowns });
    } catch (ex) {
      console.log("programs ", ex);
    }
  };

  getEnumValues = () => {
    const type = getDegreeOrDeptTypes();
    const subType = getDeptSubTypes();

    const dropDowns = this.state.dropDowns;
    dropDowns.types = type;
    dropDowns.subTypes = subType;
    this.setState({ dropDowns });
  };

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await specializationService.getSpecialization(id);
      this.mapToViewModel(data);
      // this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  }

  mapToViewModel(obj) {
    this.setState({ data: getStateObject(this.formJson.groups, obj) });
  }

  async componentDidMount() {
    await this.populateForm();
    this.getEnumValues();
    // await this.getInstitutes();
    await this.getCourses();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new")
        await specializationService.createSpecialization(this.state.data);
      else await specializationService.updateSpecialization(this.state.data);
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex.response.data.message);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t(titleText)} />

          {this.renderForm(this.formJson, dropDowns)}

          <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["specialization", "common"])(
  SpecializationDynamicForm
);
