import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/fee-items";
const apiEndpoint = "/api/fee-items";


function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data) { 
  return http.put(`${apiEndpoint}/${data.id}`, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(apiEndpoint);
}

function getAllByCategoryId(categoryId) {
  return http.get(`${apiEndpoint}/category/${categoryId}`);
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByCategoryId,
  deleteById,
};
