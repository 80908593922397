import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import instituteService from "../../services/instituteService";
import logger from "../../services/logger";
import PagingAndSorting from "../common/pagingAndSorting";
import InstitutesTable from "./instituteTable";

class Institutes extends PagingAndSorting {
  state = {
    institutes: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  getAll = async () => {
    try {
      const { data: institutes } = await instituteService.getInstitutes();
      this.setState({ institutes });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteInstitute = async (id) => {
    try {
      await instituteService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getAll();
  }

  // onConfirmOk = () => {
  //   this.deleteInstitute(this.state.id);
  //   this.setState({ confirmDialogOpen: false });
  // };

  onConfirmOk = async (id) => {
    try {
      await instituteService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, institutes } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          {/* <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
            <Button variant="outlined" color="primary">
              <Link to={"institutes/new"}>{t("New Institute")}</Link>
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <InstitutesTable
              data={institutes}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></InstitutesTable>
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["institute"])(Institutes);
