import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import educationService from "../../../services/educationService";
import logger from "../../../services/logger";
import FormDialog from "../../common/formDialog";
import PagingAndSorting from "../../common/pagingAndSorting";
import EducationForm from "./educationForm";
import EducationsTable from "./educationsTable";

class Educations extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    dialogOpen: false,
  };

  getAll = async () => {
    try {
      const { user } = this.props;
      const { data } = await educationService.getByUserId(user.id);
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog = () => {
    // dialogOpen=false;
    this.setState({ dialogOpen: false });
    this.getAll();
  };
  onConfirmOk = async (id) => {
    try {
      await educationService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const { sortColumn, data, id, dialogOpen } = this.state;
    const { t, user, activeStep, stepsLength } = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid container item sm={12} md={12} direction="row-reverse">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.handleEdit({ id: "new" })}
            >
              {t("Add Education")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EducationsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}

        {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={"Add or Update"}
            content={
              <EducationForm
                userId={user.id}
                id={id ? id : "new"}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["educations"])(Educations);
