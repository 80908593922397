import React from "react";
import MatTable from "../../common/matTable";
import { formatToDate } from "../../common/dateUtil";

const EnquiryFollowupsTable = ({ data, sortColumn, onSort,t }) => {
  // const { t } = useTranslation(["enquiryFollowup", "common"]);
  const columns = [
    { path: "followUpDate", label: t("FD"), sort: true,
    content: (item) => {
      return formatToDate(item.followUpDate)
    } },
    { path: "note", label: t("Comments") },
    {
      path: "applicant",
      label: "Applicant",
      content: (item) => (item.applicant ? "Yes" : "No"),
    },
    { path: "nextFollowUpDate",
     label: t("NFD"),
     helperText:t("Next Followup Date"),
     content: (item) => {
      return formatToDate(item.nextFollowUpDate)
    } },
    { path: "enquiryType", label: t("Enquiry Type") },
    { path: "followUpType", label: t("Followup Type") },

    // {
    //   key: "followup",
    //   content: (item) => {
    //     return (
    //       <Edit
    //         title={t("common:Edit")}
    //         icon=""
    //         ///  item={item}
    //         link={"enquiry-followups/" + item.id}
    //       ></Edit>
    //     );
    //   },
    // },
    // {
    //   key: "delete",
    //   content: (item) => <Delete item={item} onDelete={onDelete} iconOnly="true" />,
    // },
  ];

  return (
    <MatTable
      data={data}
      heading={t("Followup History")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EnquiryFollowupsTable;
