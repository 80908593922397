import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import enquiryDocumentConfigService from "../../../services/enquiryDocumentConfigService";
import logger from "../../../services/logger";
import FormDialog from "../../common/formDialog";
import PagingAndSorting from "../../common/pagingAndSorting";
import PaymentAndReceiptForm from "./paymentAndReceiptForm";
import PaymentAndRecieptsTable from "./paymentAndReceiptTable";
import downloadFileService from "../../../services/downloadFileService";

class PaymentAndReciepts extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  getAll = async () => {
    try {
      // const { data } = await enquiryService.getApplicationFee(this.props.user.id);
      const {data}=this.props
      //console.log("pay reciept :",this.props.data);
      this.setState({ data});
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await enquiryDocumentConfigService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

 async componentDidMount() {
  //console.log("componentDidMount:",this.props.data);
   await this.getAll();
  }

  handleDownload=(item)=>{
    //console.log("Download in process :",item);
    if(this.props.type==="APPLICATION_FEE")
    downloadFileService.downloadFile("admission-enquiries/application-fee-receipt/"+item.id,this.props.user.id+"application_fee.pdf")
    if(this.props.type==="ADMISSION_FEE")
    downloadFileService.downloadFile("/admission-fee-payments/receipt"+item.id,this.props.user.id+"_admission_fee.pdf")
  }
  hadlePayRemaining=(item)=>{
    //console.log("pay raming amount:",item.remaingAmount);
    const paymentData={...item,feeAmount:item.remaingAmount}
    this.setState({paymentData,dialogOpen:true})
  }

  handlePay=(item)=>{
    //console.log("pay  amount:",item.feeAmount);
// const paymentData={...item}
    this.setState({paymentData:item,dialogOpen:true})
  }

  render() {
    const { sortColumn, data,paymentData,dialogOpen } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
           &nbsp;
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PaymentAndRecieptsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDownload={this.handleDownload}
              onPayRemaining={this.hadlePayRemaining}
              onPay={this.handlePay}
            />
          </Grid>
          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
           &nbsp;
           </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
        {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={"Payment"}
            content={
              <PaymentAndReceiptForm             
                data={paymentData}
                user={this.props.user}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}


      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiryDocumentConfig"])(
  PaymentAndReciepts
);
