import http from "../../../../services/httpService";

const apiEndpoint = "/api/class-timetable-configs";

function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data) {
  return http.put(apiEndpoint, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(apiEndpoint);
}
function getAllByQuery(query) {
  return http.get(`${apiEndpoint}/q`,{params:query});
}

function getAllList() {
  return http.get(apiEndpoint + "/list");
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByQuery,
  getAllList,
  deleteById,
};
