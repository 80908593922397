import { Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

function DeleteConfirmDialog({
  open,
  onOk,
  onCancel,
  dialogTitle,
  dialogContent,
  ...rest
}) {
  return (
    <Dialog
      disableEscapeKeyDown
      minWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...rest}>
      {/* <DialogTitle id="confirmation-dialog-title">{dialogTitle}</DialogTitle> */}
      <DialogContent dividers style={{ textAlign: "center" }}>
        <p > <Icon style={{ fontSize: "50px", color: "#ed5c4d" }}>{"cancel"}</Icon> </p>

        <h1>Are you sure?</h1>
        <p style={{fontSize:"14px"}}>Do you really want to delete these records? <br /> this process can not be undone.</p>
        {/* {dialogContent} */}

      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button variant="contained" autoFocus onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => onOk({ type: "ok", value: "none" })}
          // color="primary"
          variant="contained"
          style={{ color: "#fff", background: "#ed5c4d" }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmDialog;
