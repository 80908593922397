import { Card, CardContent, Grid } from '@mui/material';
import React, { Component } from 'react';
import authService from '../../../services/authService';
import enquiryService from '../../../services/enquiryService';
class AdmissionEnrollment extends Component {
    state = {}

    async componentDidMount() {
        const { data } = await enquiryService.getEnquiryByUserId(this.props.user.id)
        this.setState({ data })
    }

    render() {
        const { data } = this.state;
        return (
            <>
                <Grid container>
                    <Grid item md={3}>
                        &nbsp;
                    </Grid>
                    <Grid item md={6}>
                        <Card>
                            <CardContent>
                                <h3>
                                    Dear {data?.userName},<br />
                                    Congratulatios...! <br />
                                    You have successfully enrolled for the course {data?.programName}.
                                </h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3}>
                        &nbsp;
                    </Grid>
                </Grid>  
            </>
        );
    }
}

export default AdmissionEnrollment;