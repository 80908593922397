import { Card, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import admissionFeeDetailService from "../../../services/admissionFeeDetailService";
import admissionFeeTemplateService from "../../../services/admissionFeeTemplateService";
import logger from "../../../services/logger";
import programService from "../../../services/programService";
import BackButton from "../../common/backButton";
import Form from "../../common/form";
import {

  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import feeStructureService from "../../../services/feeStructureService";
import termDataService from "../../../services/termDataService";
import MatSelect from "../../common/matSelect";

class FeeDetailForm extends Form {
  state = {
    formJson: {},
    feeStructureId: ""
  }
  constructor() {
    super();
    // this.getTemplate();
  }

  async getTemplate() {
    const { data } = await admissionFeeTemplateService.getTemplateByStructureId();
    //console.log("json:",data);
    this.setState({
      formJson: data,
      data: getStateObject(data.groups),
      errors: {},
      dropDowns: {}
    })

    this.schema = getJoiSchema(data.groups);
  }

  async getTemplateByStructureId(id) {

    const { data } = await admissionFeeTemplateService.getTemplateByStructureId(id);
    //console.log("json:",data);
    this.setState({
      formJson: data,
      data: getStateObject(data.groups),
      errors: {},
      dropDowns: {}
    })

    this.schema = getJoiSchema(data.groups);
    await this.getPrograms();
    await this.populateForm();
  }


  onSelectOf = async (item, value) => {

    switch (item) {
      case "instituteId":
        await this.getPrograms(value);
        break;

    }
  };

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data: feeData } = await admissionFeeDetailService.get(id);
      //console.log("data:",feeData);
      this.mapToViewModel({ ...feeData.data, label: feeData.label, id: feeData.id, programId: feeData.programId }, this.state.formJson);
      // this.mapToViewModel(null, {groups:feeData.groups},true);

    } catch (ex) {
      logger.error(ex);
    }
  }

  getFeeStructures = async () => {
    try {
      const { data: feeStructures } = await feeStructureService.getAll();;
      // const dropDowns = { ...this.state };
      // dropDowns.feeStructures = feeStructures;
      this.setState({ feeStructures });
    } catch (ex) {
      logger.error(ex);
    }
  }

  getPrograms = async () => {
    try {
      const { data: programs } = await programService.getAll();
      const dropDowns = { ...this.state };
      dropDowns.programs = programs;
      this.setState({ dropDowns,programs });
    } catch (ex) {
      logger.error(ex);
    }
  };

  async componentDidMount() {
    await this.getFeeStructures();
    const feeStructureId = this.props.match.params.feeStructureId
    if (feeStructureId !== "new") {
      this.setState({ feeStructureId })
      this.getTemplateByStructureId(feeStructureId)
    }
    await this.getPrograms();

  }

  handleFeeStructureChange = async (event) => {
    this.setState({ feeStructureId: event.target.value })
    await this.getTemplateByStructureId(event.target.value);
    await this.populateForm();

  }

  handleProgramChange = async (event) => {
    this.setState({ programId: event.target.value })
    const {data:terms}=await termDataService.getAllByProgram(event.target.value);
    this.setState({terms});

  }

  handleTermChange = async (event) => {
    this.setState({ termId: event.target.value });
  }

  doSubmit = async (id) => {
    try {
      const feeData = { ...this.state.data };
      const {programId,termId,feeStructureId}=this.state;
      let req = { label: feeData.label, programId, termId, feeStructureId};
      delete feeData.label;
      delete feeData.programId;
      req = { ...req, data: feeData };
      // console.log("request :",req);
      if (id === "new") {
        await admissionFeeDetailService.create(req);
      } else {
        await admissionFeeDetailService.update({ ...req, id: id });
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns, formJson, feeStructures, feeStructureId,programs,programId,terms,termId } = this.state;
    const id = this.props.match.params.id;

    return (
      <>
        {id === "new" &&
          <Grid container spacing={2} >
            <Grid item xs={2}>
              <MatSelect label={t("common:Select Program")} options={programs} value={programId} onChange={this.handleProgramChange}  />
            {/* <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="program-id">Select Program</InputLabel>
                <Select
                  labelId="program-id"
                  id="program"
                  value={programId}
                  onChange={this.handleProgramChange}
                  label="Select Fee Structure"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {programs?.map(program => <MenuItem value={program.id}>{program.name}</MenuItem>)}

                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={2}>
            <MatSelect label={t("term:Select Term")} options={terms} value={termId} onChange={this.handleTermChange}  />

            {/* <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="term-data-id">Select Term</InputLabel>
                <Select
                  labelId="term-data-id"
                  id="term-data"
                  value={termId}
                  onChange={this.handleTermChange}
                  label="Select Fee Structure"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {terms?.map(term => <MenuItem value={term.id}>{term.termLabel}</MenuItem>)}

                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={2}>
            <MatSelect label={t("Select Fee Structure")} options={feeStructures} value={feeStructureId} onChange={this.handleFeeStructureChange}  />

              {/* <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="fee-template-label-id">Select Fee Structure</InputLabel>
                <Select
                  labelId="fee-template-label-id"
                  id="fee-template-label"
                  value={feeStructureId}
                  onChange={this.handleFeeStructureChange}
                  label="Select Fee Structure"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {feeStructures?.map(fee => <MenuItem value={fee.id}>{fee.name}</MenuItem>)}

                </Select>
              </FormControl> */}
            </Grid>
          </Grid>
        }
        <br />
        <Card>
          <CardHeader title={t("Fee Details")} action={<BackButton {...this.props} />} />

          <form onSubmit={(event) => this.handleSubmit(event, id)}>
            {this.renderForm(formJson, dropDowns)}

          </form>
        </Card>
      </>
    );
  }
}

export default withTranslation(["feeDetails","common"])(
  FeeDetailForm
);
