import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";

const AdmissionFeeConfigTableView = ({
  data,
  sortColumn,
  onSort,
  onDelete,
}) => {
  const { t } = useTranslation(["admissionFeeConfigs"]);
  //console.log("feedata:",data);
  const columns = [
    // { path: "id", label: t("ID") },
    // { path: "calendarAcademicYear", label: t("Academic Year") },
    {
      path: "programName",
      label: t("common:Program"),
    },
    // {
    //   path: "deptAlias",
    //   label: t("Specialization"),
    // },
    // { path: "instituteName", label: t("Institute") },
    { path: "totalFeeAmount", label: t("Fee Amount") },
    // { path: "status", label: t("Status") },
    // {
    //   key: "action",
    //   content: (item) => {
    //     return <Edit link={`/admission-fee-configs/${item.id}`} />;
    //   },
    // },
    // {
    //   key: "delete",
    //   content: (item) => <Delete onDelete={onDelete} item={item} />,
    // },
  ];

  return (
    <MatTable
      data={data}
      heading="Admission Fee Details"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default AdmissionFeeConfigTableView;
