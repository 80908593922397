import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import enquiryService from "../../../services/enquiryService";
import PagingAndSorting from "../../common/pagingAndSorting";
import EnquiryWaitingTable from "./enquiryWaitingTable";

class EnquiryWaitingCard extends PagingAndSorting {
  state = {
    data: [],

    sortColumn: { path: "date", order: "desc" },
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    confirmDialogOpen: false,
    formDialogOpen: false,
  };

  componentDidMount() {
    this.getWaitingEnquiries();
  }

  async getWaitingEnquiries() {
    try {
      const { data } = await enquiryService.getWaitingEnquiries();
      this.setState({ data });
    } catch (error) {}
  }

  render() {
    const { data, sortColumn } = this.state;
    return (
      <React.Fragment>
        <Card className="dashboard-card">
          <CardHeader title="Enquiry Waiting"></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <EnquiryWaitingTable
                  data={data}
                  sortColumn={sortColumn}
                  onSort={this.handleSort}
                  onDelete={this.handleDelete}
                ></EnquiryWaitingTable>
                <br />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export default EnquiryWaitingCard;
