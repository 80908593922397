import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logger from "../../../services/logger";
import termsAndConditionsService from "../../../services/termsAndConditionsService";
import ButtonLink from "../../common/buttonLink";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import TermsAndConditionsTable from "./termsAndConditionsTable";

class TermsAndConditions extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  componentDidMount() {
    this.getAll();
  }

  onConfirmOk = (id) => {
    this.deleteTandC(id);
  };

  getAll = async () => {
    try {
      const { data } = await termsAndConditionsService.getAll();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteTandC = async (id) => {
    try {
      await termsAndConditionsService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, totalElements, rowsPerPage, page, data } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} direction="row-reverse">
            {/* <Button variant="contained" color="secondary">
              <Link to={"/configurations/terms-and-conditions/new"}>
                {t("New T & C")}
              </Link>
            </Button> */}
            <ButtonLink
             link={"/configurations/terms-and-conditions/new"}
             title={t("New T & C")}
             icon="add_circle"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TermsAndConditionsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />

            {totalElements && (
              <MatTablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            )}
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["termsAndConditions"])(TermsAndConditions);
