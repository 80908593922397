import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../services/logger";
import PagingAndSorting from "../common/pagingAndSorting";
import ButtonLink from "../common/buttonLink";
import FormDialog from "../common/formDialog";
import MatTablePagination from "../common/matTablePagination";
import employeeService from "../../services/employeeService";
import Add from "../common/add";
import StudentForm from "./studentForm";
import UploadStudentsForm from "./uploadStudentsForm";
import StudentTable from "./studentTable";
import studentService from "../../services/studentService";
import programService from "../../services/programService";
import termDataService from "../../services/termDataService";
import { GROUP_FORM_ROUTE } from "../../data/routerConstants";

class Students extends PagingAndSorting {
  state = {
    programs: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    formDialogOpen: false
  };

  

  getPrograms = async () => {
    try {
      const { data: programs } = await programService.getAll();
      this.setState({ programs });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getAll = async () => {
    try {
      const { data } = await employeeService.getAll();
      this.setState({ data });
    } catch (ex) {
      console.log("programs ", ex);
    }
  };

  deleteById = async (id) => {
    try {
      await employeeService.deleteById(id);
      // this.getPagedData(this.state.page, this.state.rowsPerPage);
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false, formDialogOpen: false });
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    this.getPagedData(this.query());
  };

  query() {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { page, size, sort };
  }
  componentDidMount() {
    this.getPagedData(this.query());
    this.getPrograms();
    
  }

  getPagedData = async (query) => {
    try {
      const { programId, termId } = this.state;

      const { data, headers } = await studentService.getAllByQuery(programId, termId, { ...query });
      //console.log("employees data:", data);
      const totalElements = headers["x-total-elements"];
      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };
  onConfirmOk = async (id) => {
    await this.deleteById(id)
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    await this.getPagedData(this.query());
  };
  handleUpload = (item) => {
    this.setState({ formDialogOpen: true });
  };

  handleProgramChange = async (event) => {
    this.setState({ programId: event.target.value })
    const { data: terms } = await termDataService.getAllByProgram(event.target.value);
    this.setState({ terms });

  }


  handleTermChange = async (event) => {
    this.setState({ termId: event.target.value })
    await this.getPagedData({ ...this.query(), programId: this.state.programId, termId: event.target.value })
    //  this.getPagedData(this.query());
  }

  render() {
    const { sortColumn, data, programs, terms, dialogOpen, formDialogOpen, id, totalElements, rowsPerPage, page } = this.state;
    const { t, } = this.props;
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="program-id">{t("common:Select Program")}</InputLabel>
              <Select
                labelId="program-id"
                id="program"
                value={this.state.programId}
                onChange={this.handleProgramChange}
                label="Select Program"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {programs?.map(program => <MenuItem value={program.id}>{program.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="term-data-id">{t("common:Select Term")}</InputLabel>
              <Select
                labelId="term-data-id"
                id="term-data"
                value={this.state.termId}
                onChange={this.handleTermChange}
                label="Select Term"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {terms?.map(term => <MenuItem value={term.id}>{term.termLabel}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container  direction="row-reverse">
              <ButtonLink
                item={{ id: "", value: "" }}
                icon={"upload"}
                title={t("Upload Students")}
                onButtonClick={this.handleUpload}
              />
              &nbsp;&nbsp;
              <Add link={"/manage-students/new"} title={t("New Student")} />
            </Grid>
            {/* <Add onButtonClick={(event) => this.handleEdit({ id: "new" }, event)} icon="add_circle" title={t("Add Employee")} size="medium" /> */}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <StudentTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
            />
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}

        {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={"Student Details"}
            content={
              <StudentForm
                id={id ? id : "new"}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}

        {formDialogOpen && (
          <FormDialog
            id="bulkupload-dialog"
            keepMounted
            title={t("Upload Students..!")}
            content={<UploadStudentsForm onSave={this.closeDialog} />}
            open={formDialogOpen}
            onClose={() => this.setState({ formDialogOpen: false })}
          />
        )}

      </React.Fragment>
    );
  }
}

export default withTranslation(["student","common"])(Students);
