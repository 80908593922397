import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import enquiryDocsUploadService from "../../../services/enquiryDocsUploadService";
import enquiryDocumentConfigService from "../../../services/enquiryDocumentConfigService";
import logger from "../../../services/logger";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class UploadDocForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("uploadDocs");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  async getDocumentNames() {
    try {
      const { data } = await enquiryDocumentConfigService.getAllList();
      const dropDowns = this.state.dropDowns;
      dropDowns.documentNames = data;
      this.setState({ dropDowns });
      //console.log("dropdowns:", dropDowns);
      return;
    } catch (ex) {
      logger.error("error: ", ex);
    }
  }

  onSelectOf = async (item, value) => {
    
    switch (item) {
      case "instituteId":
        // await this.getPrograms(value);
        break;
    }
  };

  async populateForm() {
    const { id, userId, doc } = this.props; //this.props.match.params.id;
    const { data } = this.state;
    if (id === "new") {
      data.documentName = doc.documentName;
      this.setState({ data });
    }

    try {
      //   const { data } = await service.get(id);
      //   this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    await this.getDocumentNames();
    await this.populateForm();
  }

  doSubmit = async (id) => {
    // console.log("type:", this.state.selectedFiles[0]);
    try {
      if (id === "new") {
        // const file = this.state.selectedFiles[0];
        await enquiryDocsUploadService.create({
          ...this.state.data
        });
        // uploadFilesService.upload();
      } else {
        // await service.update(this.state.data);
      }
      this.props.onSave();
      // this.props.history.push("");
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = "new"; // this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          {/* <CardHeader title={t(titleText)} /> */}

          {this.renderForm(this.formJson, dropDowns)}

          <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["uploadDocs", "common"])(UploadDocForm);
