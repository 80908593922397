
import {Button, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InstituteBasics from '../institute/instituteBasics';
import BasicInfoTrustForm from './basicInfoTrustForm';
import BomComposition from './bomComposition';
import CommitteeMeetingsPage from '../committee/committee-meeting/committeeMeetings.page';


const SynizaTrust1 = () => {
    
    const [option, setOption] = useState(null)
    const [selected, setSelected] = useState("")
    useEffect(() => {   
        const urlParams = new URLSearchParams(window.location.search);
        const param = urlParams.get('tab');
        if(param)
            setSelected(param);
        else
            setSelected("basicinfo")
    }, []);
    // let option;
    function onClick(name) {
        console.log("name trust info:", name);
        setSelected(name);
        setOption(getItem(name));
    }

    function getItem(name) {
        switch (name) {
            case "basicinfo":
                return <BasicInfoTrustForm />
            case "composition":
                return <BomComposition />
            case "scheduleMeeting":
                return <CommitteeMeetingsPage committee={"BOM"} />
            case "addinstitute":
                return <InstituteBasics />
            default:
                break;
        }
    }

    function getTabs() {
        return [
            {
                name: "basicinfo", label: "Basic Info"
            },
            {
                name: "composition", label: "Composition"
            },
            {
                name: "scheduleMeeting", label: "BoM Meetings"
            },
            {
                name: "addinstitute", label: "Add Institutions"
            }
        ]
    }




    return (
        <>
            <Grid container spacing={15}>
                {getTabs().map(tab => <Grid item sm={12} md={3}>
                    {tab.name===selected ?<Button  className='tabs' color='default' fullWidth variant='contained'  onClick={() => onClick(tab.name)}> {tab.label} </Button>:<Button className='tabs' color='outlinedDefault' fullWidth   variant='outlined'  onClick={() => onClick(tab.name)}> {tab.label} </Button>}
                </Grid>)}

            </Grid>
            <br />
            <Divider />
            <br />
            <Grid container item sm={12}>
                {getItem(selected)}
            </Grid>
        </>
    );
}

export default SynizaTrust1;