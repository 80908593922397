import React from "react";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { formatToDate, formatToTime } from "../../common/dateUtil";

const CommitteMeetingsTable = ({ data, sortColumn, onSort, onDelete,onEdit,onAttendanceClick,onAgendaClick,onResolutionClick,onUploadMoMClick,t,paginate,pagination}) => {
  const columns = [
    { path: "subject", label: t("Title")},
    { path: "date", label: t("Date"),content:(item)=>formatToDate(item.date) },
    { path: "time", label: t("Time"),content:(item)=>formatToTime(item.time) },
    { path: "venue", label: t("Venue")},
    { path: "status", label: t("Status")},
    {
      key: "attendees",
      label:"Attendees",
      align:"center",
      content: (item) => {
        return <><Edit item={item} onEdit={onAttendanceClick} icon="group" title="Attendees" iconOnly /></>;
      },
    },
    {
      key: "agenda",
      label:"Agendas",
      align:"center",
      content: (item) => {
        return <><Edit item={item} onEdit={onAgendaClick} icon="view_agenda" title="Agendas" iconOnly /> </>;
      },
    },
    {
      key: "resolution",
      label:"Resolutions",
      align:"center",
      content: (item) => {
        return <><Edit item={item} onEdit={onResolutionClick} icon="add_task" title="Action Items" iconOnly /></>;
      },
    },
    {
      key: "uploadMom",
      label:"Upload MoM Copy",
      align:"center",
      content: (item) => {
        return <><Edit item={item} onEdit={onUploadMoMClick} icon="upload_file" title="Upload MoM Copy" iconOnly /></>;
      },
    },
    {
      key: "action",
      content: (item) => {
        return <><Edit item={item} onEdit={onEdit} iconOnly  /> &nbsp; <Delete onDelete={onDelete} item={item} iconOnly  /></>;
      },
    }
  ];

  return (
    <MatTable
      paginate={paginate}
      data={data}
      heading={t("List of Meetings")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      pagination={pagination}
      

    />
  );
};

export default CommitteMeetingsTable;
