import React from "react";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import ButtonLink from "../../common/buttonLink";

const StopsTable = ({ data, sortColumn, onSort, onDelete,onEdit,onNumOfStopsClick,t}) => {
  const columns = [
    { path: "stopNum", label: t("Stop #"),
    content:(item)=>{
      return <>Stop {item.stopNum} </>
    }
  },
    { path: "stopName", label: t("Stop Name") },
    { path: "feeAmount", label: t("Fee Amount") },
    {
      key: "action",
      content: (item) => {
        return <><Edit item={item} onEdit={onEdit} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Stops")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default StopsTable;
