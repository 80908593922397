import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { ADMISSION_ENQUIRY_TARGET_ROUTE } from "../../../data/routerConstants";
import programService from "../../../services/programService";
import enquiryTargetService from "../../../services/enquiryTargetService";
import logger from "../../../services/logger";
import specializationService from "../../../services/specializationService";
import BackButton from "../../common/backButton";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class EnquiryTargetForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("enquiryTarget");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getPrograms = async () => {
    //console.log("instituteId :");
    try {
      const { data } = await programService.getAll();

      const dropDowns = this.state.dropDowns;
      dropDowns.programs = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };

  getSpecializationsByCourse = async (degreeId) => {
    try {
      const { data } = await specializationService.getSpecializationsByDegree(
        degreeId
      );
      const dropDowns = this.state.dropDowns;
      dropDowns.departments = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("specializations ", ex);
    }
  };

  onSelectOf = (item, value) => {
    switch (item) {
      case "degreeId":
        //console.log("switch selected: ", value);
        this.getSpecializationsByCourse(value);
        break;
    }
  };

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await enquiryTargetService.create(this.state.data);
      } else {
        await enquiryTargetService.update(this.state.data);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await enquiryTargetService.get(id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    await this.populateForm();
    await this.getPrograms();
    const { data } = this.state;

    data.degreeId && (await this.getSpecializationsByCourse(data.degreeId));
  }

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = this.formJson.alias;

    return (
      <Card>
        <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} />

        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["enquiryTarget","program", "common"])(EnquiryTargetForm);
