import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/users";
const apiEndpoint = "/api/users";

const addresses = "addresses";

function create(data, userId) {
  const address = { ...data, userId: userId };
  return http.post(`${apiEndpoint}/${userId}/${addresses}`, address);
}

function update(data, userId) {
  const address = { ...data, userId: userId };
  return http.put(`${apiEndpoint}/${userId}/${addresses}`, address);
}

function get(id, userId) {
  return http.get(`${apiEndpoint}/${userId}/${addresses}/${id}`);
}

function getByUserId(userId) {
  return http.get(`${apiEndpoint}/${userId}/${addresses}`);
}
function getAll() {
  return http.get(apiEndpoint);
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getByUserId,
  getAll,
  deleteById,
};
