import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getStateObject,
} from "../../common/formUtils";

import json from "../../../data/forms/admissionOffer.json"
import admissionFeeDetailService from "../../../services/admissionFeeDetailService";

class AdmissionOffer extends Form {
  constructor() {
    super();
    this.formJson = json;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
  }

  async populateForm() {
    const user = this.props.user;
    try {
      const { data:enquiryData } = await enquiryService.getEnquiryByUserIdIfApproved(user.id);
      //console.log("user enquirydata:", enquiryData);
      this.setState({enquiryData})
      if (enquiryData && enquiryData.applicationStatus==="APPROVED") {
        this.setState({ verified: true })
        this.mapToViewModel(enquiryData);
        const {data:feeData}=await admissionFeeDetailService.getByProgram(enquiryData.programId);
        this.mapToViewModel({...this.state.data,totalAmount:feeData.totalFeeAmount})
      }
      else {
        this.setState({ verified: false })
      }
    } catch (ex) {
     
    }
  }

  mapToViewModel(obj) {
    this.setState({ data: getStateObject(this.formJson.groups, obj) });
  }

  async componentDidMount() {
    await this.populateForm();

  }

  raiseBack = () => {
    this.props.onBack();
  };

  doSubmit = async (id) => {
    //console.log("user id:", id);
    try {
      //update method
      await enquiryService.submit(id);
      this.props.onNext();
    } catch (ex) {
      logger.log(ex);
    }
  };

  handleClick=async (label)=>{
    //console.log("label:",label);
    const {data}=this.state;
    switch (label.toLowerCase()) {
      case "accept":
   const {data:enquiry}=  await enquiryService.acceptOffer(data.id);   
         this.props.onNext(enquiry.stage);     
        break;    
      case "decline":
        enquiryService.declineOffer(data.id);
        // this.props.history.goBack();
        break;
    }
  }

  renderOffer() {
    const { t, user } = this.props;
    const { dropDowns, data, verified,enquiryData } = this.state;

    const buttonText = getFormButtonText(user.id);

    const titleText = getFormTitleText(user.id, this.formJson.alias);
   
    if (data.applicationStatus==="APPROVED")
      return (
        <Card>
          <CardHeader title={data.offerAccepted?t("Offer Accepted"):"Application Approved"} ></CardHeader>
          <form onSubmit={(event) => this.handleSubmit(event, user.id)}>
            {this.renderView(this.formJson)}
            {(verified && !data.offerAccepted) &&
              <CardContent>
                <Grid container item md={12} justifyContent="center">
                  {this.renderMatButton(t("Accept"),"button")}
                  {/* {this.renderMatButton(t("Decline"),"button")} */}
                  {/* {this.renderMatButton(t("common:Reset"), "reset", "secondary")} */}
                </Grid>
              </CardContent>}
          </form>
        </Card>
      );
    else {
      return( 
      <Grid container>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
        <Card>
        <CardHeader title={enquiryData?.applicationStatus==="SUBMITTED"?"Verification Pending":"Application Rejected"} />
        <CardContent>
         {enquiryData?.applicationStatus==="SUBMITTED" ? <h3>
            Dear {enquiryData.userName},<br />
            Your application is under process for the verification. <br />
            We will notify you once the application is verfied and approved. <br />
            Thank you.
          </h3>:
          <h3>
             Dear {enquiryData?.userNAme},<br />
             We are sorry to inform you that,Your application is rejected.<br />
            Thank you.
          </h3>
          }
        </CardContent>
      </Card>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>)
    }
  }

  render() {
    return (
      this.renderOffer()
    );
  }
}

export default withTranslation(["personalInfo", "common"])(
  AdmissionOffer
);
