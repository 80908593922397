import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ADMISSION_ENQUIRY_ROUTE } from "../../../data/routerConstants";
import authService from "../../../services/authService";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import {formatToDate, formatToDateAndTime} from "../../common/dateUtil"
const EnquiriesTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiries", "common"]);
  const columns = [
    {
      path: "date",
      label: t("Date"),
      sort: true,
      content: (item) => {  
        return formatToDate(item.date)
      }
    },
    {
      path: "userName",
      label: t("Name"),
      content: (item) => {
        return (
          <Link  to={`${ADMISSION_ENQUIRY_ROUTE}/${item.id}`}>
            {item.userName}
          </Link>
        );
      },
    },
   
    // { path: "userName", label: t("Name") },
    { path: "userMobile", label: t("Mobile #") },
    { path: "programName", label: t("common:Program") },
    { path: "gender", label: t("Gender"), sort:true },
    // { path: "source", label: t("Source") },
    { path: "enquiryType", label: t("Type"), sort:true,
    content:(item)=>{
      return item.enquiryType!=="NA"?item.enquiryType:''
    }
  },
    { 
      path: "lastFollowedUpDate", 
      label: t("LFD"),
      sort: true,
      helperText:t("Last Followup Date"),
      content: (item) => {
        return formatToDate(item.lastFollowedUpDate)
      }
     
     },
    { 
      path: "nextFollowUpDate", 
      label: t("NFD"), 
      sort: true, 
      helperText:t("Next Followup Date"),
      content: (item) => {
        return formatToDate(item.nextFollowUpDate)
      }
    },
    // {
    //   path: "enquiryNumber",
    //   label: t("Buy Application"),
  
    //   content: (item) => {
    //     return (
    //       <Edit  link={`${ADMISSION_ENQUIRY_ROUTE}/${item.id}?q=b`} icon="currency_rupee"  title={t("Buy Now")} />
          
    //     );
    //   },
    // },
    {
      key: "action",
      content: (item) => {
        // if (authService.getCurrentUser().id == item.counsellorId || authService.getCurrentRole() === "ADMISSION_MANAGER")
          return (
            <><Edit link={`${ADMISSION_ENQUIRY_ROUTE}/${item.id}?q=b`}  title={t("Apply")} />
            &nbsp;&nbsp;
            <Edit
              title={t("Followup")}
              link={"manage-enquiries/" +
                item.id +
                "/enquiry-followups/?item=" +
                item}
              item
              icon={"sync_alt"} /></>
          );
      },
    },
  ];

  return (
    <MatTable
      data={data}
      heading="List of Enquiries"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EnquiriesTable;
