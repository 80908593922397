import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const useStyles = makeStyles((theme) => ({
  dashboardCard:{
    height:"100%",
    [theme.breakpoints.up('md')]: {
     height:"325px"
    }
  }
}))

function getText(key, data) {
  return (
    <ListItem button>
      <ListItemText 
        primary={
          <div style={{ margin: 0 }}>
            <span> {key}</span>
            <div style={{ fontSize: 16, float: "right" }}>
              <strong>{data}</strong>
            </div>
          </div>
        }
      />
    </ListItem>
  );
}
const ApplicationStatsCard = ({ data,onClick }) => {
  const classes=useStyles()
  return (
    <React.Fragment>
      <Card className={classes.dashboardCard}
      //  onClick={()=>onClick("/manage-applications/applicants")}
       >
        <CardHeader title="Applications"></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <List component="nav" aria-label="mailbox folders">
                {getText("Today ", data.todayApplicationCount)}
                <Divider />
                {getText("Cumulative  ", data.totalApplicationCount)}
                {/* <Divider /> */}
                {/* {getText("Days  ", data.days)} */}
                <Divider />
                {getText("Percentage  ", data.applicationPercentage + "%")}
                <Divider />
                {getText("Admissions  ", data.totalAdmissionCount)}
                <Divider />
                {getText("Conversion  ", data.admissionPercentage + "%")}
                <Divider />
              </List>
            </Grid>
            <Divider />
            <Grid container item xs={12} md={6} alignItems={"center"} direction="row">
              <div style={{margin:"auto"}}>
              <CircularProgressbarWithChildren value={data.applicationPercentage}  >
                <div style={{ marginTop: -5, textAlign: "center" }}>
                  <h2 style={{ margin: 0 }}>Applications</h2>
                  <span>
                    {data.totalApplicationCount}/{data.enquiryCount} Enquiries
                  </span>
                </div>
              </CircularProgressbarWithChildren>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default ApplicationStatsCard;
