import authService from "./authService";
import http from "./httpService";
// const apiEndpoint = "/hedge-brown-server/api/admission-enquiries";
const apiEndpoint = "/api/admission-enquiries";
function register(admissionEnquiry) {
  const enquiry = {
    userName: admissionEnquiry.name,
    userEmail: admissionEnquiry.email,
    userMobile: admissionEnquiry.mobile,
    userLogin: admissionEnquiry.email,
    country: admissionEnquiry.country,
    state: admissionEnquiry.state,
    city: admissionEnquiry.city,
    instituteId: admissionEnquiry.institute,
    instituteType:authService.getInstituteType(),
    // degreeId: admissionEnquiry.course,
    // programId: admissionEnquiry.specialization,
    programId: admissionEnquiry.program,
    termId:admissionEnquiry.termId,
    source: admissionEnquiry.source,
    tcAccepted: admissionEnquiry.tcAccepted,
    counsellorId: authService.getCurrentUser()?.id,
  };

  return http.post(apiEndpoint, enquiry);
}

function create(data) {
  return http.post(apiEndpoint, {...data,instituteType:authService.getInstituteType()});
}
function createByCounsellor(data) {
  return http.post(`${apiEndpoint}/counsellor`, {...data,instituteType:authService.getInstituteType()});
}

function updateByCounsellor(data) {
  return http.put(`${apiEndpoint}/counsellor`, {...data,instituteType:authService.getInstituteType()});
}

function update(data) {
  return http.put(apiEndpoint, {...data,instituteType:authService.getInstituteType()});
}

function allocateCounsellor(data) {
  return http.put(`${apiEndpoint}/allocate`, data);
}

function apply(data) {
  return http.put(`${apiEndpoint}/apply`, data);
}

function paymentOrder(data) {
  return http.put(`${apiEndpoint}/pay`, data);
}

function createPaymentOrder(data, pay) {

  if (pay === "ADMISSION_FEE")
    return http.post(`${apiEndpoint}/admission-fee/payment-order`, data);
  else
    return http.post(`${apiEndpoint}/application-fee/payment-order`, data);
}

function verifyPayment(data) {

    return http.post(`${apiEndpoint}/application-fee/verify-payment`, data);

}

function verifyAdmissionFeePayment(data) {

    return http.post(`${apiEndpoint}/admission-fee/verify-payment`, data);
  

}

function submit(id) {
  return http.put(`${apiEndpoint}/submit/${id}`, {});
}
function approve(id) {
  return http.put(`${apiEndpoint}/approve/${id}`, {});
}
function reject(id) {
  return http.put(`${apiEndpoint}/reject/${id}`, {});
}
function acceptOffer(id) {
  return http.put(`${apiEndpoint}/accept-offer/${id}`, {});
}
function declineOffer(id) {
  return http.put(`${apiEndpoint}/decline-offer/${id}`, {});
}
function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

function getEnquiryWithApplicationFee(id) {
  return http.get(`${apiEndpoint}/application-fee/${id}`)
}

function getEnquiryWithAadmissionFee(id) {
  return http.get(`${apiEndpoint}/admission-fee/${id}`)
}

function getEnquiriesByUserId(userId) {
  return http.get(`${apiEndpoint}/user/list/${userId}`);
}

function getEnquiryByUserId(userId) {
  return http.get(`${apiEndpoint}/user/${userId}`);
}

function getEnquiryByUserIdIfApplicant(userId) {
  return http.get(`${apiEndpoint}/applicant/${userId}`);
}
function getEnquiryByUserIdIfApproved(userId) {
  return http.get(`${apiEndpoint}/approved/${userId}`);
}

function getAll() {
  return http.get(apiEndpoint);
}
function getStats() {
  return http.get(apiEndpoint + "/stats");
}

function getEnquiryStatsByProgram(type) {
  return http.get(apiEndpoint + "/stats/program",{params:{'type':type}});
}

function getAllByQuery(query) {
  if (query.allocatedTo === "ME")
    query = { ...query, allocatedTo: authService.getCurrentUser().id };
  else delete query.allocatedTo;
  return http.get(`${apiEndpoint}/q`, { params: { ...query } });
}

function getApplicantsByQuery(query) {
  if (query.allocatedTo === "ME")
    query = { ...query, allocatedTo: authService.getCurrentUser().id };
  else delete query.allocatedTo;
  return http.get(`${apiEndpoint}/applicants/q`, { params: { ...query } });
}



function getAdmissionsByQuery(query) {
  if (query.allocatedTo === "ME")
    query = { ...query, allocatedTo: authService.getCurrentUser().id };
  else delete query.allocatedTo;
  return http.get(`${apiEndpoint}/admissions/q`, { params: { ...query } });
}

function getAllByEnquiryStatus(query) {
  return http.get(`${apiEndpoint}/status`, { params: { ...query } });
}

function getEnquiryByUserIdAndStatus(userId,status) {
//console.log("status:",status);
  return http.get(`${apiEndpoint}/status/${userId}`, { params: { status } });
}

function getWaitingEnquiries() {
  return http.get(`${apiEndpoint}/waiting`);
}

function downloadTemplate() {
  return http.get(`${apiEndpoint}/download-template`,{responseType:"blob"});
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

function admissionFeePaymentByAdmissionManager(data){
  return http.post(`${apiEndpoint}/admission-fee-payment`, data);

}
function offlineAdmissionFeePayment(id,data){
  return http.post(`${apiEndpoint}/${id}/offline-admission-fee-payment`, data);
}


function studentAdmissionFeeDetails(query){
  return http.get(`${apiEndpoint}/student-admission-fee-details`,{ params: { ...query } });

}

export default {
  register,
  create,
  createByCounsellor,
  updateByCounsellor,
  update,
  allocateCounsellor,
  apply,
  paymentOrder,
  createPaymentOrder,
  verifyPayment,
  verifyAdmissionFeePayment,
  submit,
  approve,
  reject,
  acceptOffer,
  declineOffer,
  get,
  getByUserId: getEnquiriesByUserId,
  getEnquiryByUserId,
  getEnquiryByUserIdIfApplicant,
  getEnquiryByUserIdIfApproved,
  getAll,
  getStats,
  getEnquiryStatsByProgram,
  getAllByQuery,
  getAllByEnquiryStatus,
  getEnquiryByUserIdAndStatus,
  getWaitingEnquiries,
  getEnquiryWithApplicationFee,
  getEnquiryWithAadmissionFee,
  getApplicantsByQuery,
  getAdmissionsByQuery,
  downloadTemplate,
  deleteById,
  admissionFeePaymentByAdmissionManager,
  offlineAdmissionFeePayment,
  studentAdmissionFeeDetails
};
