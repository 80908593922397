function init() {}

function log(msg) {
  console.log(msg);
}

function error(...error) {
  console.error(...error);
}

function info(msg) {
  console.info(msg);
}

function warn(msg) {
  console.warn(error);
}

export default {
  init,
  log,
  error,
  info,
  warn,
};
