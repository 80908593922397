import http from "./httpService";

// const apiEndpoint = "/icamel/api";
const apiEndpoint = "/api";

const config = {
  transformRequest: [
    function (data, headers) {
      // Do whatever you want to transform the data
      delete headers.common.Authorization;
      // delete headers.common["X-tenant-id"];

      return data;
    },
  ],
};
function getInstitutes() {
  const tenantId =
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_API_BASE_URL.split("/")[1].split(".")[0]
      : "default";
  // http.deleteHeader("Authorization");

  return http.get(`${apiEndpoint}/institutes`, config);
}

function getCourses() {
  return http.get(`${apiEndpoint}/courses`);
}
function getSpecializationsByCourse(courseId) {
  return http.get(`${apiEndpoint}/specializations/course/${courseId}`);
}

function getBoardOrUniversities() {
  return http.get(`${apiEndpoint}/board-or-universities`);
}

function getCountries() {
  // http.deleteHeader("Authorization");
  return http.get(`${apiEndpoint}/countries`, config);
}

function getStatesByCountry(countryCode) {
  // http.deleteHeader("Authorization");

  return http.get(`${apiEndpoint}/states/list/${countryCode}`, config);
}

function getCitiesByState(stateCode) {
  // http.deleteHeader("Authorization");

  return http.get(`${apiEndpoint}/cities/list/${stateCode}`, config);
}

export default {
  getBoardOrUniversities,
  getCourses: getCourses,
  getCourses,
  getSpecializationsByCourse,
  getCountries,
  getStatesByCountry,
  getCitiesByState,
  getInstitutes,
};
