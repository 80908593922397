import React, { Component } from 'react';
import committeeMemberService from '../../services/committeeMemberService';
import committeeService from '../../services/committeeService';
import CommitteeMembers from '../committee/committee-member/committeeMembers';
import { withTranslation } from 'react-i18next';

class BomComposition extends Component {
    state = {}

    async getCommitte() {
        try{

            const { data: committee } = await committeeService.getByName("BOM");
            this.setState({ committee });
        }catch(error){
            console.log("error:",error);
        }
    }

    async getAllByCommittee(committeeId) {
        try{

            const { data: members } = await committeeMemberService.getAllByCommittee(this.state.committee.id)
            this.setState({ members })
        }catch(error){
            console.log("error:",error);
        }
    }

    handleMemberAdd = () => {
        this.getAllByCommittee(this.state.committee.id);
    }

    async componentDidMount() {
        await this.getCommitte();
        await this.getAllByCommittee(this.state.committee.id)
    }

    render() {
        const { committee, members } = this.state;
        const {t}=this.props;
        return (
            <>
            {members &&  <CommitteeMembers heading={t("Composition of the Trust")}  bom={true} onMemberAdd={this.handleMemberAdd} committee={committee} members={members}/>} 
            
            </>
        );
    }
}

export default withTranslation(["bom"])(BomComposition);