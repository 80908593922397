import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/dept-or-prgms";
const apiEndpoint = "/api/programs";


function create(specializtion) {
  const instituteId=authService.getInstituteTenant()
  const data={...specializtion,instituteId}
  return http.post(apiEndpoint, data);
}

function update(specializtion) {
  const instituteId=authService.getInstituteTenant()
  const data={...specializtion,instituteId}
  return http.put(apiEndpoint, specializtion);
}

function get(specializtionId) {
  return http.get(`${apiEndpoint}/${specializtionId}`);
}

function getAll() {
  return http.get(apiEndpoint);
}


function deleteById(specializtionId) {
  return http.delete(`${apiEndpoint}/${specializtionId}`);
}

export default {
  create,
  update,
  get,
  getAll,
  deleteById
};
