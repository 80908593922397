import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { toUpper } from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import authService from "../../services/authService";
import enquiryService from "../../services/enquiryService";
import AdmissionEnrollment from "./admission-enrollment/AdmissionEnrollment";
import AdmissionOffer from "./admission-offer/admissionOffer";
import ApplicantEducationDetails from "./applicant-education-details/applicantEducationDetails";
import ApplicantDetails from "./applicantDetails";
import EnquiryBasicInfoForm from "./enquiryBasicInfoForm";
import AdmissionFeePaymentAndReceipt from "./payment-and-receipt/admissionFeePaymentAndReceipt";
import PaymentAndReciept from "./payment-and-receipt/applicationFeePaymentAndReceipt";
//   import UploadDocs from "./upload-docs/uploadDocs";
const useStyles = (theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    // marginRight: theme.spacing(1),
  },
  instructions: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  tabBorder: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.primary.main,
  },
  headerBorder: {
    borderBottomStyle: "solid",
    borderWidth: "1px",
    borderBottomColor: theme.palette.primary.main,
  },
});



class EnquiryTimelineCopy extends Component {
  constructor() {
    super()
    this.state = {
      data: {},
      activeStep: 0,
      currentStep: 0,
      steps: [],
      completedSteps: []
    }
  }

  async componentDidMount() {
    await this.getStepCount()
    this.getSteps();
  }

  getSteps() {
    const steps = [
      "Enquiry",
      "Payment & Reciept",
      "Applicant",
      "Offer",
      "Payment & Reciept",
      "Enroll"
    ];
    this.setState({ steps })
  }

  async getStepCount() {
    const { data } = await enquiryService.getEnquiryByUserId(authService.getCurrentUser().id);
    this.setStepCompleted(data.stage);
    this.setState({ activeStep: data.stage, currentStep: data.stage, data })
  };

  setStepCompleted(step) {
    const completedSteps = step > 0 ? [...Array(step).keys()] : []
    //console.log("set step :", step);
    this.setState({ completedSteps });
  }

  isStepCompleted(index) {
    const { completedSteps } = this.state;
    //console.log("step index:", index, "-", completedSteps.findIndex(i => i == index));

    return completedSteps.findIndex(i => i === index) === -1 ? false : true
  }
  handleChange = (event, newValue) => {
    event.preventDefault();
  };

  handleNext = (nextStep) => {
    let { activeStep, currentStep } = this.state;
    if (nextStep >= 0) {
      currentStep = nextStep;
    }
    activeStep += 1;
    //console.log("currentStep=======:", nextStep, "-aciveStep:", activeStep);
    if (activeStep > currentStep) {
      alert("your not allowed to continue until you finsh the current step")
    } else {
      this.setStepCompleted(currentStep);
      this.setState({ activeStep, currentStep });
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 })
  };

  handlePreview = () => {
    //console.log("in preview");
  };

  handleReset = () => {
    // setActiveStep(0);
  };

  getStepContent(stepIndex) {
    const { activeStep, steps, currentStep, data } = this.state
    switch (stepIndex) {
      case 0:
        return (
          <EnquiryBasicInfoForm
            data={data}
            activeStep={activeStep}
            stepIndex={stepIndex}
            currentStep={currentStep}
            stepsLength={steps.length}
            onBack={this.handleBack}
            onNext={this.handleNext}
            user={authService.getCurrentUser()}
          />
        );
      case 1:
        return (
          <PaymentAndReciept
            activeStep={activeStep}
            stepsLength={steps.length}
            onBack={this.handleBack}
            onNext={this.handleNext}
            user={authService.getCurrentUser()}
          />);
      case 2:
        return (
          <ApplicantDetails
            activeStep={activeStep}
            stepsLength={steps.length}
            onBack={this.handleBack}
            onNext={this.handleNext}
            user={authService.getCurrentUser()}
          />);

      case 3:
        return (
          <AdmissionOffer
            onBack={this.handleBack}
            onNext={this.handleNext}
            user={authService.getCurrentUser()} />
        )
      case 4:
        return (
          <AdmissionFeePaymentAndReceipt
            activeStep={activeStep}
            stepsLength={steps.length}
            onBack={this.handleBack}
            onNext={this.handleNext}
            user={authService.getCurrentUser()}
          />
        )
      case 5:
        return <AdmissionEnrollment
            activeStep={activeStep}
            stepsLength={steps.length}
            onBack={this.handleBack}
            onNext={this.handleNext}
            user={authService.getCurrentUser()}
        />
      case 6:
        return <h2>Upload Documents</h2>;
      default:
        return "Unknown stepIndex";
    }
  }

  render() {
    const { t, useStyles } = this.props;
    const { activeStep, steps, completedSteps } = this.state;
    return (
      <div style={{ border: "2px solid #472f2a" }}>
        {activeStep === steps.length ? (
          <div>
            <Typography >Preview</Typography>
          </div>
        ) : (
          <div>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={index} completed={this.isStepCompleted(index)} >
                  <StepLabel>{label}</StepLabel>
                </Step>

              ))}
            </Stepper>
            <h2 style={{ textAlign: "center" }}>
              {toUpper(steps[activeStep])}
            </h2>
            <p style={{ marginLeft: "10px", marginRight: "10px" }}>
              {this.getStepContent(activeStep)}
            </p>
            <div>
              <Card>
                <CardHeader>
                  &nbsp;
                </CardHeader>
                <CardContent>
                  <Grid item container md={12} direction="row-reverse">
                    &nbsp;&nbsp;
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      disabled={activeStep === steps.length - 1}
                    >
                      {t("Next")}
                    </Button>
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    <Button disabled={activeStep === 0} onClick={this.handleBack} variant="contained">
                      Previous
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["personalInfo", "common"])(EnquiryTimelineCopy);


