
import { Button, Divider, Grid, GridList } from '@mui/material';
import React, { useState } from 'react';
import GroupForm from '../goup/groupForm';
import InstituteBasicForm from '../institute/instituteBasicForm';
import InstituteBasics from '../institute/instituteBasics';
import BasicInfoTrustForm from './basicInfoTrustForm';
import BomComposition from './bomComposition';
import IQACComposition from './iqacComposition';
import Committees from '../committee/committees';


const SynizaIQAC = () => {
    const [option, setOption] = useState(null)
    const [selected, setSelected] = useState("composition")
    // let option;
    function onClick(name) {
        console.log("name trust info:", name);
        setSelected(name);
        setOption(getItem(name));
    }

    function getItem(name) {
        switch (name) {
        
            case "composition":
                return <IQACComposition />
            case "committees":
                return <Committees />
            default:
                break;
        }
    }

    function getTabs() {
        return [
           
            {
                name: "composition", label: "Composition"
            },
            {
                name: "committees", label: "Committees"
            }
        ]
    }


    return (
        <>
            <Grid container>
                {getTabs().map(tab => <Grid item alignItems="center" sm={4}>
                    <Button variant='contained' style={{ textAlign: "center" }} color={tab.name === selected ? 'secondary' : 'default'} onClick={() => onClick(tab.name)}> {tab.label} </Button>
                </Grid>)}

            </Grid>
            <br />
            <Divider />
            <br />
            <Grid container item sm={12}>
                {getItem(selected)}
            </Grid>
        </>
    );
}

export default SynizaIQAC;