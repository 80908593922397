import React, { Component } from 'react';
import Strophe from 'react-strophe';

class XmppClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connection: null,
      messages: [],
      messageInput: ''
    };
  }

  componentDidMount() {
    const Strophe1 = Strophe.Strophe;
    // const xmppConnection = new Strophe1.Connection('http://localhost:7444/http-bind');
    const xmppConnection = new Strophe1.Connection('ws://localhost:5222/xmpp-websocket');


    xmppConnection.connect('poornima@localhost', '123', (status) => {
      if (status === Strophe1.Status.CONNECTED) {
        this.setState({ connection: xmppConnection });

        // Set up message handler
        xmppConnection.addHandler(this.handleIncomingMessage, null, 'message', null, null, null);
        
        console.log('Connected to XMPP server');
      }
    });

    this.xmppConnection = xmppConnection;
    this.setOnlinePresence();
  }

  componentWillUnmount() {
    if (this.xmppConnection) {
      this.xmppConnection.disconnect();
    }
  }

  setOnlinePresence = () => {
    const { connection } = this.state;
    if (connection) {
      const presenceStanza = new Strophe.Strophe.Builder('presence');
      connection.send(presenceStanza);
    }
  };
  

  handleIncomingMessage = messageStanza => {
    console.log("handleIncomingMessage");
    const from = messageStanza.getAttribute('from');
    console.log("handleIncomingMessage:",from);

    const text = Strophe.Strophe.getText(messageStanza.getElementsByTagName('body')[0]);

    const newMessage = `${from}: ${text}`;
    this.setState(prevState => ({
      messages: [...prevState.messages, newMessage]
    }));

    return true; // Keep the handler active for more messages
  };

  sendMessage = () => {
    const { connection, messageInput } = this.state;
    if (connection) {
      const messageStanza = new Strophe.Strophe.Builder('message', {
        to: 'shree@localhost',
        type: 'chat'
      }).c('body').t(messageInput);
      connection.send(messageStanza);

      const newMessage = `You: ${messageInput}`;
      this.setState(prevState => ({
        messages: [...prevState.messages, newMessage],
        messageInput: ''
      }));
    }
  };

  handleMessageInputChange = event => {
    this.setState({ messageInput: event.target.value });
  };

  render() {
    const { messages, messageInput } = this.state;

    return (
      <div>
        <h1>XMPP React Client</h1>
        <div style={{ border: '1px solid #ccc', padding: '10px', minHeight: '200px' }}>
          {messages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
        <input
          type="text"
          value={messageInput}
          onChange={this.handleMessageInputChange}
          placeholder="Type your message"
        />
        <button onClick={this.sendMessage}>Send</button>
      </div>
    );
  }
}

export default XmppClient;
