import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MatAccordion = ({heading,content}) => {
          return (
                <Accordion >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id={"accordion"}
                    expandIcon={<ExpandMoreIcon />}
                  >
                  {heading}
                  </AccordionSummary>
                  <AccordionDetails>
                    {content}
                  </AccordionDetails>
                </Accordion>
          )
      
}
 
export default MatAccordion;