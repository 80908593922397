import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import PagingAndSorting from "../../common/pagingAndSorting";
import FormDialog from "../../common/formDialog";

import Add from "../../common/add";
import eventsCalendarService from "../../../services/eventsCalendarService";
import EventsCalendarTable from "./eventsCalendarTable";
import EventsCalendarForm from "./eventsCalendarForm";
import Calendar from "./calendar";

class EventsCalendars extends PagingAndSorting {
  state = {
    data:[],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
  };

  getAll = async () => {
    try {
      const { data } = await eventsCalendarService.getAll();
      // const { data } = await ticketService.getAll();

      this.setState({ data });
    } catch (ex) {
      console.log("tickets ", ex);
    }
  };

  deleteById = async (id) => {
    try {
      await eventsCalendarService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog = () => {  
    this.setState({ dialogOpen: false });
    this.getAll();
  };

  componentDidMount() {
    this.getAll();
  }

  onConfirmOk = (id) => {
    this.deleteById(id);
  };


   handleDateSelect = (date) => {
    // setSelectedDate(date);
  };

  render() {
    const { sortColumn, data,dialogOpen,id } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            direction="row-reverse"
          >
            <Add title={t("New Event")} link={"/events-calendars/new"}  />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <Calendar events={data}></Calendar> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EventsCalendarTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}

        {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={"Raise the Ticket"}
            content={
              <EventsCalendarForm
                id={id ? id : "new"}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}  

      </React.Fragment>
    );
  }
}

export default withTranslation(["tickets"])(EventsCalendars);
