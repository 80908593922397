import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@mui/material";
import classnames from "classnames";
import Joi from "joi";
import React from "react";
import { withTranslation } from "react-i18next";
import auth from "../../../services/authService";
import logger from "../../../services/logger";
import Form from "../../common/form";
class ResetPasswordForm extends Form {
  state = {
    data: { password: "" },
    errors: {},
  };

  schema = {
    password: Joi.string().required().label("Password"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const key = this.props.match.params.key;
      await auth.resetPassword(data.password, key);

      // const { state } = this.props.location;
      //console.log("location: ", this.props.location);
      window.location = "/";
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div style={{marginTop:"100px"}}>
        <Grid container>
          <Grid item md={4}></Grid>

          <Grid item md={4}>
            <Card variant="outlined">
              <CardHeader
                title={t("Reset Your Password Here")}
                className={classnames(classes.textColorPrimary)}
              />
              <Divider />
              <CardContent>
                <form onSubmit={this.handleSubmit}>
                  <Grid item>
                    {this.renderMatInput(
                      {},
                      "password",
                      t("New Password"),
                      "password",
                      "outlined",
                      "medium"
                    )}
                  </Grid>

                  <Grid item>
                    {this.renderMatButton(
                      t("Submit"),
                      "submit",
                      "secondary",
                      true
                    )}
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation(["common"])(ResetPasswordForm);
