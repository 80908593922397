import { HEDGE_BROWN_SERVER } from "../data/endpointConstants";
import http from "./httpService";
const instituteEndpoint = "/institutes";

function createInstitute(institute) {
  return http.post(`${HEDGE_BROWN_SERVER}${instituteEndpoint}`, institute);
}

function updateInstitute(institute) {
  return http.put(`${HEDGE_BROWN_SERVER}${instituteEndpoint}`, institute);
}

function getInstitute(instituteId) {
  return http.get(`${HEDGE_BROWN_SERVER}${instituteEndpoint}/${instituteId}`);
}

function getInstituteOrTrust(instituteId) {
  return http.get(`${HEDGE_BROWN_SERVER}${instituteEndpoint}/institute-trust/${instituteId}`);
}

function getInstitutes() {
  return http.get(`${HEDGE_BROWN_SERVER}${instituteEndpoint}`);
}

function getInstitutesByUniversity(universityId) {
  return http.get(
    `${HEDGE_BROWN_SERVER}${instituteEndpoint}/university/${universityId}`
  );
}

function getProgramsByInstitute(instituteId) {
  return http.get(
    `${HEDGE_BROWN_SERVER}${instituteEndpoint}/${instituteId}/degree-or-equivalents`
  );
}

function deleteById(instituteId) {
  return http.delete(
    `${HEDGE_BROWN_SERVER}${instituteEndpoint}/${instituteId}`
  );
}

export default {
  createInstitute,
  updateInstitute,
  getInstitute,
  getInstituteOrTrust,
  getInstitutes,
  getInstitutesByUniversity,
  deleteById,
  getProgramsByInstitute,
};
