import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import employeeService from "../../../services/employeeService";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import userService from "../../../services/userService";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import EnquiryAllocationsTable from "./enquiryAllocationsTable";

class EnquiryAllocations extends PagingAndSorting {
  state = {
    data: [],
    selectedChip: "ALL",
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    confirmDialogOpen: false,
  };

  async componentDidMount() {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    const { data: counsellors } = await employeeService.getAllCounsellors();
    this.setState({ counsellors });
    await this.getPagedData({ page, size, sort });
  }

  handleCounsellorSelect = async (event, enquiryId) => {
    const counsellor = event.target.value;
    const { page, rowsPerPage: size, sortColumn } = this.state;
    this.setState({ counsellor });
    await enquiryService.allocateCounsellor({
      counsellorId: parseInt(counsellor),
      id: enquiryId,
    });
    await this.getPagedData({ page, size, sortColumn });
  };

  handleChipClick = async (selectedChip) => {
    this.setState({ selectedChip });
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    this.getPagedData({ page, size, sort });
  };

  handleInputBaseClick = async (item) => {
    this.setState({ selectedChip: "ALL" });
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    this.getPagedData({ search: item, page, size, sort });
  };

  getPagedData = async (query) => {
    try {
      const { selectedChip: enquiryType } = this.state;
      const { data, headers } = await enquiryService.getAllByQuery({
        ...query,
        enquiryType,
      });
      const totalElements = headers["x-total-elements"];
      this.setState({ data:data.page, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await enquiryService.deleteById(id);
      const {
        selectedChip: enquiryType,
        page,
        rowsPerPage: size,
        sortColumn,
      } = this.state;
      const sort = sortColumn.path + "," + sortColumn.order;
      await this.getPagedData({ enquiryType, page, size, sort });
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const {
      data,
      counsellor,
      sortColumn,
      rowsPerPage,
      page,
      totalElements,
      selectedChip,
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          {/* <Grid container item xs={12}>
            <Grid item xs={12} md={3}>
              <MatInputBase
                txtLabel="Search by Enquiry #"
                btnLabel="serach"
                icon="search"
                onInputBaseClick={this.handleInputBaseClick}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MatInputBase
                txtLabel="Search by mobile"
                btnLabel="serach"
                icon="search"
                onInputBaseClick={this.handleInputBaseClick}
              />
            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          {/* <Grid container item xs={12} sm={12} md={8}>
            <MatChip
              items={[
                { label: "ALL", value: "ALL" },
                { label: "Type A", value: "A" },
                { label: "Type B", value: "B" },
              ]}
              selected={selectedChip}
              onChipClick={this.handleChipClick}
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EnquiryAllocationsTable
              data={data}
              counsellor={counsellor}
              counsellors={this.state.counsellors}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onCounsellorSelect={this.handleCounsellorSelect}
            />
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiries"])(EnquiryAllocations);
