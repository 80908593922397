import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEPT_OR_PRGM_ROUTE } from "../../../data/routerConstants";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const SpecializationsTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation();
  const columns = [
    { path: "id", label: t("ID") },
    { path: "degreeAlias", label: t("Program") },
    {
      path: "name",
      label: t("Stream"),
      content: (spez) => {
        return (
          <Link to={`${DEPT_OR_PRGM_ROUTE}/${spez.id}`}> {spez.name} </Link>
        );
      },
    },
    { path: "code", label: t("Code") },
    { path: "intake", label: t("intake") },
    {
      key: "action",
      content: (item) => <Edit link={`${DEPT_OR_PRGM_ROUTE}/${item.id}`} />,
    },
    {
      key: "delete",
      content: (item) => <Delete onDelete={onDelete} item={item} />,
    },
  ];

  return (
    <MatTable
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default SpecializationsTable;
