import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logger from "../../../services/logger";
import roleService from "../../../services/roleService";
import PagingAndSorting from "../../common/pagingAndSorting";
import RolesTable from "./rolesTable";

class Roles extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  componentDidMount() {
    this.getAll();
  }

  getAll = async () => {
    try {
      const { data } = await roleService.getAll();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteRole = async (id) => {
    try {
      await roleService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleConfirmOk = () => {
    this.deleteRole(this.state.id);
    this.setState({ confirmDialogOpen: false });
  };

  render() {
    const { sortColumn, data } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
            <Button variant="contained" color="secondary">
              <Link to={"/role-management/new"}>{t("New Role")}</Link>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <RolesTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["role"])(Roles);
