import React, { Component } from "react";
import authService from "../../../services/authService";
import enquiryService from "../../../services/enquiryService";
import EnquiryBasicInfoForm from "../enquiryBasicInfoForm";
import PaymentAndReceiptForm from "./paymentAndReceiptForm";
import PaymentAndReciepts from "./paymentAndReceipts";

class AdmissionFeePaymentAndReceipt extends Component {
    constructor() {
        super();
        this.state = {
            data: {},
            fee: null
        }
    }
    async componentDidMount() {
        const { id } = this.props.user;
        //console.log("user in payment reciept:", this.props.user.id);
        const { data } = await enquiryService.getEnquiryWithAadmissionFee(id);
        // if(data.admissionFeePaymentStatus==="PAYMENT_DONE" || data.admissionFeePaymentStatus==="PARTIALLY_PAYMENT_DONE"){
        //     enquiryService.getAdmissionFeeReceipts(id)
        // }
        //console.log("data payment reciept:", data);
        // const fee ={...this.state.fee};
       const fee=[{ ...data }]

        this.setState({ fee:fee })
        this.setState({ data });
    }


    render() {
        const { data, fee } = this.state;

        // return (<div>
        //     {(data.admissionFeePaymentStatus === "PAYMENT_PENDING" )? <PaymentAndReceiptForm data={data} {...this.props}  pay="ADMISSION_FEE" /> : (data.admissionFeePaymentStatus === "PAYMENT_DONE" ? <PaymentAndReciepts {...this.props} data={fee} /> : <h2>Please apply for the course first  </h2>)}
        // </div>)

        return (fee && <PaymentAndReciepts {...this.props} data={fee} />)

    }
}

export default AdmissionFeePaymentAndReceipt;