import { Button, Hidden, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { useTranslation } from "react-i18next";
import ButtonLink from "./buttonLink";
import ButtonLinkIcon from "./buttonLinkIcon";
const Delete = ({ item, onDelete, iconOnly = false }) => {
  const { t } = useTranslation(["common"]);
  return (
    <React.Fragment>


      <Hidden only={["xs", "sm"]}>
        {iconOnly ? <ButtonLinkIcon
          title={"Delete"}
          item={item}
          icon={"delete"}
          // style={{ color: "#ed5c4d", padding: "0px 8px" }}
          color="error"

          onButtonClick={onDelete}
        />
          : <ButtonLink
            title={"Delete"}
            item={item}
            icon={"delete"}
            // style={{ color: "#ffffff", background:"#ed5c4d" }}
            color="error"
            onButtonClick={onDelete}
          />
        }
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <ButtonLinkIcon
          title={"Delete"}
          item={item}
          icon={"delete"}
          // style={{ color: "#ed5c4d", padding: "0px 8px" }}
          color="error"
          onButtonClick={onDelete}
        />
      </Hidden>

    </React.Fragment>
  );
};

export default Delete;
