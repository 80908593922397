import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import admissionCalendarService from "../../../services/admissionCalendarService";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import AcademicCalendarTable from "./academicCalendarTable";
import academicCalendarService from "../../../services/academicCalendarService";

class AcademicCalendars extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  getAll = async () => {
    try {
      const { data } = await academicCalendarService.getAll();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await academicCalendarService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const { sortColumn, data } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
            <Button variant="contained" color="secondary">
              <Link to={"academic-calendars/new"}>{t("New Calendar")}</Link>
            </Button>
            {/* <Add link={"admission-calendars/new"} title={t("New Config")}  /> */}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AcademicCalendarTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["academicCalendar"])(AcademicCalendars);
