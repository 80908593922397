import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../services/logger";
import PagingAndSorting from "../common/pagingAndSorting";
import EmployeeTable from "./employeeTable";
import ButtonLink from "../common/buttonLink";
import EmployeeForm from "./employeeForm";
import FormDialog from "../common/formDialog";
import MatTablePagination from "../common/matTablePagination";
import employeeService from "../../services/employeeService";
import UploadEmployeesForm from "./uploadEmployeesForm";
import Add from "../common/add";

class Employees extends PagingAndSorting {
  state = {
    programs: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    formDialogOpen: false
  };

  getAll = async () => {
    try {
      const { data } = await employeeService.getAll();
      this.setState({ data });
    } catch (ex) {
      console.log("programs ", ex);
    }
  };

  deleteById = async (id) => {
    try {
      await employeeService.deleteById(id);
      // this.getPagedData(this.state.page, this.state.rowsPerPage);
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false,formDialogOpen:false });
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    this.getPagedData({ page, size, sort });
  };

  componentDidMount() {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    this.getPagedData({ page, size, sort });
  }

  getPagedData = async (query) => {
    try {
      const { selectedChip: enquiryType } = this.state;

      const { data, headers } = await employeeService.getAllByDepartment(this.props.departmentId, {
        ...query
      });
      //console.log("employees data:", data);
      const totalElements = headers["x-total-elements"];
      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };
  onConfirmOk = async (id) => {
    await this.deleteById(id)
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    await this.getPagedData({ page, size, sort });
  };
  handleUpload = (item) => {
    this.setState({ formDialogOpen: true });
  };

  render() {
    const { sortColumn, data, dialogOpen, formDialogOpen, id, totalElements, rowsPerPage, page } = this.state;
    const { t, departmentId, department } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            direction="row-reverse"
            spacing={3}
          >
            <ButtonLink
              item={{ id: "", value: "" }}
              icon={"upload"}
              title={t("Upload Employees")}
              onButtonClick={this.handleUpload}
            />
            &nbsp;&nbsp;
            <Add onAdd={this.handleAdd} item={{ id: "new" }} title={t("New Employee")} />

            {/* <Add onButtonClick={(event) => this.handleEdit({ id: "new" }, event)} icon="add_circle" title={t("Add Employee")} size="medium" /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EmployeeTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
            />
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}

        {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={department.name + " Employee Details"}
            content={
              <EmployeeForm
                id={id ? id : "new"}
                departmentId={department.id}
                department={department}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}

        {formDialogOpen && (
          <FormDialog
            id="bulkupload-dialog"
            keepMounted
            title={t("Upload Employees..!")}
            content={<UploadEmployeesForm onSave={this.closeDialog} departmentId={department.id} />}
            open={formDialogOpen}
            onClose={() => this.setState({ formDialogOpen: false })}
          />
        )}

      </React.Fragment>
    );
  }
}

export default withTranslation(["program"])(Employees);
