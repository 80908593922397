import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";
import ButtonLink from "../../common/buttonLink";

const PaymentAndReceiptTable = ({ data, sortColumn, onSort, onDownload, onPayRemaining,onPay }) => {
  const { t } = useTranslation(["paymentAndReceipt","common"]);
  const columns = [
    { path: "programName", label: t("common:Program") },
    { path: "instituteName", label: t("Institute Name") },
    { path: "feeAmount", label: t("Fee") },
    { path: "transactionDate", label: t("Transaction Date") },
    { path: "transactionNumber", label: t("Transaction #") },
    {
      key: "action",
      content: (item) => {
        return (
          <>
            {/* {renderPay(item)} */}
            <ButtonLink onButtonClick={() => onDownload(item)} icon={"download"} title="Reciept" />
            {/* <ButtonLink link={"/application-fee-receipt-pdf"} target={"_blank"} icon={"download"} title="Receipt" /> */}
            {/* <PDFDownloadLink  document={<ApplicationFeeReceiptPDF/>} >
    
    {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Download now!'
      }
    </PDFDownloadLink> */}
          </>
        );
      },
    }
  ];

  function renderPay(item) {
    if (item.remainingAmount && item.remaingAmount > 0)
      return <ButtonLink onButtonClick={() => onPayRemaining(item)} title={"Pay-" + item.remainingAmount} />;
    else if(item.paidAmount==null || item.paidAmount<=0)
    return <ButtonLink onButtonClick={() => onPay(item)} title={"Pay-" + item.feeAmount} />;

  };

  return (
    <MatTable
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default PaymentAndReceiptTable;
