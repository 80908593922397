import { TableBody, TableCell, TableRow } from "@mui/material";
import _ from "lodash";
import React, { Component } from "react";

class MatTableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };

  createKey = (item, column) => {
    return (item.id || item._id) + (column.path || column.key);
  };

  render() {
    const { data, columns, classes } = this.props;
    if (data && data.length > 0)
      return (
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={item._id || item.id}>
              <TableCell
                align={"left"}
                key={this.createKey(item, index)}
                className={classes.tableRow}
              >
                {index + 1}
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  align={column.align?column.align: column.numeric || column.key==="action" ? "right" : "left"}
                  // align={column.key==="action"?"right":"center"}

                  key={this.createKey(item, column)}
                  className={`${classes.tableRow} ${column.className}`}
                >
                  {this.renderCell(item, column)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      );
    else
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length} className="text-center">
              Data not available
            </TableCell>
          </TableRow>
        </TableBody>
      );
  }
}

export default MatTableBody;
