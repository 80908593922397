import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import applicationFormService from "../../../services/applicationFormService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function prepOptions(data) {
  return {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        // min: 1,
        // max: data.total,
        width: 3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
}

function prepChartData(data) {
  if (data) {
    // delete data.total;

    // data.source.map((s) => s.source);
    const labels = data.map((s) => s.label);
    const dataSet = labels.map((l) => data.find((s) => s.label === l).count);
    const options = prepOptions(data);
    return {
      options: options,
      data: {
        labels,
        datasets: [
          {
            data: dataSet,
            backgroundColor: "#b88e63",
          },
        ],
      },
    };
  }
}

class ApplicantStatsCard extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    try {
      const { data } = await applicationFormService.getApplicantStats();
      this.setState({ data });
    } catch (erro) {}
  }

  prepTile(data) {
    return (
      <List component="nav" aria-label="mailbox folders">
        {data.map((d) => (
          <ListItem button>
            <ListItemText
              primary={
                <div style={{ margin: 0 }}>
                  <span> {d.label}</span>
                  <div style={{ fontSize: 20, float: "right" }}>
                    <strong>{d.count}</strong>
                  </div>
                </div>
              }
            />
          </ListItem>
        ))}
      </List>
    );
  }

  render() {
    const { t } = this.props;
    const { data } = this.state;
    const dataSet = prepChartData(data);
    return (
      <React.Fragment>
        <Card className="dashboard-card">
          <CardHeader title={t("Enquiry Sources")}></CardHeader>
          <CardContent>
            <Grid container alignItems={"baseline"} spacing={2}>
              <Grid item md={5}>
                {this.prepTile(data)}
              </Grid>
              <Grid item md={7}>
                <br />
                {dataSet && (
                  <Bar
                    options={dataSet.options}
                    data={dataSet.data ? dataSet.data : {}}
                    height={180}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

export default withTranslation(["applicants"])(ApplicantStatsCard);
