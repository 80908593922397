import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import OrgContext from "../../app-context";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import BackButton from "../../common/backButton";
import eventsCalendarService from "../../../services/eventsCalendarService";

class EventsCalendarForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("eventsCalendar");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }





  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      
      const { data } = await eventsCalendarService.get(id);

      this.mapToViewModel(data);
    } catch (ex) { }
  }

  async componentDidMount() {

    await this.populateForm();
  }

  doSubmit = async (id) => {
    try {
      console.log("date time:",this.state.data);
      if (id === "new") await eventsCalendarService.create(this.state.data);
      else await eventsCalendarService.update(this.state.data);
      this.props.history.goBack();
    } catch (ex) {
      // logger.log(ex.response.data.message);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      <Card>
          <CardHeader title={t("Event Detail")} action={<BackButton {...this.props} />} />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

        </form>
      </Card>
    );
  }
}
EventsCalendarForm.contextType = OrgContext;
export default withTranslation(["program", "common"])(EventsCalendarForm);
