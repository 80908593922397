import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/pre-admission-calendars";
const apiEndpoint = "/api/pre-admission-calendars";


function createPreAdmissionCalendar(preAdmissionCalendar) {
  return http.post(apiEndpoint, preAdmissionCalendar);
}

function updatePreAdmissionCalendar(preAdmissionCalendar) {
  return http.put(apiEndpoint, preAdmissionCalendar);
}

function getPreAdmissionCalendar(calendarId) {
  return http.get(`${apiEndpoint}/${calendarId}`);
}
function getPreAdmissionCalendars() {
  return http.get(apiEndpoint);
}

function deletePreAdmissionCalendar(calendarId) {
  return http.delete(`${apiEndpoint}/${calendarId}`);
}

export default {
  createPreAdmissionCalendar,
  updatePreAdmissionCalendar,
  getPreAdmissionCalendar,
  getPreAdmissionCalendars,
  deletePreAdmissionCalendar,
};
