import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import authService from "../../../services/authService";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";

import MatChips from "../../common/matChips";
import MatInputBase from "../../common/matInputBase";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import ApplicationTable from "./applicationTable";
import { debounce } from "lodash";
// import UploadEnquiriesForm from "./uploadEnquiriesForm";

class Applications extends PagingAndSorting {
  state = {
    data: [],
    status: "ALL",
    allocatedTo: "ME",
    sortColumn: { path: "date", order: "desc" },
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    confirmDialogOpen: false,
    formDialogOpen: false,
  };

  componentDidMount() {
   
   
    this.getPagedData({
      ...this.query(),
      status: this.state.status
    });
  }

  query(search = "") {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { search, page, size, sort };
  }

 

 
  handleStatusClick = (type) => {
    //console.log("type :", type);
    this.setState({ status: type });

    this.getPagedData({
      ...this.query(),
      status: type
    });
  };

  // handleInputBaseClick = async (item) => {
  //   //console.log("inputbase :", item);
  //   this.setState({ status: "ALL" });

  //   this.getPagedData({
  //     ...this.query(item),
  //     status: "ALL"
      
  //   });
  // };

  handleInputBaseClick = async (item) => {
    this.setState({search:''})
    this.setState({ status: "ALL" });
    this.getPagedData({
      ...this.query(),
      status: "ALL"
    });
  };

   debouncedSearch = debounce((item) => {
    this.getPagedData({
      ...this.query(item),
      status: "ALL"
    });
  }, 300); 

  handleInputBaseChange = async (item) => {
    console.log("input base change:",item);
    this.setState({search:item})
    this.setState({ status: "ALL" });
    this.debouncedSearch(item);

  };

  getPagedData = async (query) => {
    try {
      query.status = query.status
        ? query.status
        : this.state.status;
    
      const { data, headers } = await enquiryService.getAllByEnquiryStatus(query);
      const totalElements = headers["x-total-elements"];

      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

 

  render() {
    const {
      sortColumn,
      data,
      rowsPerPage,
      page,
      totalElements,
      status,
      allocatedTo,
      confirmDialogOpen,
      formDialogOpen,
      search
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12}>
            {/* <Grid item xs={12} md={3}>
              <MatInputBase
                txtLabel="Search by Application #"
                btnLabel="serach"
                icon="search"
                onInputBaseClick={this.handleInputBaseClick}
              />
            </Grid> */}
            <Grid item xs={12} md={3}>
              <MatInputBase
                input={search}
                txtLabel="Search by Name/Mobile"
                btnLabel="serach"
                icon="search"
                onInputBaseClick={this.handleInputBaseClick}
                onChange={this.handleInputBaseChange}
              />
            </Grid>
            {/* {authService.getCurrentRole() === "COUNSELLOR" || authService.getCurrentRole() === "ADMISSION_MANAGER" && (
              <Grid container item xs={12} md={6} direction="row-reverse">
                <MatChip
                  items={[
                    { label: "ALL", value: "ALL" },
                    { label: "Allocated to me", value: "ME" },
                  ]}
                  selected={allocatedTo}
                  onChipClick={this.handleAllocatedToClick}
                />
              </Grid>
            )} */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          {authService.getCurrentRole() === "COUNSELLOR" || authService.getCurrentRole() === "ADMISSION_MANAGER" && (
            <Grid container XS={12}>
              <Grid container item xs={12} sm={12} md={8}>
                <MatChips
                  items={[
                    { label: "ALL", value: "ALL" },
                    { label: "Submitted", value: "SUBMITTED" },
                    { label: "Approved", value: "APPROVED" },
                    { label: "Rejected", value: "REJECTED" },
                  ]}
                  selected={status}
                  onChipClick={this.handleStatusClick}
                />
              </Grid>
              
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ApplicationTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>

        {confirmDialogOpen &&
          this.renderDeleteConfirmDialog(
            t("Delete Confirmation"),
            t("Are you sure you want to delete?")
          )}

       
      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiries"])(Applications);
