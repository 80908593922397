import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@mui/material";
import classnames from "classnames";
import Joi from "joi";
import React from "react";
import { withTranslation } from "react-i18next";
import auth from "../../../services/authService";
import logger from "../../../services/logger";
import Form from "../../common/form";

class ActivatePasswordForm extends Form {
  state = {
    data: { password: "" },
    errors: {},
  };

  schema = {
    password: Joi.string().required()
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    .label("Password")
    .messages({
      'string.pattern.base': 'Password length must be at least 8 characters long and must contain at least one lowercase letter, one uppercase letter, one digit, and one special character',
      'any.required': 'Password is required',
      'string.empty': 'Password is not allowed to be empty'
    }),
  };

  key = "";

  doSubmit = async (key) => {
    try {
      const { data } = this.state;

      await auth.activatePassword(data.password, this.key);

      window.location = "/";
    } catch (ex) {
      logger.log(ex);
    }
  };

  componentDidMount() {
    this.key = this.props.match.params.key;
  }

  render() {
    const { t, classes } = this.props;
    return (
      <div style={{marginTop:"100px"}}>
        <Grid container>
          <Grid md={4}></Grid>
          <Grid md={4}>
            <Card variant="outlined">
              <CardHeader
                title={t("Activate Your Account")}
                className={classnames(classes.textColorPrimary)}
              />
              <Divider />
              <CardContent>
                <form onSubmit={(event) => this.handleSubmit(event, "new")}>
                  <Grid item>
                    {this.renderMatInput(
                      {},
                      "password",
                      t("Create New Password"),
                      "password",
                      "outlined",
                      "medium"
                    )}
                  </Grid>

                  <Grid item>
                    {this.renderMatButton(
                      t("Submit"),
                      "submit",
                      "secondary",
                      true
                    )}
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid md={4}></Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation(["common"])(ActivatePasswordForm);
