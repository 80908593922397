import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MatTable from "../../common/matTable";

const MyApplicationsTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["myApplications"]);
  const columns = [
    { path: "id", label: t("ID") },
    { path: "applicationNumber", label: t("Application #") },
    {
      path: "degreeAlias",
      label: t("Course"),
      sort: true,
    },
    { path: "departmentAlias", label: t("Specialization") },
    // { path: "instituteName", label: t("Institute Name") },
    {
      key: "apply",
      content: (item) => (
        <Button variant="contained" color="secondary">
          <Link to={`/admission-application-forms/pay/${item.id}`}>
            {t("Pay Now")}
          </Link>
        </Button>
      ),
    },
  ];

  return (
    <MatTable
      data={data}
      heading="My Applications"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default MyApplicationsTable;
