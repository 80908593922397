import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const AdmissionCalendarTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["admissionCalendar"]);
  const columns = [
    { path: "id", label: t("ID") },

    { path: "academicYear", label: t("Academic Year") },
    { path: "startDate", label: t("Start Date") },
    { path: "endDate", label: t("End Date") },
    {
      key: "active",
      label: "Enabled",
      content: (obj) => (obj.active ? "Yes" : "No"),
    },
    {
      key: "action",
      content: (item) => {
        return <Edit link={`admission-calendars/${item.id}`}/>;
      },
    },
    {
      key: "delete",
      content: (obj) => <Delete onDelete={onDelete} item={obj} />,
    },
  ];

  return (
    <MatTable
      data={data}
      heading="Admission Clendars"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default AdmissionCalendarTable;
