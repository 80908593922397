import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import applicationFeeConfigService from "../../../services/applicationFeeConfigService";
import logger from "../../../services/logger";
import myApplicationService from "../../../services/myApplicationService";
import PagingAndSorting from "../../common/pagingAndSorting";
import MyApplicationsTable from "./myApplicationsTable";

class MyApplications extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  componentDidMount() {
    this.getAll();
  }

  handleConfirmOk = () => {
    this.deleteGroup(this.state.id);
    this.setState({ confirmDialogOpen: false });
  };

  getAll = async () => {
    try {
      const { data } = await myApplicationService.getAllByUser();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteGroup = async (id) => {
    try {
      await applicationFeeConfigService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, data } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MyApplicationsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></MyApplicationsTable>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withTranslation(["myApplications"])(MyApplications);
