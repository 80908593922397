
import React, { Component } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import MatChips from '../../common/matChips';
import { getStatsData } from '../../common/util';
import enquiryService from '../../../services/enquiryService';
import BackButton from '../../common/backButton';
import MatCircularProgressbar from '../../common/matCircularProgressbar';
import { withTranslation } from 'react-i18next';

class ApplicationAnalysisByProgram extends Component {
  state = {}


  async componentDidMount() {
    const { data } = await enquiryService.getEnquiryStatsByProgram("Application");
    console.log("data========:", data);
    this.setState({ data });
    const stats = getStatsData();
    this.setState({ stats })
  }
  handleClick = (item) => {
    this.props.history.push("/manage-applications/applicants?programId=" + item.id);

  }
  getChipItem(label, count) {
    return <><span>{label}</span> <br></br> <span className="count-label">{count ? count : 0}</span></>
  }
  render() {
    const { data, stats } = this.state;
    const {t}=this.props;
    return (<>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography component={"h"} variant='h6' >
            {"Application Analysis by "+t("common:Program")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems='flex-end'>
          <Grid container direction='row-reverse'>
            <BackButton {...this.props} />
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container>
        <MatChips
          items={[
            { label: this.getChipItem("Today", stats?.todayApplicationCount), value: "ALL" },
            { label: this.getChipItem("Total", stats?.totalApplicationCount), value: "TOTAL" },
            { label: this.getChipItem("Percentage", stats?.applicationPercentage+"%"), value: "A" },
            // { label: this.getChipItem("Collectable", stats?.bcc.Collectable), value: "B" },
            // { label: this.getChipItem("Type C", stats?.bcc.C), value: "C" },
            // { label: this.getChipItem("Followup Remaining", stats?.enquiryFollowupType.remaining), value: "REMAINING" },
          ]}
        // selected={enquiryType}
        // onChipClick={this.handleEnquiryTypeClick}
        />
      </Grid>
      <br />
      <Divider />
      <br />
      <Grid container spacing={2}>
        {
          data && data.programs.map(item => {
            return (
              <Grid xs={12} sm={12} md={2} item onClick={() => this.handleClick(item)}>
                <Card>
                  <CardHeader title={item.programName}> </CardHeader>
                  <CardContent>
                  <MatCircularProgressbar value={item.percentage}
                  content={
                    <div style={{ marginTop: -5, textAlign: "center" }}>
                      <h2 style={{ margin: 0 }}>Applications</h2>
                      <strong>
                        {item.enquiryCount}/{item.targetValue}
                        <br />
                        {`(${item.percentage}%)`}
                      </strong>
                    </div>
                  }
                />
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    </>);
  }
}

export default withTranslation(["feeDetails","common"])(ApplicationAnalysisByProgram);