import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@mui/material";
import Joi from "joi";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import auth from "../../../services/authService";
import logger from "../../../services/logger";
import Form from "../../common/form";
class ForgotPasswordForm extends Form {
  state = {
    data: { email: "" },
    errors: {},
  };

  schema = {
    email: Joi.string().required().label("Email"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.forgotPassword(data.email);

      // const { state } = this.props.location;
     //console.log("location: ", this.props.location);
      this.props.history.push("/forgot-password-success");
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.paper} style={{marginTop:"100px"}}>
        <Grid container component="main">
          <h2>&nbsp; </h2>
        </Grid>
        <Grid container component="main">
          <Grid item xs={12} sm={12} md={4}></Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card variant="outlined">
              <CardHeader
                title={t("Forgot Password")}
                className={classes.textPrimary}
              />
              <Divider />
              <CardContent>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <Grid container>
                    {this.renderMatInput(
                      {},
                      "email",
                      "Enter your registered email",
                      "text",
                      "outlined",
                      "medium"
                    )}

                    {this.renderMatButton(
                      t("Submit"),
                      "submit",
                      "secondary",
                      true
                    )}
                  </Grid>
                  <Grid container item spacing={5}>
                    <Grid item xs>
                      <Button size="small" variant="text" color="primary">
                        <Link to="/login" replace="true">
                          {t("Existing User? Login")}
                        </Link>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}></Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation(["forgotPassword", "common"])(
  ForgotPasswordForm
);
