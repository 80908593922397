import { Paper } from "@mui/material";
import React from "react";
const ForgotPasswordSuccess = (props) => {
  return (
    <div style={{marginTop:"100px",height:"300px"}}>
    <Paper>
      <h1>Password reset link is sent to your registerd email</h1>
      <h2>Please check your email to reset your password.</h2>
    </Paper>
    </div>
  );
};

export default ForgotPasswordSuccess;
