import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logger from "../../../services/logger";
import userService from "../../../services/userService";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import UsersTable from "./usersTable";
class Users extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
    totalElements: 0,
  };

  componentDidMount() {
    this.getPagedData(this.state.page, this.state.rowsPerPage);
  }

  getPagedData = async (page, size) => {
    try {
      const { data, headers } = await userService.getAllByQuery({
        page,
        size,
        sort: this.state.sortColumn.path + "," + this.state.sortColumn.order,
      });

      const totalElements = headers["x-total-elements"];

      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  onConfirmOk = async (id) => {
    try {
      await userService.deleteById(id);
      await this.getPagedData(this.state.page, this.state.rowsPerPage);
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, data, rowsPerPage, page, totalElements } = this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid container item sm={12} md={12} lg={12} direction="row-reverse">
            <Button variant="contained" color="secondary">
              <Link to={"/user-management/new"}>{t("New User")}</Link>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <UsersTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            />
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["applicationFeeConfigs"])(Users);
