import React from "react";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import { formatToDate } from "../../common/dateUtil";

const MileagesTable = ({ data, sortColumn, onSort, onDelete,onEdit,onNumOfStopsClick,pagination,t}) => {
  const columns = [
    { path: "busRegNum", label: t("Bus Reg #")},

    { path: "fuelFilled", label: t("Fuel Filled(In Ltrs)"),numeric:true },
    { path: "fuelFilledDate", label: t("Fuel Filled Date"),
      content:(item)=>{
        return formatToDate(item.fuelFilledDate);
      }
  },
    { path: "initialMeterReading", label: t("Initial Reading"),numeric:true },
    
    { path: "finalMeterReading", label: t("Final Reading"),numeric:true},
    { path: "totalMileage", label: t("Total Mileage")},
    {
      key: "action",
      content: (item) => {
        return <><Edit item={item} onEdit={onEdit} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("Mileage Details")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default MileagesTable;
