import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import admissionPartnerService from "../../../services/admissionPartnerService";
import logger from "../../../services/logger";
import PagingAndSorting from "../../common/pagingAndSorting";
import AdmissionPartnerTable from "./admissionPartnerTable";

class AdmissionPartners extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  componentDidMount() {
    this.getAll();
  }

  onConfirmOk = async (id) => {
    try {
      await admissionPartnerService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  getAll = async () => {
    try {
      const { data } = await admissionPartnerService.getAll();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, data } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} direction="row-reverse">
            <Button variant="contained" color="secondary">
              <Link to={"/admission-partners/new"}>{t("New Partner")}</Link>
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AdmissionPartnerTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></AdmissionPartnerTable>
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["admissionFeeConfigs"])(AdmissionPartners);
