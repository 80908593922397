import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/users";
const apiEndpoint = "/api/users";


function create(data) {
  return http.post(apiEndpoint, {...data,instituteType:authService.getInstituteType()});
}



function update(data) {
  return http.put(apiEndpoint, {...data,instituteType:authService.getInstituteType()});
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(apiEndpoint);
}

function getAllByDepartment(id) {
  return http.get(`${apiEndpoint}/department/${id}`);
}

function getAllByRole(role) {
  return http.get(`${apiEndpoint}/role/${role}`);
}

function getAllByQuery(query) {
  return http.get(apiEndpoint, { params: { ...query } });
}


function addEmployee(data) {
  return http.post(`${apiEndpoint}/employees/add`, {...data,instituteType:authService.getInstituteType()});
}

function updateEmployee(data) {
  return http.post(`${apiEndpoint}/employees/update`, {...data,instituteType:authService.getInstituteType()});
}

function getAllEmployeesByQuery(query) {
  return http.get(`${apiEndpoint}/employees/q`, { params: { ...query } });
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByQuery,
  getAllEmployeesByQuery,
  addEmployee,
  updateEmployee,
  getAllByDepartment,
  getAllByRole,
  deleteById,
};
