import { CssBaseline, ThemeProvider, StyledEngineProvider } from "@mui/material";
import React, { Suspense } from "react";
import "react-circular-progressbar/dist/styles.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./configs/i18n.js";
import "./index.css";
import "./public-path";
import * as serviceWorker from "./serviceWorker";
import "./themes/font.css";
import { getTheme } from "./themes/theme";

const theme = getTheme({name:"na"});
console.log("therem:",theme);
function render(props) {
  const { container } = props;

  ReactDOM.render(
    <Suspense fallback="loading...">
      <BrowserRouter basename="/hedge-brown">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ToastContainer />
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </Suspense>,
    container
      ? container.querySelector("#root")
      : document.querySelector("#root")
  );
}

function storeTest(props) {
  props.onGlobalStateChange(
    (value, prev) =>
      console.log(`[onGlobalStateChange - ${props.name}]:`, value, prev),
    true
  );
  props.setGlobalState({
    ignore: props.name,
    user: {
      name: props.name,
    },
  });
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  console.log("[rewired] react app bootstraped");
}

export async function mount(props) {
  console.log("[rewired] props from main framework", props);
  storeTest(props);
  render(props);
}

export async function unmount(props) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector("#root")
      : document.querySelector("#root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//render({});
