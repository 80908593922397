import React from "react";
import { withTranslation } from "react-i18next";
import feeItemService from "../../../services/feeItemService";
import logger from "../../../services/logger";

import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class FeeItemForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("feeItem");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  
  

  async populateForm() {
    // const id = this.props.match?.params?.id;
   const id=this.props.id;
    if (id === "new") return;
    try {
      const { data } = await feeItemService.get(id);

      this.mapToViewModel(data);
    } catch (ex) { }
  }
  async componentDidMount() {
    await this.populateForm();
  
  }

  doSubmit = async (id) => {
    try {
      const req={...this.state.data,name:this.state.data.label,categoryId:this.props.categoryId};
      if (id === "new") await feeItemService.create(req);
      else await feeItemService.update(req);
      // this.props.history.goBack();
      this.props.onSave();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { t,id } = this.props;
    const { dropDowns } = this.state;
    // const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      // <Card>
      //   <CardHeader
      //     title={t(titleText)}
      //     action={
      //       <BackButton {...this.props} />
      //     }
      //   />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset")}
            </Grid>
          </CardContent> */}
        </form>
      // </Card>
    );
  }
}

export default withTranslation(["feeItem", "common"])(FeeItemForm);
