import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Form from "../../common/form";
import {
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";
import classSectionFormJson from "./classSection.json"
import classSectionService from "./classSection-service";

class ClassSectionForm extends Form {
  constructor() {
    super();
    this.formJson = classSectionFormJson;
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }



  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await classSectionService.get(id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    this.getPrograms();
    await this.populateForm();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await classSectionService.create(this.state.data);
      } else {
        await classSectionService.update(this.state.data);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;


    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          <CardHeader title={t(titleText)} />

          {this.renderForm(this.formJson, dropDowns)}

          {/* <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t(buttonText))}
              {this.renderMatButton(t("common:Reset"), "reset", "secondary")}
            </Grid>
          </CardContent> */}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["classSection", "common"])(
  ClassSectionForm
);
