import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import RoutesTable from "./routesTable.page";
import routeService from "./route.service";
import FormDialog from "../../common/formDialog";
import StopsPage from "../manage-stops/stops.page";

class Routes extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    confirmDialogOpen: false,
    numOfStopsDilog:false,
    pagination: { page: 0, rowsPerPage: 25, count: 0, onPageChange: this.handlePageChange, onRowsPerPageChange: this.handleRowsPerPageChange }

  };

  componentDidMount() {
    this.getPagedData({ ...this.query() });
  }


  onConfirmOk = async (id) => {
    try {
      await routeService.deleteById(id);
      await this.getPagedData({ ...this.query() });
    } catch (ex) {
      logger.error(ex);
    }
  };


  getPagedData = async (query) => {
    try {
      const { data, headers } = await routeService.getAllByQuery(query);
      const totalElements = headers["x-total-elements"];
      const pagination = { ...this.state.pagination };
      pagination.count = totalElements;
      this.setState({ data, pagination });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleNumOfStopsClick = (item) => {
    this.setState({numOfStopsDilog:true,routeId:item.id});

  }

  render() {
    const { sortColumn, data, pagination, confirmDialogOpen,numOfStopsDilog, dialogOpen, routeId } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid container item sm={12} md={12} direction="row-reverse">
              <Add link={"routes/new"} title={t("Add Route")} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <RoutesTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onNumOfStopsClick={this.handleNumOfStopsClick}
              pagination={pagination}
              t={t}
            />
          </Grid>
        </Grid>
        {
          numOfStopsDilog &&
          <FormDialog
            title={t("Stop Details")}
            content={<StopsPage routeId={routeId}/>}
            open={numOfStopsDilog}
            onClose={()=>this.setState({numOfStopsDilog:false})}
          />
        }

        {confirmDialogOpen && this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["route", "common"])(Routes);
