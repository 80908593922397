import React, { Component } from "react";
import authService from "../services/authService";
import instituteService from "../services/instituteService";
import CounsellorDashboard from "./dashboards/counsellorDashboard";
class Home extends Component {
  state = {};

  async componentDidMount() {
    try {
      const { data: institute } = await instituteService.getInstituteOrTrust(authService.getInstituteTenant());
      if (institute) {
        this.setState({ institute });
        this.handleLocalStorage(institute?.name)
      }
    } catch (error) {

    }
  }
  handleLocalStorage = (name) => {
    localStorage.setItem("institute", name)
    window.dispatchEvent(new Event("storage"));
  };
  render() {
    return (
      <React.Fragment>
        {/* <h1>
          Welcome back <strong>{authService.getCurrentUser().login}</strong>{" "}
        </h1> */}
        <h1>
          Dashboard
        </h1>
        {/* <CounsellorDashboard /> */}
      </React.Fragment>
    );
  }
}

export default Home;
