import authService from "./authService";
import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/users";
const apiEndpoint = "/api/users";

const educationsApi = "educations";

function create(userId, data) {
  const education = { ...data, userId: userId };
  return http.post(`${apiEndpoint}/${userId}/${educationsApi}`, education);
}

function update(userId, data) {
  const education = { ...data, userId: userId };
  return http.put(`${apiEndpoint}/${userId}/${educationsApi}`, education);
}

function get(userId, id) {
  return http.get(`${apiEndpoint}/${userId}/${educationsApi}/${id}`);
}

function getByUserId(userId) {
  return http.get(`${apiEndpoint}/${userId}/${educationsApi}`);
}
function getAll() {
  return http.get(apiEndpoint);
}

function getAllByQuery(query) {
  const userId = authService.getCurrentUser().id;
  return http.get(`${apiEndpoint}/${userId}/${educationsApi}/q`, {
    params: { ...query },
  });
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}/${educationsApi}/${id}`);
}

export default {
  create,
  update,
  get,
  getByUserId,
  getAll,
  getAllByQuery,
  deleteById,
};
