import React from "react";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import ButtonLink from "../../common/buttonLink";

const BlocksTable = ({ data, sortColumn, onSort,onEdit, onDelete,onNumOfRoomsClick,pagination,t}) => {
  const columns = [
    { path: "blockNum", label: t("Block #") },
    { path: "name", label: t("Block Name") },
    { path: "numOfRooms", label: t("# of Rooms"),numeric:true,
      key:"action",
      content:(item)=>{
        return<>
        <ButtonLink icon="visibility" variant="text" title={item.numOfRooms?item.numOfRooms:0} onButtonClick={()=>onNumOfRoomsClick(item)}/>
        </>
      }
  
    },

    {
      key: "action",
      content: (item) => {
        return <><Edit onEdit={onEdit} item={item} /> &nbsp; <Delete onDelete={onDelete} item={item} /></>;
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Blocks")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default BlocksTable;
