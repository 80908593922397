import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import employeeService from "../../services/employeeService";
import logger from "../../services/logger";
import OrgContext from "../app-context";
import { getDynamicFormJson } from "../common/dynamicFormService";
import Form from "../common/form";
import {
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";
import programService from "../../services/programService";
import studentService from "../../services/studentService";
import termDataService from "../../services/termDataService";
import BackButton from "../common/backButton";
import { getBloodGroups, getGenders, getMotherTongue, getNationality, getReligion } from "../../data/enums";
import icamelService from "../../services/icamelService";

class StudentForm extends Form {
  constructor(props) {
    super(props);
    this.formJson = getDynamicFormJson("student");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  getCountries = async () => {
    try {
      //console.log("getcountries");
      const { data } = await icamelService.getCountries();
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["countries"] = data;
      this.setState({ dropDowns });
      if (this.state.data.country)
        await this.getStatesByCountry(this.state.data.country)
    } catch (ex) {
      logger.error(ex);
    }
  };

  getStatesByCountry = async (countryCode) => {
    try {
      const { data } = await icamelService.getStatesByCountry(
        countryCode
      );
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["states"] = data;
      this.setState({ dropDowns });
      if (this.state.data.state)
        await this.getCitiesByState(this.state.data.state);
    } catch (ex) {
      logger.error(ex);
    }
  };

  getCitiesByState = async (stateCode) => {
    try {
      const { data } = await icamelService.getCitiesByState(stateCode);
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["cities"] = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error(ex);
    }
  };



  getPrograms = async () => {
    try {
      const { data } = await programService.getAll();
      const dropDowns = this.state.dropDowns;
      dropDowns.programs = data;
      this.setState({ dropDowns });
      if (this.state.data.programId)
        await this.getTerms(this.state.data.programId);
    } catch (ex) {
      logger.error("roles ", ex);
    }
  };

  getTerms = async (id) => {
    try {
      const { data } = await termDataService.getAllByProgram(id);
      const dropDowns = this.state.dropDowns;
      dropDowns.terms = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("roles ", ex);
    }
  };

  onSelectOf = (selected, value) => {
    switch (selected) {
      case "programId":
        this.getTerms(value);
        break;
      case "country":
        this.getStatesByCountry(value);
      case "state":
        this.getCitiesByState(value);
          
      default:
        break;
    }
  }


  getDropdowns = () => {
    const { dropDowns } = { ...this.state };
    const updated = {
      ...dropDowns,
      bloodGroups: getBloodGroups(),
      nationalities: getNationality(),
      religions: getReligion(),
      motherTongues: getMotherTongue(),
      genders: getGenders()
    };
    this.setState({ dropDowns: updated });
  };


  async populateForm() {
    const id = this.props.match.params.id;
    //console.log("employee id:",id);
    if (id === "new") return;
    try {
      const { data } = await studentService.get(id);
      //console.log("employee data by id:",data);
      this.mapToViewModel(data);
    } catch (ex) { }
  }

  async componentDidMount() {
    this.getDropdowns();
    await this.populateForm();
    await this.getPrograms();
    this.getCountries();
  }

  doSubmit = async (id) => {
    try {
      const { data } = this.state;
      if (id === "new") await studentService.create(data);
      else {
        await studentService.update(data);
      }
      this.props.history.goBack();
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    // const buttonText = getFormButtonText(id);
    const titleText = getFormTitleText(id, t(this.formJson.alias));
    return (
      <Card>
        <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          {this.renderForm(this.formJson, dropDowns)}
        </form>
      </Card>
    );
  }
}
StudentForm.contextType = OrgContext;
export default withTranslation(["student", "common"])(StudentForm);
