import { useTranslation } from "react-i18next";
import termsAndConditionsService from "../../../services/termsAndConditionsService";
// import renderHTML from 'react-render-html';
import htmr from 'htmr';
import React, { Component } from 'react';


class TermsAndConditionsView extends Component {
  state = {  } 

async componentDidMount(){
  const {data}= await termsAndConditionsService.getByType("PRE_ADMISSION");
  //console.log("t and c :",data);
  this.setState({data});   
 }
  render() { 
    const {data}=this.state;
    if(data)
    return ( <div>
      
      {htmr(data.value)}
    </div>);
    else
    return <h5>Wait...</h5>
  }
}
 
export default TermsAndConditionsView ;