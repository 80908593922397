import React, { Component } from "react";
import { client, xml } from "@xmpp/client";

class XmppChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      inputMessage: "",
    };

    this.xmpp = client({
      service: "http://localhost:7444/http-bind",
      domain: "localhost",
      resource: "example",
      username: "shree",
      password: "123",
    });

    console.log("xmpp:",this.xmpp);

    this.xmpp.on("error", (err) => {
      console.error(err);
    });

    this.xmpp.on("offline", () => {
      console.log("offline");
    });

    this.xmpp.on("stanza", async (stanza) => {
      if (stanza.is("message")) {
        const newMessage = {
          type: "received",
          text: stanza.getChildText("body"),
        };

        this.setState((prevState) => ({
          messages: [...prevState.messages, newMessage],
        }));

        await this.xmpp.send(xml("presence", { type: "unavailable" }));
        await this.xmpp.stop();
      }
    });

    this.xmpp.on("online", async (address) => {
      // Makes itself available
      await this.xmpp.send(xml("presence"));

      // Sends a chat message to itself
      const message = xml(
        "message",
        { type: "chat", to: address },
        xml("body", {}, "hello world")
      );
      await this.xmpp.send(message);
    });

    this.xmpp.start().catch(console.error);
  }

  handleInputChange = (event) => {
    this.setState({ inputMessage: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { inputMessage } = this.state;

    if (inputMessage.trim() !== "") {
      const message = xml(
        "message",
        { type: "chat", to: "destination@example.com" }, // Replace with the actual destination address
        xml("body", {}, inputMessage)
      );

      await this.xmpp.send(message);

      this.setState((prevState) => ({
        messages: [...prevState.messages, { type: "sent", text: inputMessage }],
        inputMessage: "",
      }));
    }
  };

  render() {
    const { messages, inputMessage } = this.state;

    return (
      <div className="xmpp-chat">
        <div className="chat-window">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.type === "received" ? "received" : "sent"}`}
            >
              {message.text}
            </div>
          ))}
        </div>
        <form className="message-form" onSubmit={this.handleSubmit}>
          <input
            type="text"
            value={inputMessage}
            onChange={this.handleInputChange}
            placeholder="Enter your message..."
          />
          <button type="submit">Send</button>
        </form>
      </div>
    );
  }

  componentWillUnmount() {
    this.xmpp.stop().catch(console.error);
  }
}

export default XmppChat;
