import { HEDGE_BROWN_SERVER } from "../data/endpointConstants";
import http from "./httpService";

const apiEndpoint=HEDGE_BROWN_SERVER
function downloadFile(uri,fileName="template") {
     http.get(`${apiEndpoint}/${uri}`,{responseType:"blob"}).then(response=>{
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
     }).catch(error=>{
        console.log("error:",error);
     })
}

export default{
    downloadFile
}