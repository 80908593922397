import { Button, Hidden, Icon, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const ButtonLink = ({ link, item, title, icon,color="info", variant = "contained",size="small", onButtonClick,target,...rest }) => {
  const { t } = useTranslation(["common"]);
  const useStyles = makeStyles((theme) => ({
    buttonSpace: {
        margin: theme.spacing(1),
    },
  }));
const classes=useStyles()
  return (
    <React.Fragment>
      <Hidden only={["xs", "sm"]}>
        {link ? (
          <Link to={link} target={target} {...rest} >
            <Button
              title={title}
              color={color}
              variant={variant ? variant : "contained"}
              size={size}
              startIcon={icon? <Icon  >{icon}</Icon>:"" }
              {...rest}
            >
              {t("common:" + title)}
            </Button>
          </Link>
        ) : (
          <Button
          title={title}
            color={color}
            variant={variant ? variant : "contained"}
            className={classes.buttonSpace}
            size={size}
            onClick={(event) => onButtonClick(item,event)}
            startIcon={icon? <Icon  >{icon}</Icon>:"" }
            {...rest}
          >
            {title && t("common:" + title)}
          </Button>
        )}
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        {link ? (
          <Link to={link}>
            <IconButton title={title} color={color} aria-label={title} {...rest} size="large">
              <Icon>{icon}</Icon>
            </IconButton>
          </Link>
        ) : (
          // <Link to={link}>
            <IconButton
              title={title}
              color={color}
              aria-label={title}
              onClick={(event) => onButtonClick(item,event)}
              {...rest}
              size="large">
              <Icon>{icon}</Icon>
            </IconButton>
          // </Link>
        )}
      </Hidden>
    </React.Fragment>
  );
};

export default ButtonLink;
