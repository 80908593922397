import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import authService from "../../../services/authService";
import enquiryService from "../../../services/enquiryService";
import logger from "../../../services/logger";
import MatInputBase from "../../common/matInputBase";
import MatTablePagination from "../../common/matTablePagination";
import PagingAndSorting from "../../common/pagingAndSorting";
import EnquiriesQueueTable from "./enquiriesQueueTable";

class EnquiriesQueue extends PagingAndSorting {
  state = {
    data: [],
    enquiryType: "ALL",
    allocatedTo: "ME",
    sortColumn: { path: "date", order: "desc" },
    page: 0,
    rowsPerPage: 10,
    totalElements: 0,
    confirmDialogOpen: false,
    formDialogOpen: false,
  };

  componentDidMount() {
    const role = authService.getCurrentRole();

    this.getPagedData(this.query());
  }

  query(search = "") {
    const { page, rowsPerPage: size, sortColumn } = this.state;
    const sort = sortColumn.path + "," + sortColumn.order;
    return { search, page, size, sort };
  }

  handleInputBaseClick = async (item) => {
    this.getPagedData({
      ...this.query(item),
    });
  };

  getPagedData = async (query) => {
    try {
      const { data, headers } = await enquiryService.getAllByEnquiryStatus({
        ...query,
        enquiryStatus: "ENQUIRED",
      });
      const totalElements = headers["x-total-elements"];
      this.setState({ data, totalElements });
    } catch (ex) {
      logger.error(ex);
    }
  };

  handleChange = async (event, item) => {
    //console.log("event: ", event.target.checked);
    //console.log("item : ", { ...item });

    await enquiryService.update({ ...item, waiting: event.target.checked });
    await this.getPagedData(this.query());
  };

  onConfirmOk = async (id) => {
    try {
      await enquiryService.deleteById(id);
      await this.getPagedData(this.query());
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const {
      sortColumn,
      data,
      rowsPerPage,
      page,
      totalElements,
      confirmDialogOpen,
      formDialogOpen,
    } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item xs={12} md={3}>
              <MatInputBase
                txtLabel="Search by Enquiry #"
                btnLabel="serach"
                icon="search"
                onInputBaseClick={this.handleInputBaseClick}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MatInputBase
                txtLabel="Search by mobile"
                btnLabel="serach"
                icon="search"
                onInputBaseClick={this.handleInputBaseClick}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EnquiriesQueueTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onChange={this.handleChange}
            ></EnquiriesQueueTable>
            <MatTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>

        {confirmDialogOpen &&
          this.renderDeleteConfirmDialog(
            t("Delete Confirmation"),
            t("Are you sure you want to delete?")
          )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["enquiries"])(EnquiriesQueue);
