import http from "./httpService";

const apiEndpoint = "/api/holiday-calendars";

function create(data) {
  return http.post(apiEndpoint, data);
}

function update(data) {
  return http.put(`${apiEndpoint}/${data.id}`, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(apiEndpoint);
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  deleteById,
};
