import http from "./httpService";

// const apiEndpoint = "/hedge-brown-server/api/degree-or-equilnts";
const apiEndpoint = "/api/degree-or-equilnts";

function createProgram(program) {
  return http.post(apiEndpoint, program);
}

function updateProgram(program) {
  return http.put(apiEndpoint, program);
}

function getProgram(programId) {
  return http.get(`${apiEndpoint}/${programId}`);
}

function getPrograms() {
  return http.get(apiEndpoint);
}

function getDegreesByInstitute(instituteId) {
  return http.get(`${apiEndpoint}/institute-id/${instituteId}`);
}


function deleteProgram(programId) {
  return http.delete(`${apiEndpoint}/${programId}`);
}

export default {
  createProgram,
  updateProgram,
  getProgram,
  getPrograms,
  getDegreesByInstitute,
  deleteProgram,
};
