import { Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../../services/logger";
import Add from "../../common/add";
import PagingAndSorting from "../../common/pagingAndSorting";
import FormDialog from "../../common/formDialog";
import MileagesTable from "./mileagesTable.page";
import MileageFormPage from "./mileageForm.page";
import  mileageService  from "./mileage.service";
class Mileages extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    confirmDialogOpen: false,
    numOfStopsDilog:false,
    pagination: { page: 0, rowsPerPage: 25, count: 0, onPageChange: this.handlePageChange, onRowsPerPageChange: this.handleRowsPerPageChange }

  };

  componentDidMount() {
   
    this.getPagedData({ ...this.query() });
  }


  onConfirmOk = async (id) => {
    try {
      await mileageService.deleteById(id);
      await this.getPagedData({ ...this.query() });
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog=()=>{
    this.setState({dialogOpen:false});
    this.getPagedData({...this.query()});
  }


  getPagedData = async (query) => {
    try {
      const busId = this.props.match.params.busId;
      const { data, headers } = await mileageService.getAllByBus(busId,query);
      const totalElements = headers["x-total-elements"];
      const pagination = { ...this.state.pagination };
      pagination.count = totalElements;
      this.setState({ data, pagination });
    } catch (ex) {
      logger.error(ex);
    }
  };



  render() {
    const { sortColumn, data, pagination, confirmDialogOpen,dialogOpen,id } = this.state;
    const { t } = this.props;
    const busId = this.props.match.params.busId;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid container item sm={12} md={12} direction="row-reverse">
              <Add onAdd={this.handleAdd} item={{id:"new"}} title={t("Add Mileage Details")} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MileagesTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              pagination={pagination}
              t={t}
            />
          </Grid>
        </Grid>
        {
          dialogOpen &&
          <FormDialog
            title={t("Mileage Details")}
            content={<MileageFormPage id={id ? id : "new"} busId={busId} onSave={this.closeDialog}/>}
            open={dialogOpen}
            onClose={()=>this.setState({dialogOpen:false})}
          
          />
        }

        {confirmDialogOpen && this.renderDeleteConfirmDialog(
          "Delete Confirmation",
          "Are you sure you want to delete?"
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["mileage", "common"])(Mileages);
