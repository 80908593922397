import React, { Component } from "react";
import enquiryService from "../../../services/enquiryService";
import PaymentAndReciepts from "./paymentAndReceipts";
import PaymentAndReceiptForm from "./paymentAndReceiptForm";

class PaymentAndReceipt extends Component {
    constructor() {
        super();
        this.state = {
            data: {},
            fee: null
        }
    }
    async componentDidMount() {
        const { id } = await this.props.user;
        //console.log("user in payment reciept:", this.props.user.id);
        const { data } = await enquiryService.getEnquiryWithApplicationFee(id);
        //console.log("data payment reciept:", data);
        // const fee = [{ ...data }]
        this.setState({ fee:[{...data}]})
        //console.log("data componentDidMount:",this.state.fee);
        this.setState({ data });
    }

    render() {
        const { data, fee } = this.state;
        return (<div>
            {(data.paymentStatus === "PAYMENT_PENDING" )? <PaymentAndReceiptForm data={data} {...this.props} /> : (data.paymentStatus === "PAYMENT_DONE" ? <PaymentAndReciepts data={fee} {...this.props} /> : <h2>Please apply for the course first  </h2>)}
        </div>)
       
        // if(data.paymentStatus === "PAYMENT_PENDING"){

        // }
       
        // return (
        //     <div>
        //         {fee && <PaymentAndReciepts data={fee} {...this.props} type={"APPLICATION_FEE"} />}
        //     </div>
        // )
    }
}

export default PaymentAndReceipt;