import React from "react";
import { withTranslation } from "react-i18next";
import departmentService from "../../services/departmentService";
import OrgContext from "../app-context";
import { getDynamicFormJson } from "../common/dynamicFormService";
import Form from "../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";

class DepartmentForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("department");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  
  async populateForm() {
    const id = this.props.id?this.props.id:this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await departmentService.get(id);
      this.mapToViewModel(data);
    } catch (ex) { }
  }

  async componentDidMount() {
   await this.populateForm();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new") await departmentService.create({...this.state.data,type:this.props.type});
      else await departmentService.update({...this.state.data,type:this.props.type});
      // this.props.history.goBack();
      this.props.onSave();
    } catch (ex) {
      // logger.log(ex.response.data.message);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.id?this.props.id:this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      // <Card>
          // <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

        </form>
      // </Card>
    );
  }
}
DepartmentForm.contextType = OrgContext;
export default withTranslation(["department", "common"])(DepartmentForm);
