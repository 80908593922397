import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BOARD_OR_UNIVERSITY_ROUTE } from "../../data/routerConstants";
import Delete from "../common/delete";
import Edit from "../common/edit";
import MatTable from "../common/matTable";

const BoardOrUniversitiesTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["boardOrUniversity"]);
  const columns = [
    { path: "id", label: t("ID") },
    {
      path: "name",
      label: t("Name"),
      content: (boardOrUniversity) => {
        return (
          <Link to={`board-or-universities/${boardOrUniversity.id}`}>
            {boardOrUniversity.name}
          </Link>
        );
      },
    },
    { path: "alias", label: t("Alias") },
    { path: "addressLine1", label: t("Address") },
    {
      key: "action",
      content: (item) => {
        return <Edit link={`${BOARD_OR_UNIVERSITY_ROUTE}/${item.id}`}/>;
      },
    },
    {
      key: "delete",
      content: (boardOrUniversity) => (
        <Delete onDelete={onDelete} item={boardOrUniversity} />
      ),
    },
  ];

  return (
    <MatTable
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default BoardOrUniversitiesTable;
