import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import boardOrUniversityService from "../../services/boardOrUniversityService";
import logger from "../../services/logger";
import PagingAndSorting from "../common/pagingAndSorting";
import BoardOrUniversitiesTable from "./boardOrUniversitiesTable";

class BoardOrUniversities extends PagingAndSorting {
  state = {
    boardOrUniversities: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
  };

  getBoardOrUniversities = async () => {
    try {
      const {
        data: boardOrUniversities,
      } = await boardOrUniversityService.getBoardOrUniversities();
      this.setState({ boardOrUniversities });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteBoardOrUniversity = async (id) => {
    try {
      await boardOrUniversityService.deleteBoardOrUniversity(id);
      await this.getBoardOrUniversities();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getBoardOrUniversities();
  }

  onConfirmOk = (id) => {
    this.deleteBoardOrUniversity(id);
  };

  render() {
    const { sortColumn, boardOrUniversities } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            direction="row-reverse"
          >
            <Button variant="outlined" color="primary">
              <Link to={"board-or-universities/new"}>
                {t("New University")}
              </Link>
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BoardOrUniversitiesTable
              data={boardOrUniversities}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></BoardOrUniversitiesTable>
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["boardOrUniversity"])(BoardOrUniversities);
