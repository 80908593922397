import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";

const EnquiryWaitingTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiries", "common"]);
  const columns = [
    
    { path: "userName", label: t("Name") },
    {
      path: "programAlias",
      label: t("Branch"),
    },
    { path: "counsellorName", label: t("Allocated") },
  ];

  return (
    <MatTable
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EnquiryWaitingTable;
