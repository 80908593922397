import TablePagination from "@mui/material/TablePagination";
import React from "react";
const MatTablePagination = (props) => {
  const {
    count,
    rowsPerPage,
    rowsPerPageOptions,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props;

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
    />
  );
};

export default MatTablePagination;
