import authService from "./authService";
import http from "./httpService";

const apiEndpoint = "/api/enquiry-document-stores";

async function create(data) {
  let formData = new FormData();

  formData.append("document", data.document);
  formData.append("documentName", data.documentName);
  formData.append("documentUrl", data.documentUrl);

  // return uploadFilesService.uploadWithFormData(
  //   formData,
  //   "/enquiry-document-stores"
  // );
  // const file = await toBase64(data.document);
  return http.post(apiEndpoint, {
    ...data
   
  });
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function update(data) {
  return http.put(apiEndpoint, data);
}

function get(id) {
  return http.get(`${apiEndpoint}/${id}`);
}
function getAll() {
  return http.get(apiEndpoint, {
    params: { userId: authService.getCurrentUser().id },
  });
}

function getAllByUser() {
  return http.get(apiEndpoint + "/user/" + authService.getCurrentUser().id, {
    params: { userId: authService.getCurrentUser().id },
  });
}
function getAllByUserId(userId) {
  return http.get(apiEndpoint + "/user/" + userId, {
    params: { userId: userId },
  });
}

function deleteById(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

export default {
  create,
  update,
  get,
  getAll,
  getAllByUser,
  getAllByUserId,
  deleteById,
};
