import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import MatCircularProgressbar from "../../common/matCircularProgressbar";

const useStyles = makeStyles((theme) => ({
  dashboardCard: {
    height: "100%",
    [theme.breakpoints.up('md')]: {
      height: "325px"
    }
  }
}))

function getText(key, data) {
  return (
    <ListItem button>
      <ListItemText
        primary={
          <div style={{ margin: 0 }}>
            <span> {key}</span>
            <div style={{ fontSize: 20, float: "right" }}>
              <strong>{data}</strong>
            </div>
          </div>
        }
      />
    </ListItem>
  );
}
const EnquiryStatsCard = ({ data, onClick, isSummaryRequired = true, title }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Card className={classes.dashboardCard}
      // onClick={()=>onClick("/admission-enquiries")} 
      >
        <CardHeader title={title ? title : "Enquiry/Leads"}></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            {isSummaryRequired && <Grid item xs={12} md={6}>
              <List component="nav" aria-label="mailbox folders">
                {getText("Today ", data.today)}
                <Divider />
                {getText("Cumulative  ", data.enquiryCount)}
                <Divider />
                {getText("Days  ", data.days)}
                <Divider />
                {getText("Percentage  ", data.percentage + "%")}
                <Divider />
              </List>
            </Grid>}
            <Divider />
            <Grid container item xs={12} md={isSummaryRequired ? 6 : 12} alignItems={"center"} direction="row">
              <div style={{ margin: "auto" }}>
                <MatCircularProgressbar value={data.percentage}
                  content={
                    <div style={{ marginTop: -5, textAlign: "center" }}>
                      <h2 style={{ margin: 0 }}>Target</h2>
                      <strong>
                        {data.enquiryCount}/{data.targetValue}
                      </strong>
                    </div>
                  }
                />
                {/* <CircularProgressbarWithChildren value={data.percentage}  >
                <div style={{ marginTop: -5, textAlign: "center" }}>
                  <h2 style={{ margin: 0 }}>Target</h2>
                  <strong>
                    {data.enquiryCount}/{data.targetValue}
                  </strong>
                </div>
              </CircularProgressbarWithChildren> */}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EnquiryStatsCard;
