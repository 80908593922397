import {
  AppBar,
  Box,
  
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { PanoramaFishEye } from "@mui/icons-material";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SchoolIcon from "@mui/icons-material/School";
import React from "react";
import { useTranslation } from "react-i18next";
import authService from "../../services/authService";
import AddressForm from "./address/addressForm";
import Educations from "./education/educations";
import StudentPersonalInfoForm from "./personal-info/studentPersonalInfoForm";
import ApplicationPreviewForm from "./preview/applicationPreviewForm";
import UploadDocs from "./upload-docs/uploadDocs";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    // marginRight: theme.spacing(1),
  },
  instructions: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  
  headerBorder: {
    borderBottomStyle: "solid",
    borderWidth: "1px",
    borderBottomColor: theme.palette.primary.main,
  },
}));
function getSteps() {
  return [
    "Personal Info",
    "Address Details",
    "Education Details",
    "Upload Documents",
  ];
}

export default function MyProfile() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePreview = () => {
    //console.log("in preview");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

 

 
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }
  return (
    <div className={classes.root}>
      <div className={classes.tabBorder}>
        <AppBar position="static"  color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            // variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            // centered={true}
            // className={classes.headerBorder}
          >
            <Tab
              label="Applicant Details"
              icon={<PersonPinIcon />}
              {...a11yProps(0)}
            />
            {/* <Tab
              label="Address"
              icon={<PermContactCalendarIcon />}
              {...a11yProps(1)}
            /> */}
            <Tab label="Education" icon={<SchoolIcon />} {...a11yProps(1)} />
            <Tab
              label="Upload Documents"
              icon={<CollectionsBookmarkIcon />}
              {...a11yProps(3)}
            />
             <Tab
              label="Preview & Submit"
              icon={<PanoramaFishEye />}
              {...a11yProps(2)}
            />
            {/* <Tab label="Item Five" {...a11yProps(4)} />
            <Tab label="Item Six" {...a11yProps(5)} />
            <Tab label="Item Seven" {...a11yProps(6)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel key={1} value={value} index={0}>
          <StudentPersonalInfoForm
            activeStep={activeStep}
            stepsLength={steps.length}
            onBack={handleBack}
            onNext={handleNext}
            user={authService.getCurrentUser()}
          />
        </TabPanel>
        {/* <TabPanel key={2} value={value} index={1}>
          <AddressForm
            activeStep={activeStep}
            stepsLength={steps.length}
            onBack={handleBack}
            onNext={handleNext}
            user={authService.getCurrentUser()}
          />
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <Educations
            activeStep={activeStep}
            stepsLength={steps.length}
            onBack={handleBack}
            onNext={handleNext}
            user={authService.getCurrentUser()}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UploadDocs user={authService.getCurrentUser()} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ApplicationPreviewForm
            activeStep={activeStep}
            stepsLength={steps.length}
            onBack={handleBack}
            onNext={handleNext}
            user={authService.getCurrentUser()}
          />
        </TabPanel>
      </div>
      <div>
        
      </div>
    </div>
  );
}
