import { Icon, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const FeeStructureTable = ({ data, sortColumn, onSort, onDelete,onEdit }) => {
  const { t } = useTranslation(["feeItem"]);
 
  const columns = [
    {
      path: "name",
      label: t("Name")
    },
    {
      path: "description",
      label: t("Remarks")
    },     
    {
      key: "action",
      content: (item) => {
        return <>
        <Edit link={"/admission-fee-structure/"+item.id+"/setup"} title={t("Setup")}/> &nbsp;&nbsp;
        <Edit item={item} onEdit={onEdit}/> &nbsp;&nbsp;
        <Delete onDelete={onDelete} item={item} />
        </>;
      },
    }
  ];

  return (
    <MatTable
      heading={"List of Fee Structures"}
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default FeeStructureTable;
