import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  getApplicationFeeOptions,
  getApplicationFormStatus,
  getGraduationTypes,
  getStreams,
} from "../../../data/enums";
import { ADMISSION_APPLICATION_FORM_FEE_CONFIG_ROUTE } from "../../../data/routerConstants";
import applicationFeeConfigService from "../../../services/applicationFeeConfigService";
import degreeService from "../../../services/degreeService";
import instituteService from "../../../services/instituteService";
import logger from "../../../services/logger";
import programService from "../../../services/programService";
import BackButton from "../../common/backButton";
import { getDynamicFormJson } from "../../common/dynamicFormService";
import Form from "../../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../../common/formUtils";

class ApplicationFeeConfigForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("addmissionApplictionFee");
    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  getEnumValue() {
    try {
      const applicationFormStatus = getApplicationFormStatus();
      const graduationTypes = getGraduationTypes();
      const streams = getStreams();
      const applicationOptions = getApplicationFeeOptions();

      const dropDowns = this.state.dropDowns;
      dropDowns.applicationFormStatus = applicationFormStatus;
      dropDowns.graduationTypes = graduationTypes;
      dropDowns.streams = streams;
      dropDowns.applicationOptions = applicationOptions;

      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("applicationFormStatus ", ex);
    }
  }

  getInstitutes = async () => {
    try {
      const { data } = await instituteService.getInstitutes();
      const dropDowns = this.state.dropDowns;
      dropDowns.institutes = data;
      this.setState({ dropDowns });
      if (this.state.data.instituteId)
        this.onSelectOf("instituteId", this.state.data.instituteId);
    } catch (ex) {
      logger.error(ex);
    }
  };

  getPrograms = async (instituteId) => {
    try {
      const { data } = await programService.getAll();
      const dropDowns = this.state.dropDowns;
      dropDowns.programs = data;
      this.setState({ dropDowns });
    } catch (ex) {
      logger.error("programs ", ex);
    }
  };

  onSelectOf = async (item, value) => {

    switch (item) {
      case "instituteId":
        await this.getPrograms(value);
        break;
    }
  };

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {
      const { data } = await applicationFeeConfigService.get(id);
      this.mapToViewModel(data);
    } catch (ex) {
      logger.error(ex);
    }
  }

  async componentDidMount() {
    await this.populateForm();
    await this.getPrograms(1);
    this.getEnumValue();
  }

  doSubmit = async (id) => {
    try {
      if (id === "new") {
        await applicationFeeConfigService.create(this.state.data);
      } else {
        await applicationFeeConfigService.update(this.state.data);
      }
      this.props.history.push(ADMISSION_APPLICATION_FORM_FEE_CONFIG_ROUTE);
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);

    return (
      <Card>
        <CardHeader title={t(titleText)} action={<BackButton {...this.props} />} />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>
          {this.renderForm(this.formJson, dropDowns)}
        </form>
      </Card>
    );
  }
}

export default withTranslation(["applicationFeeConfigs","program", "common"])(
  ApplicationFeeConfigForm
);
