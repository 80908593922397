import { Button, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import applicationFeeConfigService from "../../../services/applicationFeeConfigService";
import logger from "../../../services/logger";
import Add from "../../common/add";
import ButtonLink from "../../common/buttonLink";
import PagingAndSorting from "../../common/pagingAndSorting";
import ApplicationFeeConfigsTable from "./applicationFeeConfigsTable";

class ApplicationFeeConfigs extends PagingAndSorting {
  state = {
    data: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    confirmDialogOpen: false,
  };

  componentDidMount() {
    this.getAll();
  }

  onConfirmOk = (id) => {
    this.deleteById(id);

  };

  getAll = async () => {
    try {
      const { data } = await applicationFeeConfigService.getAll();
      this.setState({ data });
    } catch (ex) {
      logger.error(ex);
    }
  };

  deleteById = async (id) => {
    try {
      await applicationFeeConfigService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  render() {
    const { sortColumn, data } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid container item xs={12} sm={12} direction="row-reverse">
            <Add link={"/admission-application-form-fee-configs/new"} title={t("New Application Fee")} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ApplicationFeeConfigsTable
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            ></ApplicationFeeConfigsTable>
          </Grid>
        </Grid>

        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation(["applicationFeeConfigs"])(
  ApplicationFeeConfigs
);
