import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import logger from "../../services/logger";
import PagingAndSorting from "../common/pagingAndSorting";
import departmentService from "../../services/departmentService";
import Add from "../common/add";
import DepartmentForm from "./departmentForm";
import FormDialog from "../common/formDialog";
import Delete from "../common/delete";
import Edit from "../common/edit";
import Employees from "../employee/employees";

class Departments extends PagingAndSorting {
  state = {
    programs: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    expanded:""
  };

  getAll = async () => {
    try {
      const { data} = await departmentService.getAllByType(this.props.type);
      if (data && data.length > 0)
      this.setState({ expanded: data[0]?.id })
      this.setState({ data });
    } catch (ex) {
      console.log("departments ", ex);
    }
  };

  deleteById = async (id) => {
    try {
      await departmentService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  componentDidMount() {
    this.getAll();
  }

  onConfirmOk = (id) => {
    this.deleteById(id);
  };
  closeDialog = () => {
    this.setState({ dialogOpen: false });
    this.getAll();
  };

  accordionList(data) {
    const { t } = this.props;
    if (data && data.length > 0) {
      return (
        <div>
          <h3 style={{ textAlign: "center" }}>{t("List of Teaching Departments")}</h3>
          {data && data.map((item, index) => (
            <Accordion key={item.id} expanded={this.state.expanded === item.id} onChange={() => this.handleAccordionExpandChange(item.id)}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id={"accordion" + item.id}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography color="secondary">{index + 1}. {item.name}</Typography>
                {/* <Typography> {item.remarks} </Typography> */}
                <div>
                  <Edit onEdit={this.handleEdit} item={item} iconOnly={true} />
                  <Delete onDelete={this.handleDelete} item={item} iconOnly={true} />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Employees department={item} departmentId={item.id} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      );
    } else {
      return <>
        <Grid>
          <Card>
            <CardContent style={{ textAlign: "center" }}>
              <h2 > {t("Departments not created yet. Click 'Add Department' to start creating.")} </h2>
              <Add item={{ id: "new" }} title={t("Add Department")} onAdd={this.handleAdd} type="fab" />
            </CardContent>
          </Card>
        </Grid>
      </>
    }
  }

  render() {
    const {  data,dialogOpen,id } = this.state;
    const { t,type } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            direction="row-reverse"
          >           
            <Add onAdd={this.handleAdd} item={{id:"new"}} title={t("New Department")}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* <DepartmentTable
              heading={"List of Teaching Departments"}
              data={data}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
            /> */}
            {this.accordionList(data)}
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}

      {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={"Department Details"}
            content={
              <DepartmentForm
                type={type}
                id={id ? id : "new"}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}

      </React.Fragment>
    );
  }
}

export default withTranslation(["department"])(Departments);
