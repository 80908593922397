import admin from "../data/nav/admin.json";
import counsellor from "../data/nav/counsellor.json";
import receptionist from "../data/nav/receptionist.json";
import student from "../data/nav/student.json";
import system_admin from "../data/nav/system_admin.json";
import hr from "../data/nav/hr.json";
import md from "../data/nav/md.json"
import principal from "../data/nav/principal.json"
import admission_manager from "../data/nav/admission_manager.json"
import syniza_md from "../data/nav/syniza_md.json"
import iqac_coordinator from "../data/nav/iqac_coordinator.json"
import syniza_principal from "../data/nav/syniza_principal.json"
import syniza_hr from "../data/nav/syniza_hr.json"
import authService from "./authService";

function setHeaderTitle(key,title){
  localStorage.setItem(key,title)
}

export function getHeaderTitle(key){
  return localStorage.getItem(key);
}

export function getNavItems(roleName = "new") {
  const role = authService.getCurrentRole();
  switch (role.toLowerCase()) {
    case "hr":
      setHeaderTitle(role,"Human Resources");
      return syniza_hr;
    case "md":
      setHeaderTitle(role,"MD")
      return syniza_md;
    case "principal":
      setHeaderTitle(role,"Principal")
      return syniza_principal;
      case "iqac_coordinator":
        setHeaderTitle(role,"IQAC");
        return iqac_coordinator;
    case "admission_manager":
      setHeaderTitle(role,"Admission Manager")
      return admission_manager;
    case "admin":
      return admin;
    case "system_admin":
      setHeaderTitle(role, "System Admins")
      return system_admin;
    case "student":
      return student;
    case "enquirer":
      setHeaderTitle(role, "Student")
      return student;
    case "counsellor":
      setHeaderTitle(role, "Counsellor")
      return counsellor;
    case "receptionist":
      return receptionist;
    default:
      alert("Navigation for the role: " + role + " is not configured yet");
  }
}
