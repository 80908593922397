import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";

const EnquiryDocumentConfigTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["enquiryDocumentConfig"]);
  const columns = [
    // { path: "id", label: t("id") },
    {
      path: "name",
      label: t("Document Name"),
      content: (doc) => {
        return (
          <Link to={`enquiry-document-config/${doc.id}`}> {doc.name} </Link>
        );
      },
    },
    { path: "documentSizeInKb", label: t("Document Size in KB") },
    { path: "documentType", label: t("Document Type") },
    {
      key: "action",
      content: (item) => {
        return <> <Edit link={`enquiry-document-config/${item.id}`}/> &nbsp;  <Delete onDelete={onDelete} item={item} /></>
      },
    }
  ];

  return (
    <MatTable
      data={data}
      heading="List of Required Documents"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default EnquiryDocumentConfigTable;
