import React from "react";
import { useTranslation } from "react-i18next";
import Delete from "../../common/delete";
import Edit from "../../common/edit";
import MatTable from "../../common/matTable";
import ButtonLink from "../../common/buttonLink";

const HostelTable = ({ data, sortColumn, onSort, onDelete,onNumOfBlocksClick,pagination }) => {
  const { t } = useTranslation(["admissionCalendar","common"]);
  const columns = [
    // { path: "id", label: t("ID") },

    { path: "name", label: t("Hostel Name"),
    content:(item)=>{
     return <ButtonLink variant="text" icon={"apartment"} title={item.name}></ButtonLink>;
    }
  },
    { path: "numOfBlocks", label: t("# of Blocks"),numeric:true,
    key:"action",
    content:(item)=>{
      return<>
      <ButtonLink icon="visibility" variant="text" title={item.numOfBlocks} onButtonClick={()=>onNumOfBlocksClick(item,"dialogOpen")}/>
      </>
    }

  },
  { path: "wardenName", label: t("Warden Name")},
  { path: "managerName", label: t("Manager Name")},
  {
    key: "action",
    content: (item) => {
      return <> 
      <Edit link={`buses/${item.id}`} /> &nbsp; 
      <Delete onDelete={onDelete} item={item} /></>;
    },
  }
  ];

  return (
    <MatTable
      data={data}
      heading={t("List of Buses")}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
      paginate={true}
      pagination={pagination}
    />
  );
};

export default HostelTable;
