import { DatePicker,DateTimePicker,TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import React from 'react';

const MatDatePicker = ({date,label,onChange}) => {
    return ( <>

   <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableToolbar={false}
                autoOk
                variant="dialog"
                inputVariant={"outlined"}
                size="small"
                format="DD-MMM-YYYY"
                id={"date-picker-inline"+label}
                slotProps={{textField:{size:"small",}}}
                
                label={label}
                value={date ? dayjs(date) : null}
                onChange={onChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              </LocalizationProvider>
              &nbsp;&nbsp;
    </> );
}
 
export default MatDatePicker;