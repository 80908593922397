import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import logger from "../../services/logger";
import Form from "../common/form";
import { getJoiSchema, getStateObject } from "../common/formUtils";
import uploadFilesService from "../../services/uploadFilesService";
import { getDynamicFormJson } from "../common/dynamicFormService";
import downloadFileService from "../../services/downloadFileService";
class UploadEmployeesForm extends Form {
  constructor() {
    super();
    this.formJson = getDynamicFormJson("uploadEmployees");
    this.state = {
      data: getStateObject(this.formJson.groups),
      selectedFiles: [],
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }


  handleClick=(label)=>{
    downloadFileService.downloadFile("employees/download-template","employees.xlsx");
  }

  async componentDidMount() {
    // await this.populateForm();
    // this.getPrograms();
  }

  doSubmit = async (id) => {
    const {departmentId}=this.props;
    try {
      if (id === "new") {
        await uploadFilesService
          .upload(
            this.state.selectedFiles[0],
            "/employees/upload?departmentId="+departmentId,
            (event) => {
              this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
              });
            }
          )
          .then((response) => {
            this.props.onClose();
            this.setState({
              message: response.data.message,
              isError: false,
            });
          })
          .catch(() => {
            this.setState({
              progress: 0,
              message: "Could not upload the file!",
              currentFile: undefined,
              isError: true,
            });
          });
      } else {
        // await service.update(this.state.data);
      }
      // this.props.history.push("/admission-enquiries");
      this.props.onSave()
    } catch (ex) {
      logger.log(ex);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns, progress } = this.state;

    return (
      <Card>
        <form onSubmit={(event) => this.handleSubmit(event, "new")}>
          {/* <CardHeader title={t(titleText)} /> */}

          {this.renderForm(this.formJson, dropDowns,false)}

          <CardContent>
            <Grid container item sm={12} md={12} direction="row-reverse">
              {this.renderMatButton(t("Upload"),"submit","secondary",false,"upload")}
              {/* <ButtonLink size="small" icon={"download"} title={"Download Template"} onButtonClick={this.handleDownload} /> */}
              {/* <Button size="small" title="Download Template" color="primary" variant="contained" onClick={this.handleDownload}>Download Template</Button> */}
              {this.renderMatButton(t("common:Download Template"), "button", "secondary",false,"download")}
            </Grid>
          </CardContent>
        </form>
      </Card>
    );
  }
}

export default withTranslation(["uploadEnquiriesForm", "common"])(
  UploadEmployeesForm
);
