import { Card, CardHeader } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import OrgContext from "../app-context";
import { getDynamicFormJson } from "../common/dynamicFormService";
import Form from "../common/form";
import {
  getFormButtonText,
  getFormTitleText,
  getJoiSchema,
  getStateObject,
} from "../common/formUtils";
import ticketService from "../../services/ticketService";
import BackButton from "../common/backButton";

class ResolveTicketForm extends Form {
  constructor() {
    super();

    this.formJson = getDynamicFormJson("resolveTicket");

    this.state = {
      data: getStateObject(this.formJson.groups),
      errors: {},
      dropDowns: {},
    };
    this.schema = getJoiSchema(this.formJson.groups);
  }

  async populateForm() {
    const id = this.props.match.params.id;
    if (id === "new") return;
    try {

      const { data } = await ticketService.get(id);

      this.mapToViewModel(data);
    } catch (ex) { }
  }

  async componentDidMount() {

    await this.populateForm();
  }

  doSubmit = async (id) => {
    try {

      if (id === "new") await ticketService.create(this.state.data);
      else await ticketService.update(this.state.data);
      this.props.history.goBack();
    } catch (ex) {
      // logger.log(ex.response.data.message);
    }
  };

  render() {
    const { t } = this.props;
    const { dropDowns } = this.state;
    const id = this.props.match.params.id;

    const buttonText = getFormButtonText(id);

    const titleText = getFormTitleText(id, this.formJson.alias);
    return (
      <Card>
        <CardHeader title={t("Update the Ticket")} action={<BackButton {...this.props} />} />
        <form onSubmit={(event) => this.handleSubmit(event, id)}>

          {this.renderForm(this.formJson, dropDowns)}

        </form>
      </Card>
    );
  }
}
ResolveTicketForm.contextType = OrgContext;
export default withTranslation(["tickets", "common"])(ResolveTicketForm);
