import { Icon } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
const MatInputBase = ({ input,txtLabel, icon, btnLabel, onInputBaseClick,onChange }) => {
  const classes = useStyles();

  // const [input, setInput] = useState(null);
  const handleChange = ({ target }) => {
    //console.log("inputbase event: ", target);
    // setInput(target.value);

  };

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={txtLabel}
        inputProps={{ "aria-label": txtLabel }}
        value={input}
        onChange={(event)=>onChange(event.target.value)}
      />

{/* <Divider className={classes.divider} orientation="vertical" /> */}
      {input?
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={() => onInputBaseClick(input)}
        size="large">
      <Icon>{"close"}</Icon>
      </IconButton>
      :
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="directions"
        onClick={() => onInputBaseClick(input)}
        size="large">
      <Icon>{icon}</Icon>
      </IconButton>
      }
    </Paper>
  );
};

export default MatInputBase;
