import { func } from "joi";

export function getGraduationTypes() {
  return [
    { id: "DIPLOMA", name: "DIPLOMA" },
    { id: "PU", name: "PU" },
    { id: "UG", name: "UG" },
    { id: "PG", name: "PG" },
  ];
}

export function getStreams() {
  return [
    { id: "SCIENCE", name: "SCIENCE" },
    { id: "ARTS", name: "ARTS" },
    { id: "COMMERCE", name: "COMMERCE" },
    { id: "TECHNOLOGY", name: "TECHNOLOGY" },
  ];
}

export function getDeptSubTypes() {
  return [
    { id: "PROGRAM", name: "PROGRAM" },
    { id: "OPTIONAL", name: "OPTIONAL" },
    { id: "NA", name: "NA" },
  ];
}

export function getDegreeOrDeptTypes() {
  return [
    { id: "ACADEMIC", name: "ACADEMIC" },
    { id: "NON_ACADEMIC", name: "NON ACADEMIC" },
    { id: "NA", name: "NA" },
  ];
}

export function getBloodGroups() {
  return [
    { id: "A+", name: "A+" },
    { id: "A-", name: "A-" },
    { id: "B+", name: "B+" },
    { id: "B-", name: "B-" },
    { id: "O+", name: "O+" },
    { id: "O-", name: "O-" },
    { id: "AB+", name: "AB+" },
    { id: "AB-", name: "AB-" },
  ];
}

export function getGenders() {
  return [
    { id: "FEMALE", name: "Female" },
    { id: "MALE", name: "Male" },
    { id: "OTHER", name: "Other" },
  ];
}

export function getNationality() {
  return [
    { id: "INDIAN", name: "Indian" },
    { id: "CANADIAN", name: "Canadian" },
    { id: "OTHER", name: "Other" },
  ];
}

export function getReligion() {
  return [
    { id: "HINDI", name: "Hindu" },
    { id: "MUSLIM", name: "Muslim" },
    { id: "SIKH", name: "Sikh" },
  ];
}

export function getMotherTongue() {
  return [
    { id: "KANNADA", name: "Kannada" },
    { id: "HINDI", name: "Hindi" },
    { id: "TAMIL", name: "Tamil" },
  ];
}

export function getResultTypes() {
  return [
    { id: "CGPA", name: "CGPA" },
    { id: "PERCENTAGE", name: "Percentage" },
  ];
}

export function getApplicationFormStatus() {
  return [
    { id: "OPEN", name: "OPEN" },
    { id: "CLOSED", name: "CLOSED" },
    { id: "YET_TO_OPEN", name: "YET TO OPEN" },
  ];
}

export function getApplicationFeeOptions() {
  return [
    { id: "1", name: "Pay First" },
    { id: "0", name: "Pay Later" },
    { id: "-1", name: "No Fee" },
  ];
}

export function getEnquiryTypes() {
  return [
    {
      id: "A",
      name: "A",
    },
    {
      id: "B",
      name: "B",
    },
    {
      id: "C",
      name: "C",
    },
  ];
}

export function getFollowupTypes() {
  return [
    { id: "CALL", name: "CALL" },
    { id: "SMS", name: "SMS" },
    { id: "EMAIL", name: "E-Mail" },
    { id: "VISIT", name: "Visit" },
  ];
}

export function getTandCTypes() {
  return [
    { id: "PRE_ADMISSION", name: "Pre Admission" },
    { id: "ADMISSION", name: "Admission" },
    { id: "FEE", name: "Fee" },
  ];
}

export function getDocumentTypes() {
  return [
    { id: "JPEG", name: "JPEG" },
    { id: "PNG", name: "PNG" },
    { id: "PDF", name: "PDF" },
  ];
}

export function getProgramTypes(instituteType) {
  //console.log("insti type:",instituteType);
  switch (instituteType) {
    case "SCHOOL":
      return [
        { id: "Pre-Primary", name: "Pre-Primary" },
        { id: "Primary", name: "Primary" },
        { id: "Higher-Primary", name: "Higher-Primary" },
        { id: "Secondary", name: "Secondary" }
        
      ];
    case "PU":
      return [
        { id: "ARTS", name: "Arts" },
        { id: "COMMERCE", name: "Commerce" },
        { id: "SCIENCE", name: "Science" },
      ];
    default:
      return [
        { id: "UG", name: "UG" },
        { id: "PG", name: "PG" },
        { id: "PhD", name: "PhD" },
      ];
  }
}
