import { Button, Card, CardHeader, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEGREE_OR_EQUILNT_ROUTE } from "../../data/routerConstants";
import programService from "../../services/programService";
import logger from "../../services/logger";
import PagingAndSorting from "../common/pagingAndSorting";
import ProgramsTable from "./programsTable";
import ButtonLink from "../common/buttonLink";
import ProgramForm from "./programForm";
import FormDialog from "../common/formDialog";
import InfoDialog from "../common/infoDialog";
import termDataService from "../../services/termDataService";

class Programs extends PagingAndSorting {
  state = {
    programs: [],
    sortColumn: { path: "id", order: "asc" },
    page: 0,
    rowsPerPage: 10,
    infoDialogOpen: false
  };

  componentDidMount() {
    this.getAll();
  } 

  getAll = async () => {
    try {
      const { data: programs } = await programService.getAll();
      this.setState({ programs });
    } catch (ex) {
      console.log("programs ", ex);
    }
  };

  deleteById = async (id) => {
    try {
      await programService.deleteById(id);
      await this.getAll();
    } catch (ex) {
      logger.error(ex);
    }
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
    this.getAll();
  };

  onConfirmOk = (id) => {
    this.deleteById(id);
  };

  handleViewClick=async (item)=>{
   const {data:terms}= await termDataService.getAllByProgram(item.id)
   this.setState({terms})
   this.setState({infoDialogOpen:true})
  }

  renderViewTerms(items, title = "") {
    return (
      <Card>
        <CardHeader title={title}></CardHeader>
        <Divider />
        <Typography>
          <List >
            {items?.map((item, index) => {
              const labelId = `checkbox-list-label-${item.id}`;
              return (
                <ListItem key={item.id} role={undefined} dense button >
                  <ListItemText id={labelId} primary={` ${item.name}`} />
                  <ListItemSecondaryAction>
                    <Typography >
                      {item.value}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Typography>
      </Card>
    )
  }

  render() {
    const { sortColumn, programs, dialogOpen, infoDialogOpen, terms, id } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            direction="row-reverse"
          >
            <ButtonLink onButtonClick={(event) => this.handleEdit({ id: "new" }, event)} icon="add_circle" title={t("Add Program")} size="medium" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ProgramsTable
              data={programs}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
              onViewClick={this.handleViewClick}
            />
          </Grid>
        </Grid>
        {this.renderDeleteConfirmDialog(
          t("Delete Confirmation"),
          t("Are you sure you want to delete?")
        )}

        {dialogOpen && (
          <FormDialog
            id="education-dialog"
            keepMounted
            title={t("Program Details")}
            content={
              <ProgramForm
                id={id ? id : "new"}
                onSave={this.closeDialog}
              />
            }
            open={dialogOpen}
            onClose={() => this.setState({ dialogOpen: false })}
          />
        )}

        {infoDialogOpen && (
          <InfoDialog
            id="info-dialog"
            keepMounted
            title={t("List of Terms")}
            content={this.renderViewTerms(terms)}
            open={infoDialogOpen}
            onClose={() => this.setState({ infoDialogOpen: false })}
            onOk={() => this.setState({ infoDialogOpen: false })}
          />
        )}

      </React.Fragment>
    );
  }
}

export default withTranslation(["program"])(Programs);
