import React from "react";
import { useTranslation } from "react-i18next";
import MatTable from "../../common/matTable";
import { formatToDateAndTime } from "../../common/dateUtil";
import Edit from "../../common/edit";

const AdmissionFeePaymentTransactionTable = ({
  data,
  sortColumn,
  onSort,
  onDownload,
}) => {
  const { t } = useTranslation(["admissionFeeConfigs"]);
  //console.log("feedata:",data);
  const columns = [
    // { path: "id", label: t("ID") },
    {
      path: "createdDate",
      label: t("Tnx Date"),
      content:(item)=>{
        return formatToDateAndTime(item.createdDate);
      }
    },
    {
        path: "paymentType",
        label: t("Tnx Type"),
      },
      {
        path: "paymentMode",
        label: t("Tnx Mode"),
      },
      { path: "transactionAmount", label: t("Tnx Amount") },
      { path: "discountAmount", label: t("Discount") },
      { path: "remainingAmount", label: t("Balance") },
    // { path: "status", label: t("Status") },
    {
      key: "action",
      content: (item) => {
        return <Edit icon="download" onEdit={()=>onDownload(item)} title={t("Receipt")}  />;
      },
    },
    // {
    //   key: "delete",
    //   content: (item) => <Delete onDelete={onDelete} item={item} />,
    // },
  ];

  return (
    <MatTable
      data={data}
      heading="Transaction Details"
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default AdmissionFeePaymentTransactionTable;
