import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { INSTITUTE_ROUTE } from "../../data/routerConstants";
import Delete from "../common/delete";
import Edit from "../common/edit";
import MatTable from "../common/matTable";

const InstitutesTable = ({ data, sortColumn, onSort, onDelete }) => {
  const { t } = useTranslation(["institute"]);
  const columns = [
    { path: "id", label: t("ID") },
    {
      path: "name",
      label: t("Name"),
      content: (institute) => {
        return (
          <Link to={`institutes/${institute.id}`}> {institute.name} </Link>
        );
      },
    },
    { path: "phone", label: t("Phone") },
    { path: "addressLine1", label: t("Address") },
    {
      key: "action",
      content: (item) => {
        return <Edit link={`${INSTITUTE_ROUTE}/${item.id}`}/>;
      },
    },
    // {
    //   key: "delete",
    //   content: (institute) => <Delete onDelete={onDelete} item={institute} />,
    // },
  ];

  return (
    <MatTable
      data={data}
      columns={columns}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default InstitutesTable;
