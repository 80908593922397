import DateFnsUtils from "@date-io/date-fns";
import JoiDate from "@hapi/joi-date";
import { DatePicker, DateTimePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { Button, capitalize, CardActions, CardContent, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Icon, IconButton, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Typography, } from "@mui/material";
import { Delete, Edit, EditNote, Label, Remove, Upload } from "@mui/icons-material";
import classNames from "classnames";
import "date-fns";
import JoiBase from "joi";
import React, { Component } from "react";
import { getStateObject } from "./formUtils";
import MatCKEditor from "./matCKEditor";
// import renderHTML from "react-render-html";
import htmr from 'htmr';
import programService from "../../services/programService";
import logger from "../../services/logger";
import termDataService from "../../services/termDataService";

const Joi = JoiBase.extend(JoiDate);

class Form extends Component {
  state = {
    data: {},
    selectedFiles: [],
    files: {},
    dropDowns: {},
    errors: {},

  };

  getPrograms = async (termsRequired = false) => {
    //console.log("instituteId :", instituteId);
    try {
      const { data } = await programService.getAll();
      const dropDowns = { ...this.state.dropDowns };
      dropDowns["programs"] = data;
      this.setState({ dropDowns });

      if (termsRequired && this.state.data.programId)
        await this.getTermsByProgram(this.state.data.programId)
    } catch (ex) {
      logger.error(ex);
    }
  };
  getTermsByProgram = async (programId) => {
    try {
      const { data: terms } = await termDataService.getAllByProgram(programId);

      const dropDowns = { ...this.state.dropDowns };
      dropDowns["terms"] = terms;
      const data = { ...this.state.data }
      data["termId"] = terms[0].id;
      this.setState({ data, dropDowns, termId: terms[0].id });
    } catch (ex) {
      logger.error(ex);
    }
  };
  mapToViewModel = (obj, formJson = null, isJsonHasData = false) => {
    // console.log("object in map to view:", obj);
    const json = formJson ? formJson : this.formJson;
    // console.log("json obj:", json);

    this.setState({ data: getStateObject(json.groups, obj, isJsonHasData) });
  };

  validate = () => {
    const { error } = Joi.object(this.schema).validate(this.state.data, {
      abortEarly: false,
    });

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    console.log("errrrrr :", errors);
    return errors;
  }

  validateProperty = (input) => {
    // console.log("input : ", input);
    const { name, value, checked } = input;
    let obj;
    let schema;
    if (this.schema[name]._refs && this.schema[name]._refs.refs.length > 0) {
      const ref = this.schema[name]._refs.refs[0].root;
      obj =
        input.type === "checkbox"
          ? { [name]: checked }
          : { [name]: value, [ref]: this.state.data[ref] };

      //  console.log("refs obj :", obj);
      schema = { [name]: this.schema[name], [ref]: this.schema[ref] };
      //console.log("refs schema :", schema);
    } else {
      obj = input.type === "checkbox" ? { [name]: checked } : { [name]: value };
      schema = { [name]: this.schema[name] };
    }

    const validationError = Joi.object(schema).validate(obj);
    const { error } = validationError;
    // console.log(error);
    return error ? error.details[0].message : null;
  };

  handleBack = (item) => {
    this.props.history.goBack();
  }

  handleSubmit = (e, id) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit(id);
  };

  handleDateChange = (inputName, inputValue) => {
    const errors = { ...this.state.errors };
    const input = { name: inputName, value: inputValue?.$d };
    console.log("date input:", input.value);
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleDateTimeChange = (inputName, inputValue) => {
    console.log("time value:", inputValue)
    const errors = { ...this.state.errors };
    const input = { name: inputName, value: inputValue };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(input.value - tzoffset)).toISOString().slice(0, -1);

    data[input.name] = localISOTime;

    this.setState({ data, errors });
  };


  manageDependents(field, value, formJson = null) {
    const json = formJson ? formJson : this.formJson;
    const result = json.groups.flatMap(g => g.fields);

    field.dependent.keys.forEach(key => {
      let dependentField = result.find(r => r.name === key);
      // console.log("dependentField:", dependentField);
      if (dependentField)
        switch (dependentField.dependency.is.key) {
          case "gt":
            if (value > dependentField.dependency.is.value) {
              dependentField.notShow = false;
              return value;
            }
            else {
              dependentField.notShow = true;
            }
            break;
          case "lt":
            if (value < dependentField.dependency.is.value) {
              dependentField.notShow = false;
              return value;
            }
            else {
              dependentField.notShow = true;
            }
            break;
          case "eq":
            if (value === dependentField.dependency.is.value) {
              console.log("eq:",value);
              dependentField.notShow = false;
              return value;
            }
            else {
              dependentField.notShow = true;
            }
            break;
          default:
            break
        }
    })

  }

  handleChange = async ({ target: input }, type = "none", field = {}) => {
    // if (field.dependent) {
    //   this.manageDependents(field, input.value);
    // }
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    const files = { ...this.state.files }
    console.log("type:", type);
    if (field.type === "mobile") {
      const regex = /^[0-9\b]+$/;
      data[input.name] = (input.value === "" || regex.test(input.value)) ? input.value : "";
      this.setState({ data, errors });
      return;
    }
    data[input.name] = input.type === "checkbox" ? input.checked : input.value;

    if (input.type === "file") {
      const file = input.files[0];
      const base64File = await this.toBase64(file)
      data[input.name] = base64File.split(",")[1];
      data[input.name + "ContentType"] = file.type;
      console.log("file type:", file.type);
      files[input.name] = file;
      this.setState({ selectedFiles: input.files, files })
      this.setState({ data, errors });
      console.log("files:", files);
      return;
    }

    this.setState({ data, errors });
    // console.log("input type :", errorMessage);
    console.log("type1:", type, ":", data[input.name]);
    if (type !== "none") this.onSelectOf(type, data[input.name]);
  };


  handleEditorChange = async (name, value) => {
    // console.log("editor data:", value);
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data })
  }

  onSelectOf = (selected, value) => {
    console.log("selected : ", selected, ":", value);
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleCancelButton = () => {
    try {
      const { onSave } = this.props;
      if (onSave)
        onSave();
      this.props.history.goBack();
    } catch (error) {
      // console.log("ss",error);
      console.error(error);
    }
  }

  handleDeleteFile = (event, field) => {
    event.preventDefault();
    const { data, files } = { ...this.state }

    console.log("fiel-", files);
    delete data[field.name];
    if(files){

      delete files[field.name]
      this.setState({ data, files });

    }else
    this.setState({ data});
  }

  handleMultiControl = (event,field) => {
    event.preventDefault()
    console.log("field:", field);
    this.onSelectOf(field.name,field.label2);
  }

  renderMatButton(label, type = "submit", color = "success", fullWidth = false, icon = "save",size="small") {
    switch (type) {
      case "submit":
        return (
          <Button size={size} fullWidth={fullWidth} variant="contained" color={color} type={type} style={{ margin: "5px" }} startIcon={<Icon>{icon}</Icon>}>
            {label}
          </Button>
        );

      case "reset":
        return (
          <Button
            fullWidth={fullWidth}
            variant="contained"
            type={"button"}
            color="grey"
            style={{ margin: "5px" }}
            startIcon={<Icon>cancel</Icon>}
            onClick={this.handleCancelButton}>
            {label}
          </Button>
        );
      default:
        return (
          <Button fullWidth={fullWidth} variant="contained" color={color} type="button" style={{ margin: "5px" }} startIcon={<Icon>{icon}</Icon>} onClick={() => this.handleClick(label)}>
            {label}
          </Button>
        );
    }
  }

  renderMatInput(field, name, label, type = "text", variant = "outlined", size = "small") {
    const { data, errors } = this.state;

    return (
      <TextField
        className={field.class}
        fullWidth
        disabled={field.disabled ? true : false}
        required={field.required ? true : false}
        error={errors[name] ? true : false}
        helperText={errors[name]}
        variant={variant}
        margin="normal"
        id={name}
        label={label ? label : capitalize(name)}
        name={name}
        autoComplete={name}
        value={data[name]}
        onChange={(event) => this.handleChange(event, field.name, field)}
        type={type}
        size={size}
        InputProps={{
          readOnly: (field.readOnly ? true : false) || (data["id"] && field.readOnlyOnUpdate ? true : false),
          maxLength: "10"

        }}
      />
    );


  }


  renderMatEditor(field, type = "text", variant = "outlined", size = "small") {
    const { data, errors } = this.state;
    if (field.readOnly) {
      return htmr(data[field.name]);
    } else
      return (
        <>
          <Label>{field.label}</Label>
          <MatCKEditor
            fullWidth
            disabled={field.disabled ? true : false}
            required={field.required ? true : false}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]}
            variant={variant}
            margin="normal"
            id={field.name}
            name={field.name}
            value={data[field.name]}
            onEditorChange={this.handleEditorChange}
            type={type}
            size={size}
            InputProps={{
              readOnly: (field.readOnly ? true : false) || (data["id"] && field.readOnlyOnUpdate ? true : false),
              maxLength: "10"
            }}
          />
        </>
      );
  }




  renderInputFile(field) {
    return (
      <React.Fragment>
        <div className={field.class}>
        <br />
        {field.multiControl &&
          <>
          <Button color="default" startIcon={<EditNote/>} variant="contained" component="span" onClick={() => this.onSelectOf(field.name,field.label2)}>{field.label2}</Button>
          <br />
          {field.separator}
          <br />
          </>
        }
     
        <FormControl component="fieldset">
          <label htmlFor={field.name}>
            <input
              id={field.name}
              name={field.name}
              style={{ display: "none" }}
              type="file"
              onChange={this.handleChange}
              accept={field.accept ? field.accept : "image/*"}
            />
            {this.state.data[field.name] ?
              <span>
                {this.renderFileType(field)}
                <div className="file-edit-delete">

                <IconButton component="span" size="small" color="primary" >
                  <Edit />
                </IconButton>
                {/* <br /> */}
                <IconButton component="span" size="small" color="error" onClick={(event) => { event.preventDefault(); this.handleDeleteFile(event, field) }}>
                  <Delete />
                </IconButton>
                </div>
              </span>
              :
              <Button variant="outlined" color="outlinedDefault" component="span"  endIcon={<Upload />} >
                {field.label}
              </Button>}          
              {/* <Icon>{upload}</Icon> */}
              </label>
        </FormControl>
        <label htmlFor="">{field.helperText}</label>
      </div>
      </React.Fragment>
    );
  }


  excel_type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  pdf_type = "application/pdf"

  renderFileType(field) {
    if ((this.state.files && (this.state.files[field.name]?.type === "text/csv" || this.state.files[field.name]?.type === this.excel_type) || (this.state.data[field.name] && this.state.data[field.name + "ContentType"] === this.excel_type || this.state.data[field.name + "ContentType"] === "text/csv"))) {
      return <>
        <span className="file-view">

          <img src="../../csv.png" width="50" height="50" alt="img" />
          {/* <br /> */}
          <span>{field.label}</span>

        </span>
      </>
    } else if ((this.state.files && (this.state.files[field.name]?.type === this.pdf_type) || (this.state.data[field.name] && this.state.data[field.name + "ContentType"] === this.pdf_type))) {
      return <>
        <span className="file-view">

          <img src="../../pdf.png" width="50" height="50" alt="img" />
          {/* <br /> */}
          <span>{field.label}</span>

        </span>
      </>
    } else {
      return <>
        <span className="file-view">

          <img alt="img" width="50" height="50" src={"data:" + this.state.data[field.name + "ContentType"] + ";base64," + this.state.data[field.name]}
          />
          {/* <br /> */}
          <span>{field.label}</span>
        </span>

      </>
    }
  }

  renderMatTextArea(field, name, label, rows = 4, variant = "standard", size = "small") {
    const { data, errors } = this.state;
    return (
      <TextField
        fullWidth
        disabled={field.disabled ? true : false}
        required={field.required ? true : false}
        error={errors[name] ? true : false}
        helperText={errors[name]}
        variant={variant}
        margin="normal"
        id={name}
        label={label ? label : capitalize(name)}
        name={name}
        autoComplete={name}
        value={data[name] ? data[name] : ""}
        onChange={this.handleChange}
        multiline
        rows={rows}
        size={size}
        InputProps={{ readOnly: field.readOnly ? true : false || (data["id"] && field.readOnlyOnUpdate ? true : false) }}
        InputLabelProps={{ shrink: data[name] ? true : false }}
      />
    );
  }

  renderMatCheckbox(field, name, label, color = "primary") {
    const { data } = this.state;
    console.log("Boolean(data[name]):", Boolean(data[name]));
    return (
      <>
        <FormControlLabel
          control={<Checkbox
            checked={Boolean(data[name])}
            onChange={(event) => this.handleChange(event, name, field)}
            name={name}
            id={name}
            value={label}
            color={color} />}
          label={label ? label : capitalize(name)} />
        {/* <FormHelperText style={{ color: "red" }}>{errors[name]}</FormHelperText> */}
      </>
    );
  }
  renderMatRadio(field, name, label, options, variant = "outlined") {
    const { data, errors } = this.state;
    // const labelId = name + "-label";
    const value = typeof data[name] === "boolean" ? data[name].toString() : data[name]?.toString();
    console.log("data type:", typeof data[name]);
    console.log("data type after:", typeof value, ":", value);
    if (!data[name] && field.defaultValue) {
      // const data = { ...this.state.data };data
      data[name] = field.defaultValue?.toString()
      // this.setState({data})

    }

    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {label ? label : capitalize(name)}
        </FormLabel>
        <RadioGroup
          aria-label={label}
          row
          id={name}
          name={name}
          value={
            value
              ? value
              : field.defaultValue
                ? field.defaultValue
                : ""
          }
          label={label ? label : capitalize(name)}
          onChange={(event) => this.handleChange(event, name, field)}
        >
          {
            this.renderRadioOptions(options ? options : field.options)
          }
        </RadioGroup>
        <FormHelperText style={{ color: "red" }} >{errors[name]}</FormHelperText>
      </FormControl>
    );
  }

  renderRadioOptions(options) {
    return options &&
      options.length > 0 &&
      options.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.id}
          control={<Radio />}
          label={option.name}
        />
      ))
  }
  renderMatSelect(field, name, label, options, variant = "outlined") {
    const { data, errors } = this.state;
    const labelId = name + "-label";
    // console.log("options : ", name);
    return (
      <FormControl
        fullWidth={"true"}
        size="small"
        variant={variant}
        error={errors[name] ? true : false}
        className={classNames("dropDown")}
        required={field.required ? true : false}
      >
        <InputLabel id={labelId}>{label ? label : capitalize(name)}</InputLabel>
        <Select
          fullWidth={"true"}
          readOnly={field.readOnly ? true : false || (data["id"] && field.readOnlyOnUpdate ? true : false)}
          disabled={field.disabled ? true : false}
          labelId={labelId}
          id={name}
          name={name}
          value={data[name] ? data[name] : ""}
          label={label ? label : capitalize(name)}
          onChange={(event) => this.handleChange(event, name, field)}
        >
          <MenuItem>{label ? label : capitalize(name)}</MenuItem>
          {
            this.renderOptions(options ? options : field.options, field)
          }
        </Select>
        <FormHelperText>{errors[name]}</FormHelperText>
      </FormControl>
    );
  }

  renderOptions(options, field) {
    if (options && options.length > 0) {
      const id = field.renderIdKey ? field.renderIdKey : "id"
      const value = field.renderValueKey ? field.renderValueKey : "name"
      return options.map((option) => (
        <MenuItem key={option[id]} value={option[id]}>
          {option[value]}
        </MenuItem>
      ))
    } else {

    }
  }

  renderMatMultiSelect(field, name, label, options, variant = "outlined") {
    const { data, errors } = this.state;
    const labelId = name + "-label";
    // console.log("data options :", name);
    if (!data[name]) data[name] = [];

    return (
      <FormControl
        size={"small"}
        variant={variant}
        error={errors[name] ? true : false}
        className={classNames("dropDown")}
        required={field.required ? true : false}
      >
        <InputLabel id={labelId}>{label ? label : capitalize(name)}</InputLabel>
        <Select
          multiple
          // input={<Input />}
          readOnly={field.readOnly ? true : false || (data["id"] && field.readOnlyOnUpdate ? true : false)}
          disabled={field.disabled ? true : false}
          renderValue={(selected) => selected.join(", ")}
          labelId={labelId}
          id={name}
          name={name}
          value={data[name]}
          onChange={this.handleChange}
          label={label ? label : capitalize(name)}
          variant={variant}
        >
          {options &&
            options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox checked={data[name].indexOf(option.id) > -1} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  renderMatDatePicker(field, name, label, disableToolbar = false, variant = "outlined") {
    const { data, errors } = this.state;
    dayjs()
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="date-input"
            fullWidth
            disableToolbar={disableToolbar}
            allowKeyboardControl={Boolean("false")}
            minDate={field.min &&
              (field.min === "now" ? dayjs() : dayjs(data[field.min.ref]))}
            inputFormat="DD-MMM-YYYY"
            format="DD-MMM-YYYY"
            error={errors[name] ? true : false}
            helperText={errors[name]}
            variant={variant}
            inputVariant={variant}
            size="small"
            margin="normal"
            id={name}
            label={label ? label : capitalize(name)}
            name={name}
            autoComplete={name}
            value={data[name] ? dayjs(data[name]) : null}
            onChange={(date) => this.handleDateChange(name, date)}
            InputLabelProps={{ shrink: data[name] ? true : false }}
            required={field.required ? true : false}
            slotProps={{ textField: { fullWidth: true, size: "small" } }}

            inputProps={{
              readOnly: (field.readOnly ? true : false) || (data["id"] && field.readOnlyOnUpdate ? true : false),
              style: "margin-top:1rem"
            }}
          />
        </LocalizationProvider>

        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar={disableToolbar}
            allowKeyboardControl={Boolean("false")}
            minDate={field.min &&
              (field.min === "now" ? new Date() : data[field.min.ref])}
            format="dd MMM yyyy"
            error={errors[name] ? true : false}
            helperText={errors[name]}
            variant={variant}
            inputVariant={variant}
            size="small"
            margin="normal"
            id={name}
            label={label ? label : capitalize(name)}
            name={name}
            autoComplete={name}
            value={data[name] ? data[name] : null}
            onChange={(date) => this.handleDateChange(name, date)}
            InputLabelProps={{ shrink: data[name] ? true : false }}
            required={field.required ? true : false}
            inputProps={{
              readOnly: (field.readOnly ? true : false) || (data["id"] && field.readOnlyOnUpdate ? true : false),
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }} />
        </MuiPickersUtilsProvider> */}
      </>
    );
  }

  renderMatDateTimePicker(field, name, label, disableToolbar = false, variant = "outlined") {
    const { data, errors } = this.state;

    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            className="date-input"
            fullWidth
            disableToolbar={disableToolbar}
            allowKeyboardControl={Boolean("false")}
            minDate={field.min &&
              (field.min === "now" ? dayjs() : dayjs(data[field.min.ref]))}
            inputFormat="DD-MMM-YYYY"
            format="DD-MMM-YYYY hh:mm a"
            error={errors[name] ? true : false}
            helperText={errors[name]}
            variant={variant}
            inputVariant={variant}
            size="small"
            margin="normal"
            id={name}
            label={label ? label : capitalize(name)}
            name={name}
            autoComplete={name}
            value={data[name] ? dayjs(data[name]) : null}
            onChange={(date) => this.handleDateTimeChange(name, date)}
            InputLabelProps={{ shrink: data[name] ? true : false }}
            required={field.required ? true : false}
            slotProps={{ textField: { fullWidth: true, size: "small" } }}

            inputProps={{
              readOnly: (field.readOnly ? true : false) || (data["id"] && field.readOnlyOnUpdate ? true : false),
            }}
          />
        </LocalizationProvider>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <KeyboardDateTimePicker
          fullWidth
          disableToolbar={disableToolbar}
          allowKeyboardControl={Boolean("false")}
          minDate={
            field.min &&
            (field.min === "now" ? new Date() : data[field.min.ref])
          }
          format="dd MMM yyyy hh:mm a"
          error={errors[name] ? true : false}
          helperText={errors[name]}
          variant={variant}
          inputVariant={variant}
          size="small"
          margin="normal"
          id={name}
          label={label ? label : capitalize(name)}
          name={name}
          autoComplete={name}
          value={data[name] ? data[name] : null}
          onChange={(date) => this.handleDateTimeChange(name, date)}
          InputLabelProps={{ shrink: data[name] ? true : false }}
          required={field.required ? true : false}
          inputProps={{
            readOnly: (field.readOnly ? true : false) || (data["id"] && field.readOnlyOnUpdate ? true : false),
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider> */}
      </>
    );
  }

  renderMatTimePicker(field, name, label, disableToolbar = false, variant = "outlined") {
    const { data, errors } = this.state;
    console.log("time -----:", data[name], "==", new Date(data[name]).toTimeString());
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            className="date-input"
            fullWidth
            disableToolbar={disableToolbar}
            allowKeyboardControl={Boolean("false")}
            minDate={field.min &&
              (field.min === "now" ? dayjs() : dayjs(data[field.min.ref]))}
            inputFormat="DD-MMM-YYYY"
            format="hh:mm a"
            error={errors[name] ? true : false}
            helperText={errors[name]}
            variant={variant}
            inputVariant={variant}
            size="small"
            margin="normal"
            id={name}
            label={label ? label : capitalize(name)}
            name={name}
            autoComplete={name}
            value={data[name] ? dayjs(data[name]) : null}
            onChange={(date) => this.handleDateTimeChange(name, date)}
            InputLabelProps={{ shrink: data[name] ? true : false }}
            required={field.required ? true : false}
            slotProps={{ textField: { fullWidth: true, size: "small" } }}

            inputProps={{
              readOnly: (field.readOnly ? true : false) || (data["id"] && field.readOnlyOnUpdate ? true : false),
            }}
          />
        </LocalizationProvider>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <KeyboardTimePicker
          fullWidth
          disableToolbar={disableToolbar}
          allowKeyboardControl={Boolean("false")}
          minDate={
            field.min &&
            (field.min === "now" ? new Date() : data[field.min.ref])
          }
          format="hh:mm a"
          error={errors[name] ? true : false}
          helperText={errors[name]}
          variant={variant}
          inputVariant={variant}
          size="small"
          margin="normal"
          id={name}
          label={label ? label : capitalize(name)}
          name={name}
          autoComplete={name}
          value={data[name] ? data[name] : null}
          onChange={(date) => this.handleDateTimeChange(name, date)}
          InputLabelProps={{ shrink: data[name] ? true : false }}
          required={field.required ? true : false}
          inputProps={{
            readOnly: (field.readOnly ? true : false) || (data["id"] && field.readOnlyOnUpdate ? true : false),
          }}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </MuiPickersUtilsProvider> */}
      </>
    );
  }



  renderField(field, dropDowns) {
    // const inputType = field.inputType;
    // console.log("render field:",field,this.state.data[field.name]);
    const { t } = this.props;
    if (field.dependent) {
      this.manageDependents(field, this.state.data[field.name]);
    }
        // console.log("result :", this.schema);

    switch (field.inputType) {
      case "editor":
        return this.renderMatEditor(field)
      case "select":
        return this.renderMatSelect(
          field,
          field.name,
          t(field.label),
          dropDowns[field.dropDown]
        );
      case "multi-select":
        return this.renderMatMultiSelect(
          field,
          field.name,
          t(field.label),
          dropDowns[field.dropDown]
        );
      case "text-area":
      case "textarea":
        return this.renderMatTextArea(field, field.name, t(field.label));
      case "date":
        return this.renderMatDatePicker(field, field.name, t(field.label));
      case "date-time":
        return this.renderMatDateTimePicker(field, field.name, t(field.label));
      case "time":
        return this.renderMatTimePicker(field, field.name, t(field.label));
      case "checkbox":
        return this.renderMatCheckbox(field, field.name, t(field.label));
      case "radio":
        return this.renderMatRadio(
          field,
          field.name,
          t(field.label),
          dropDowns[field.dropDown]
        );
      case "file":
        return this.renderInputFile(field);
      case "number":
        return this.renderMatInput(field, field.name, t(field.label), "number");
      default:
        return this.renderMatInput(field, field.name, t(field.label));
    }
  }

  renderForm(formJson, dropDowns, buttons = true) {
    // console.log("json:", formJson);
    const { t } = this.props;
    return (
      <React.Fragment>
        {formJson.groups && formJson?.groups?.map((group) => (
          !group.notShow && <div key={group.name + "-div"}>
            <Divider />
            {/* <br /> */}
            <CardContent>
              <Typography
                //color="secondary"
                variant="h6"
                component="h3"
                className="field-group"
              >
                {t(group.name)}
              </Typography>
              <Grid container spacing={1}>
                {this.renderFields(group, dropDowns)}
              </Grid>
            </CardContent>
          </div>
        ))}
        <Divider />
        {buttons && <CardActions>
          <Grid container item sm={12} md={12} direction="row-reverse">
            {this.renderMatButton(t("Save"))}
            {this.renderMatButton(t("Cancel"), "reset")}
          </Grid>
        </CardActions>}
      </React.Fragment>
    );
  }

  renderFields(group, dropDowns) {
    const fields = group.fields
    if (fields.length > 1) {
      return <>
        <Divider />
        {fields.map(
          (field) =>
            !field.notShow && (
              <Grid key={field.name + "-grid"} item
                xs={12}
                sm={12}
                md={group.cols ? 12 / group.cols : 6}
              >
                <div>
                  {this.renderField(field, dropDowns)}
                </div>
              </Grid>
            )
        )}
      </>
    } else {
      return (fields.map(
        (field) =>
          !field.notShow && (
            <Grid key={field.name + "-grid"} item
              xs={12}
              sm={12}
              md={12}
            >
              <div>
                {this.renderField(field, dropDowns)}
              </div>
            </Grid>
          )
      ))
    }
  }

  renderView(formJson, previewData = null) {
    const data = previewData ? previewData : this.state.data
    return (
      <React.Fragment>
        <Typography>

          {formJson.groups && formJson.groups?.map((group) => (
            <div key={group.name + "-div"}>
              <Divider />
              <br />
              <CardContent>
                <Typography
                  color="secondary"
                  variant="h6"
                  component="h4"
                >
                  {group.name}
                </Typography>
                <Grid container spacing={1}>
                  <Divider />
                  {group.fields.map(
                    (field) => {
                      if (!field.notShow)
                        if (field.type !== "file") {
                          return (<Grid item key={field.name + "-grid"} sm={12} md={6}>
                            <Typography
                              color="default"
                              variant="span"
                              component="span"
                            >
                              <span> <strong> {field.label} :</strong></span>  <span>{data[field.name]} </span>
                            </Typography>
                          </Grid>)
                        } else {
                          return (<Grid item key={field.name + "-grid"} sm={12} md={6}>
                            {<img
                              width="50"
                              height="50"
                              alt="img"
                              src={
                                "data:" + data[field.name + "ContentType"] + ";base64," + data[field.name]
                              }
                            />} <br />
                            <span> <strong> {field.label}</strong></span>

                          </Grid>)
                        }
                    }
                  )}
                </Grid>
              </CardContent>
            </div>
          ))}
        </Typography>

      </React.Fragment>
    );
  }

}

export default Form;
